import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MIRROR } from 'data/mirrorConfig';

import { postRequest } from 'utils/requests';

import { selectMyProfile } from 'redux/auth/selectors';
import { getWomanData } from 'redux/chat/selectors';
import { togleGlobalModal } from 'redux/interface/slice';

export const useSendSupportReq = () => {
    const [selectFileToComplaint, setSelectFileToComplaint] = useState();
    const [describe, setDescribe] = useState('');
    const [mail, setMail] = useState(useSelector(selectMyProfile)?.user_detail?.email);
    const [isOpenModalComplaint, setIsOpenModalComplaint] = useState(false);

    const womanData = useSelector(getWomanData);

    const dispatch = useDispatch();

    const sendSupportReq = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', mail);
        formData.append('message_content', describe);
        formData.append('support_type', 'report');
        formData.append('files[]', selectFileToComplaint?.target.files[0]);
        formData.append('brand_url', MIRROR[process.env.REACT_APP_MIRROR_NAME].BRAND_URL);
        formData.append('female_external_id', womanData.external_id);
        if (describe !== '') {
            postRequest('/api/support/supportRequest', formData).then((res) => {
                setIsOpenModalComplaint(false);
                setDescribe('');
                setSelectFileToComplaint(undefined);
                const message = () => {
                    return (
                        <div>
                            <p style={{ marginBottom: '10px' }}>{res?.message}</p>
                            <a
                                style={{
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    textDecoration: 'none',
                                }}
                                href={`mailto:${
                                    MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL
                                }`}
                            >
                                {MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL}
                            </a>
                        </div>
                    );
                };
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: message(),
                        btnText: 'Got it',
                    }),
                );
            });
        }
    };

    const changeFileToComplaint = (event) => {
        setSelectFileToComplaint(event);
    };

    const handleMail = (value) => {
        setMail(value);
    };

    const onChangeDescribe = (value) => {
        setDescribe(value);
    };

    return {
        sendSupportReq,
        selectFileToComplaint,
        setSelectFileToComplaint,
        describe,
        setDescribe,
        isOpenModalComplaint,
        setIsOpenModalComplaint,
        mail,
        setMail,
        changeFileToComplaint,
        handleMail,
        onChangeDescribe,
    };
};
