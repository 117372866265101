import { useState } from 'react';

import { postRequest } from 'utils/requests';

import { policyLinkConfig } from 'data/config';

const usePolicyPage = () => {
    const [policyInfo, setPolicyInfo] = useState();

    const changePolicy = (seo_url) => {
        postRequest('/api/page/show', { seo_url: seo_url }).then((res) => {
            setPolicyInfo(res.result);
        });
    };

    return {
        policyLinkConfig,
        changePolicy,
        policyInfo,
        setPolicyInfo,
    };
};

export default usePolicyPage;
