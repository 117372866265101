import React from 'react';
import { Box, Button, AccordionMenu, TextNameonChat } from 'components/atomsKit/Elements';
import useFaqPage from './useFaqPage';
import classes from './FaqPage.module.scss';

const FaqPage = () => {
    const { faqList, setChoseFaq, choseFaq, idSelectTopic, setIdSelectTopic } = useFaqPage() as any;

    return (
        <Box className={classes.wrap}>
            <Box flex directionColumn align="center" justify="center" padding="24px">
                {!choseFaq &&
                    faqList?.map((item: any, index: number) => (
                        <button
                            className={classes.btn}
                            key={index}
                            onClick={() => setChoseFaq(item)}
                        >
                            <p className={classes.number}>{index + 1}</p>
                            {item.title}
                        </button>
                    ))}
                {choseFaq && (
                    <>
                        <Box
                            flex
                            position={'relative'}
                            width100
                            align="center"
                            justify="center"
                            onClick={() => setChoseFaq()}
                        >
                            <Button
                                fitContent
                                text="back"
                                borderOff
                                noBg
                                color="#474F5C"
                                className={classes.btnBack}
                            />
                            <TextNameonChat text={choseFaq.title} margin="0 0 10px 0" size={18} />
                        </Box>
                        <Box className={classes.wrapSubMenu}>
                            {choseFaq?.question_list?.map((item: any, index: number) => (
                                <Box className={classes.option} key={index}>
                                    <AccordionMenu
                                        text={item.title}
                                        isOpenOption={idSelectTopic === item.id}
                                        handleOption={() =>
                                            setIdSelectTopic(
                                                idSelectTopic === item.id ? null : item.id,
                                            )
                                        }
                                    >
                                        <div
                                            className={classes.answer}
                                            dangerouslySetInnerHTML={{
                                                __html: item.answer_list[0]?.text,
                                            }}
                                        />
                                    </AccordionMenu>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default FaqPage;
