import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    Box,
    Button,
    ModalWindowModern,
    InputModern,
    TextNameonChat,
} from 'components/atomsKit/Elements';

import { togleGlobalModal } from 'redux/interface/slice';

import { postRequest } from 'utils/requests';

import classes from './ModalNewPassword.module.scss';

interface IProps {
    modalIsOpen: boolean;
    closeModal: any;
}

const ModalNewPassword = ({ modalIsOpen, closeModal }: IProps) => {
    const [valuePass, setValuePass] = useState('');

    const { activation_code } = useParams();

    const dispatch = useDispatch();

    const onChangePass = (e: any) => {
        setValuePass(e.target.value);
    };

    const handleConfirm = (e: any) => {
        e.preventDefault();

        if (valuePass.length < 8) {
            dispatch(
                togleGlobalModal({
                    isOpen: 'open',
                    text: 'Please enter a new password of 8 or more characters',
                    btnText: 'Got it',
                }),
            );
        } else {
            postRequest('/api/login/passwordSet', {
                activation_code: activation_code,
                password: valuePass,
            })
                .then((res: any) => {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: res.message,
                            btnText: 'Got it',
                        }),
                    );

                    closeModal();
                })
                .catch(() => {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: 'Something went wrong.',
                            btnText: 'Got it',
                        }),
                    );
                });
        }
    };

    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <form onSubmit={handleConfirm}>
                <Box
                    className={classes.modalBody}
                    flex
                    align="center"
                    justify="center"
                    directionColumn
                >
                    <TextNameonChat
                        className={classes.title}
                        text="Enter your new password"
                        size={18}
                        textAlign={'center'}
                    />
                    <Box
                        width100
                        flex
                        align="center"
                        justify="center"
                        directionColumn
                        marginBottom="10px"
                    >
                        <InputModern
                            type={'password'}
                            placeholder="New password (8+ characters)"
                            value={valuePass}
                            onChange={onChangePass}
                        />
                    </Box>
                    <Button text="Set password" fitContent padding="0 40px 0 40px" />
                </Box>
            </form>
        </ModalWindowModern>
    );
};

export default ModalNewPassword;
