export const ATTACH_FILENAME = /[A-Za-z0-9_]+/;
// eslint-disable-next-line no-useless-escape
export const IMAGE_FILENAME = /[,~@#$%^(){}'`+=:;/?\\*"\[\]<>|]+/;
export const URL = /(https?:\/\/[^\s]+)/g;
// eslint-disable-next-line no-useless-escape
export const URL_DOMAIN = /(www\.)([\w.-]+).*/;
export const MOB_NUMBER_USA = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
export const NUMBER_IN_STRING = /\d{5,}/gi;
export const VALIDATION_EMAIL =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
// only Latin, numbers, symbols, signs and spaces
export const LATIN_SYMBOLS = /^[a-zA-Z0-9\s.,;:?!'"()$#%&@+-/*=_{}<>~^|\\[\]]*$/i;
