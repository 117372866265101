import React from 'react';
import { Avatar } from 'components/atomsKit/Elements';
// import { Image } from "components/designAtom/Elements";
// import cn from "classnames";
import classes from './InfoGirl.module.scss';

interface IProps {
    imgLink?: any;
    isFavorite?: boolean;
    isOnline?: boolean;
    external_id?: any;
    public_external_id?: any;
    name: string | undefined;
    age: any;
    country_icon?: any;
    city?: any;
    isSmallAvatar?: boolean;
    isBigAvatar?: boolean;
}

const InfoGirl = ({
    imgLink,
    isFavorite,
    isOnline,
    public_external_id,
    name,
    age,
    country_icon,
    city,
    isSmallAvatar,
    isBigAvatar,
}: IProps) => {
    return (
        <div className={classes.wrpaInfoAvatar}>
            <Avatar
                imgLink={imgLink}
                isFavorite={isFavorite}
                isOnline={isOnline}
                small={isSmallAvatar}
                big={isBigAvatar}
            />
            <div className={classes.avatartTextInfo}>
                {public_external_id && <p className={classes.id}>ID: {public_external_id}</p>}

                <p className={classes.name}>
                    {name}, {age}
                </p>
                <p className={classes.city}>
                    <img
                        src={country_icon}
                        alt=""
                        width="15px"
                        loading="lazy"
                        className={classes.flag}
                    />
                    {city}
                </p>
            </div>
        </div>
    );
};

export default InfoGirl;
