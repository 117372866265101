import React from 'react';
import { Box, TextNameonChat } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './ProgressBarDay.module.scss';

interface IProps {
    maxDay: number;
    usedDay: number;
    labelText: string;
    margin?: string;
}

const ProgressBarDay = ({ maxDay, usedDay, labelText, margin }: IProps) => {
    const days = Array.from({ length: maxDay }, (_, index) => index + 1);
    return (
        <Box margin={margin}>
            <TextNameonChat text={`${maxDay} ${labelText}`} size={12} />
            <div className={classes.container}>
                {days.map((day) => (
                    <div
                        key={day}
                        className={cn(classes.oneDayBox, day <= usedDay && classes.usedDay)}
                    />
                ))}
            </div>
        </Box>
    );
};

export default ProgressBarDay;
