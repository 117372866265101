export const BILLING_ACTION_CLOSE_PAYMENT_PAGE = 'CLOSE_PAYMENT_PAGE';
export const BILLING_ACTION_OPEN_PAYMENT_PAGE = 'OPEN_PAYMENT_PAGE';
export const BILLING_ACTION_RECURRING_CANCEL = 'RECURRING_CANCEL';
export const BILLING_ACTION_RECURRING_CHANGE = 'RECURRING_CHANGE';
export const BILLING_ACTION_RECURRING_CONFIRM = 'RECURRING_CONFIRM';
export const BILLING_ACTION_RECURRING_CREATE = 'RECURRING_CREATE';
export const BILLING_ACTION_OPEN_PAYMENT_ROUTING_MODAL = 'OPEN_PAYMENT_ROUTING_MODAL';
export const BILLING_ACTION_CLOSE_PAYMENT_ROUTING_MODAL = 'CLOSE_PAYMENT_ROUTING_MODAL';
export const BILLING_ACTION_RECURRING_ROUTING_CANCEL = 'RECURRING_ROUTING_CANCEL';
export const BILLING_ACTION_FIRST_PAYMENT = 'FIRST_PAYMENT';
export const BILLING_ACTION_VAT_BONUS = 'VAT_BONUS';
export const BILLING_ACTION_VAT_PRICES = 'VAT_PRICES';
export const BILLING_ACTION_VAT_ATTENTION_MODAL = 'VAT_ATTENTION_MODAL';
export const BILLING_ACTION_OPEN_PAYMENT_ROUTING_PAGE = 'OPEN_PAYMENT_ROUTING_PAGE';
export const BILLING_ACTION_CLOSE_PAYMENT_ROUTING_PAGE = 'CLOSE_PAYMENT_ROUTING_PAGE';
export const BILLING_ACTION_LAUNCH_PAYMENT_ROUTING_ERROR = 'LAUNCH_PAYMENT_ROUTING_ERROR';
export const BILLING_ACTION_INIT_PAYMENT_ROUTING_ERROR = 'INIT_PAYMENT_ROUTING_ERROR';
