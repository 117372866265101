import { useLayoutEffect, useState } from 'react';

const useIsAllWindow = () => {
    const [browsPanelHide, setBrowsPanelHide] = useState(0);

    useLayoutEffect(() => {
        function handleResize() {
            setBrowsPanelHide(window.innerHeight);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { browsPanelHide };
};

export default useIsAllWindow;
