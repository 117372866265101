import React from 'react';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';

import styles from './styles.module.scss';
import ImbraDoc from './ImbraDoc';

import { MIRROR } from 'data/mirrorConfig';

import { ARRAY_SMALL_ANSWER, MAX_LENGTH_ANSWER, QUESTIONS_DATA } from './constant';
import useHandleImbraForm from './useHandleImbraForm';
import PropTypes from 'prop-types';

ImbraForm.propTypes = {
    closeModal: PropTypes.func,
};
export default function ImbraForm({ closeModal }) {
    const {
        imbraFormData,
        handleImbraTableData,
        handleAnswerCheckbox,
        handleAnswerTextarea,
        isActiveDownLoad,
        checkAllFields,
        errorFields,
        textareaClassError,
        invalidDate,
    } = useHandleImbraForm();

    const SITE = {
        name: process.env.REACT_APP_MIRROR_NAME,
        copyrightsText: `${MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME}.com`,
    };

    const { supportEmail } = useSelector((state) => state.user) || 'avodate.com';

    const maxAnswerLength = (id) => {
        return ARRAY_SMALL_ANSWER.includes(id) ? MAX_LENGTH_ANSWER / 2 : MAX_LENGTH_ANSWER;
    };

    return (
        <div className={styles.imbra_form_popup} data-testid="imbra-form-modal">
            <h1 id="start-imbra-form" className={styles.imbra_form_title}>
                PERSONAL DISCLOSURE STATEMENT
            </h1>
            <p className={styles.imbra_form_text}>
                This form is used to meet the requirements of IMBRA 2005 (HR03657). This information
                will be translated into Ukrainian or Russian and provided to any member that you
                wish to make contact with either via purchase of her contact information.
            </p>
            <p className={styles.imbra_form_text}>
                Please, print this page, fill in all required information below, date, sign and
                email a scanned copy of this Personal Disclosure Statement and copy of your passport
                (or driver license) to {SITE.copyrightsText} at{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail ?? ''}</a>.
            </p>
            <div className={styles.imbra_form_table}>
                <div className={styles.imbra_form_table_col}>
                    {errorFields.includes('firstName') && (
                        <span className={`${styles.imbra_form_required} ${styles.table}`}>
                            required*
                        </span>
                    )}
                    <div className={styles.imbra_form_table_head}>First Name</div>
                    <input
                        className={styles.imbra_form_table_body}
                        type="text"
                        name="firstName"
                        data-testId="firstName"
                        value={imbraFormData?.firstName}
                        onChange={handleImbraTableData}
                    />
                </div>
                <div className={styles.imbra_form_table_col}>
                    {errorFields.includes('middleName') && (
                        <span className={`${styles.imbra_form_required} ${styles.table}`}>
                            required*
                        </span>
                    )}
                    <div className={styles.imbra_form_table_head}>Middle Name</div>
                    <input
                        className={styles.imbra_form_table_body}
                        type="text"
                        name="middleName"
                        data-testId="middleName"
                        value={imbraFormData?.middleName}
                        onChange={handleImbraTableData}
                    />
                </div>
                <div className={styles.imbra_form_table_col}>
                    {errorFields.includes('lastName') && (
                        <span className={`${styles.imbra_form_required} ${styles.table}`}>
                            required*
                        </span>
                    )}
                    <div className={styles.imbra_form_table_head}>Last Name</div>
                    <input
                        className={styles.imbra_form_table_body}
                        type="text"
                        name="lastName"
                        data-testId="lastName"
                        value={imbraFormData?.lastName}
                        onChange={handleImbraTableData}
                    />
                </div>
                <div className={styles.imbra_form_table_col}>
                    {errorFields.includes('dateOfBirth') && (
                        <span className={`${styles.imbra_form_required} ${styles.table}`}>
                            required*
                        </span>
                    )}
                    {invalidDate === 'dateOfBirth' && (
                        <span className={`${styles.imbra_form_required} ${styles.table}`}>
                            Invalid date
                        </span>
                    )}
                    <div className={styles.imbra_form_table_head}>Date of Birth (MM/DD/YYYY)</div>
                    <input
                        className={`${styles.imbra_form_table_body} ${
                            invalidDate === 'dateOfBirth' ? styles.error : ''
                        }`}
                        type="text"
                        name="dateOfBirth"
                        data-testId="dateOfBirth"
                        value={imbraFormData?.dateOfBirth}
                        onChange={handleImbraTableData}
                    />
                </div>
            </div>

            {QUESTIONS_DATA.map((el) => (
                <div className={styles.imbra_form_question_block} key={el?.id}>
                    {errorFields.includes(`answer${el?.id}`) && (
                        <span className={styles.imbra_form_required}>required*</span>
                    )}
                    <p className={styles.imbra_form_question}>
                        {el?.question}
                        {!!+el?.questionLink?.length && (
                            <a
                                href={el?.questionLink ?? ''}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {el?.questionLink}
                            </a>
                        )}
                    </p>
                    <div className={styles.imbra_form_row}>
                        <div
                            className={styles.imbra_form_checkbox_row}
                            data-testId={`answerCheckbox${el?.id}`}
                        >
                            <input
                                id={`imbra-checkbox-${el?.id}-yes`}
                                className={styles.imbra_form_checkbox}
                                type="radio"
                                checked={imbraFormData[`answer${el?.id}`]?.checkbox === 'Yes'}
                                onChange={() => handleAnswerCheckbox(`answer${el?.id}`, 'Yes')}
                            />
                            <label
                                htmlFor={`imbra-checkbox-${el?.id}-yes`}
                                className={styles.imbra_form_checkbox_label}
                            >
                                Yes
                            </label>

                            <input
                                id={`imbra-checkbox-${el?.id}-no`}
                                className={styles.imbra_form_checkbox}
                                type="radio"
                                checked={imbraFormData[`answer${el?.id}`]?.checkbox === 'No'}
                                onChange={() => handleAnswerCheckbox(`answer${el?.id}`, 'No')}
                            />
                            <label
                                htmlFor={`imbra-checkbox-${el?.id}-no`}
                                className={styles.imbra_form_checkbox_label}
                            >
                                No
                            </label>
                        </div>
                        {!!+el?.questionDetails?.length && (
                            <p className={styles.imbra_form_question}>{el?.questionDetails}</p>
                        )}
                    </div>

                    {!!(
                        imbraFormData[`answer${el?.id}`]?.checkbox === 'Yes' &&
                        !!+el?.questionDetails?.length
                    ) && (
                        <div className={styles.imbra_form_answer_wrap}>
                            <textarea
                                className={`${styles.imbra_form_answer} ${textareaClassError(
                                    `answer${el?.id}`,
                                )}`}
                                name={`answer${el?.id}`}
                                data-testId={`answer${el?.id}`}
                                value={imbraFormData[`answer${el?.id}`]?.answer}
                                onKeyDown={(e) => e?.keyCode === 13 && e.preventDefault()}
                                onChange={handleAnswerTextarea}
                            />
                            <div
                                className={`${styles.imbra_form_answer_counter} ${
                                    imbraFormData[`answer${el?.id}`]?.answer?.length >=
                                    maxAnswerLength(el?.id)
                                        ? styles.error
                                        : ''
                                }`}
                            >
                                {imbraFormData[`answer${el?.id}`]?.answer?.length}/
                                {maxAnswerLength(el?.id)}
                            </div>
                        </div>
                    )}
                </div>
            ))}

            <div className={styles.imbra_form_today_wrap}>
                <div className={styles.imbra_form_today_date}>Date (MM/DD/YYYY)</div>
                <input
                    className={`${styles.imbra_form_today_input} ${
                        errorFields.includes('todayDate') || invalidDate === 'todayDate'
                            ? styles.error
                            : ''
                    }`}
                    type="text"
                    name="todayDate"
                    data-testId="todayDate"
                    value={imbraFormData?.todayDate}
                    onChange={handleImbraTableData}
                />
            </div>

            <div
                className={`${styles.imbra_form_download_btn} ${
                    isActiveDownLoad ? styles.active : ''
                }`}
                data-testId="DownloadImbra"
                onClick={checkAllFields}
            >
                <PDFDownloadLink
                    document={
                        <ImbraDoc supportEmail={supportEmail} imbraFormData={imbraFormData} />
                    }
                    fileName={`PERSONAL DISCLOSURE STATEMENT ${SITE.name}.pdf`}
                >
                    {({ loading }) => (loading ? 'Loading document...' : 'Download IMBRA form')}
                </PDFDownloadLink>
            </div>

            <div className={styles.imbra_form_close} onClick={closeModal} />
        </div>
    );
}
