import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/atomsKit/Elements';

import classes from './DropdownIndicatorPart.module.scss';

const DropdownIndicatorPart = (props) => {
    const { menuIsOpen } = props.selectProps;
    const rotateDeg = {
        transform: `rotate(${180}deg)`,
    };
    return (
        <div className={classes.indicatorWrap}>
            <Icon size="16px" style={menuIsOpen && rotateDeg} color={'red'} />
        </div>
    );
};

DropdownIndicatorPart.propTypes = {
    selectProps: PropTypes.object,
};

export default DropdownIndicatorPart;
