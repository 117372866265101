import React from 'react';
import cn from 'classnames';
import classes from './NameAge.module.scss';

interface IProps {
    name: string;
    age: number;
    whiteColor?: boolean;
}

const NameAge = ({ name, age, whiteColor }: IProps) => {
    return (
        <p className={cn(classes.name, whiteColor && classes.whiteColor)}>{`${name}, ${age}`}</p>
    );
};

export default NameAge;
