import { createSlice } from '@reduxjs/toolkit';
import { fetchChatList, delCardChat, getMessages, sendMessage, setFavorite } from './operations';

import { updateStatusOnline } from 'utils/common';

const chatInitialState = {
    items: [],
    currentChat: { chats: [] },
    isLoading: false,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState: chatInitialState,
    reducers: {
        readStatus(state, action) {
            const idReadChat = action.payload.chat_uid;
            if (state.currentChat?.chats[0]?.chat_uid === idReadChat) {
                state.currentChat.chats = state.currentChat.chats.map((item) => ({
                    ...item,
                    read_status: 1,
                    message_content:
                        action?.payload?.message_id === item?.id
                            ? action?.payload?.original
                            : item.message_content,
                    payed:
                        action?.payload?.message_id === item?.id
                            ? action?.payload?.payed
                            : item.payed,
                }));
            }
        },
        setWinkTrue(state) {
            if (state?.currentChat?.chatInfo) {
                state.currentChat.chatInfo.wink = true;
            }
        },
        updateStatusOnlineChatList(state, action) {
            const data = state.items;
            if (data) {
                state.items = updateStatusOnline(action.payload, data);
            }
        },
        updateStatusOnlineMessager(state, action) {
            const data = state?.currentChat?.womanData;
            if (data) {
                state.currentChat.womanData = updateStatusOnline(action.payload, data);
            }
        },
        updateStatusLikeInMessenger(state) {
            state.currentChat.womanData.isLike = 1;
        },
    },
    extraReducers: {
        [fetchChatList.pending](state, action) {
            if (action.meta?.arg?.page === 1) {
                state.items = [];
            }
            state.userProfile = null;
            state.isLoading = true;
        },
        [fetchChatList.fulfilled](state, action) {
            if (action.meta?.arg?.page === 1) {
                state.items = action.payload;
            } else {
                state.items = [...(state.items || []), ...(action.payload || [])];
            }
            state.isLoading = false;
        },
        [fetchChatList.rejected](state) {
            state.isLoading = false;
        },

        [delCardChat.pending](state) {
            state.isLoading = true;
        },
        [delCardChat.fulfilled](state, action) {
            state.items = state.items?.filter((item) => item.chat_uid !== action.payload.chat_uid);
            state.isLoading = false;
        },
        [delCardChat.rejected](state) {
            state.isLoading = false;
        },

        [getMessages.pending](state, action) {
            if (action.meta.arg.page === 1) {
                state.items = [];
            }
        },
        [getMessages.fulfilled](state, action) {
            if (action.meta.arg.page === 1) {
                state.currentChat.womanData = action.payload.womanData;
                state.currentChat.chats = action.payload.chats;
                state.currentChat.chatInfo = action.payload.chatInfo;
            } else {
                state.currentChat.chats = [
                    ...(action.payload.chats || []),
                    ...(state.currentChat.chats || []),
                ];
            }
            state.isLoading = false;
        },

        [sendMessage.fulfilled](state, action) {
            state.currentChat.chats = [...(state.currentChat.chats || []), action.payload];
        },

        [setFavorite.fulfilled](state, action) {
            state.currentChat.womanData.isFavorite = action.payload;
        },
    },
});

export const {
    readStatus,
    setWinkTrue,
    updateStatusOnlineChatList,
    updateStatusOnlineMessager,
    updateStatusLikeInMessenger,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
