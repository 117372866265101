import React from 'react';
import { MetricDefinition, MetricsProvider } from '@cabify/prom-react';
import { MetricsInnerWrap } from 'components/atomsKit/Elements';
import { MIRROR } from 'data/mirrorConfig';

import { PAGE_PERFORMANCE_METRICS } from 'data/config';
import * as PATH from 'data/path';

interface IProps {
    children?: any;
}

const MetricsLayout = ({ children }: IProps) => {
    const hostname = window?.location?.hostname?.includes('localhost')
        ? `mstage.${MIRROR[
              process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR
          ].NAME.toLowerCase()}.com`
        : window?.location?.hostname;

    const normalizePath = (path: string) => {
        for (const key in PATH.PATHS) {
            const currentPath = PATH.PATHS[key as keyof typeof PATH.PATHS];
            const replacePath =
                currentPath && 'replacePath' in currentPath ? currentPath?.replacePath : null;
            if (replacePath) {
                const regex = new RegExp(`(${replacePath})([^/]+)`, 'g');
                if (regex.test(path)) {
                    return path.replace(regex, `${replacePath}id`);
                }
            }
        }
        return path;
    };

    return (
        <MetricsProvider
            appName={hostname}
            owner="mobile"
            getNormalizedPath={normalizePath}
            customMetrics={PAGE_PERFORMANCE_METRICS as MetricDefinition[]}
            metricsAggregatorUrl="https://gateway.cdndate.net/push"
        >
            <MetricsInnerWrap>{children}</MetricsInnerWrap>
        </MetricsProvider>
    );
};

export default MetricsLayout;
