import React from 'react';
import { Box, InfoGirl } from 'components/atomsKit/Elements';
// import cn from "classnames";
import classes from './ContactRequestCard.module.scss';

interface IProps {
    public_external_id: number;
    nameGirl: string;
    ageGirl: number;
    avatarGirl: string;
    date_created: string;
    status: number;
    city: string;
    country_code: string;
}

const ContactRequestCard = ({
    public_external_id,
    nameGirl,
    ageGirl,
    avatarGirl,
    date_created,
    status,
    city,
    country_code,
}: IProps) => {
    return (
        <Box className={classes.wrap}>
            <Box flex align="center">
                <InfoGirl
                    imgLink={avatarGirl}
                    name={nameGirl}
                    public_external_id={public_external_id}
                    age={ageGirl}
                    city={city}
                    country_icon={`/assets/flags/${country_code?.toLocaleLowerCase()}.jpg`}
                />
            </Box>
            <Box className={classes.info} marginTop="10px" padding="10px 0 0 0">
                <p className={classes.textInfo}>
                    Request date
                    <span style={{ marginLeft: '16px' }}>{date_created}</span>
                </p>
                <p className={classes.textStatus}>
                    STATUS:
                    <span style={{ marginLeft: '16px' }}>
                        {status === 0 ? 'In progress' : 'Approved'}
                    </span>
                </p>
            </Box>
        </Box>
    );
};

export default ContactRequestCard;
