import React from 'react';
import { SelectDropDown, CheckboxTogle, Input, Box } from 'components/atomsKit/Elements';
import { ageOptions } from 'utils/common';
import classes from './FilterBodyGender.module.scss';

interface IProps {
    className?: string;
    filtersOptions: any;
    onChangeOption: any;
    valuesFilter: any;
}

const FilterBodyGender = ({ filtersOptions, onChangeOption, valuesFilter }: IProps) => {
    return (
        <section className={classes.wrap}>
            <p className={classes.fixBottom}>All filters are applied automatically</p>
            <CheckboxTogle
                value="ONLINE"
                onChange={() => onChangeOption('online', !valuesFilter.online)}
                checked={valuesFilter.online}
                className={classes.onlineWrap}
            />
            <Box padding="0 12px 0 12px" marginBottom="32px">
                <Input
                    colorIcon="hsl(0, 0%, 80%)"
                    icon="ok"
                    onClickIcon={() => onChangeOption('id', '')}
                    onChange={(value) => {
                        onChangeOption('id', value.target.value);
                    }}
                    value={valuesFilter.id}
                    placeholder="Search ID or name"
                />
            </Box>
            <Box padding="0 12px 0 12px" marginBottom="24px">
                <SelectDropDown
                    isSearchable
                    placeholder={'Choose country'}
                    options={filtersOptions.country_list}
                    value={valuesFilter.country}
                    onChange={(value: any) => onChangeOption('country', value)}
                    label={'Country'}
                    isClearable
                />
            </Box>
            <Box padding="0 12px 0 12px" marginBottom="24px">
                <p className={classes.lable}>Age range</p>
                <div className={classes.ageWrap}>
                    <SelectDropDown
                        placeholder={'From'}
                        options={ageOptions()}
                        value={valuesFilter.from}
                        onChange={(value: any) =>
                            onChangeOption('from', { ...value, id: value.value })
                        }
                        className={classes.ageDrop}
                    />
                    <SelectDropDown
                        placeholder={'To'}
                        options={ageOptions()}
                        value={valuesFilter.to}
                        onChange={(value: any) =>
                            onChangeOption('to', { ...value, id: value.value })
                        }
                        className={classes.ageDrop}
                    />
                </div>
            </Box>
            <Box padding="0 12px 0 12px" marginBottom="24px">
                <SelectDropDown
                    menuPlacement="top"
                    placeholder={'I`m looking for'}
                    options={filtersOptions.gender_list}
                    value={valuesFilter.gender}
                    onChange={(value: any) => onChangeOption('gender', value)}
                    label={'I`m looking for'}
                />
            </Box>
        </section>
    );
};

export default FilterBodyGender;
