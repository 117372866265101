import { useSearchParams, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { togleGlobalModal } from 'redux/interface/slice';
import { getRequest } from 'utils/requests';
import { removeQueryParam, sendErrToSentry } from 'utils/common';

export const useAccessKeyRedirect = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUrl = window.location.pathname + window.location.search;

    const token = localStorage.getItem('token');
    const accessKey = searchParams.get('accessKey');

    const accessKeyRedirect = async () => {
        try {
            if (token) {
                openInfoModal();
                removeQueryParam('accessKey');
            } else if (accessKey && accessKey !== undefined) {
                const res = await getRequest(`/api/login/accessToken?accessKey=${accessKey}`)
                    .then((res) => {
                        if (res?.fingerprint) {
                            localStorage.setItem('fingerprinthash', res?.fingerprint);
                        }
                        return res;
                    })
                    .catch(() => {});

                if (res?.status && res?.response) {
                    localStorage.setItem('token', res?.response);
                    window.location.replace(currentUrl);
                } else {
                    window.location.replace(`/login`);
                }
            }
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const openInfoModal = () => {
        if (searchParams.has('mPayment')) {
            if (searchParams.get('type') === 'success') {
                const credits = searchParams.get('credits');
                navigate(window.location.pathname, { replace: true });
                dispatch(
                    togleGlobalModal({
                        isOpen: 'openPaySucces',
                        description: credits,
                    }),
                );
            } else if (searchParams.get('type') === 'fail') {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'openPayFail',
                    }),
                );
            }
        }
    };

    return { accessKeyRedirect };
};
