import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper';
import cn from 'classnames';
import { Button, AudioPlayer } from 'components/atomsKit/Elements';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import classes from './SliderMIniPhoto.module.scss';

interface IProps {
    handleProfile?: (id: number) => void;
    handleDel?: any;
    girlsList: any;
    className?: any;
    handleContent?: any;
}

const SliderMIniPhoto = ({
    girlsList,
    handleProfile = () => {},
    handleDel,
    className,
    handleContent,
}: IProps) => {
    const isBlure = (item: any) => {
        return item.attachment_free === 0 && item.attachment_payed === 0;
    };

    return (
        <Swiper
            slidesPerView={5}
            spaceBetween={2}
            freeMode={true}
            modules={[FreeMode, Pagination]}
            className={className}
        >
            {girlsList.map((image: any, index: number) => (
                <SwiperSlide className={classes.slide} key={index}>
                    <button onClick={() => handleProfile(image)} className={classes.btn}>
                        {handleDel && (
                            <Button
                                text=""
                                isWhite
                                borderOff
                                className={classes.delPhotoBtn}
                                iconSvg="delete-photo"
                                onClick={() => handleDel(image)}
                            />
                        )}
                        {image.message_type === 'SENT_VIDEO' || image.content_type === 'video' ? (
                            <img
                                alt=""
                                src={image.thumb_link}
                                className={cn(classes.img, isBlure(image) && classes.blur)}
                                onClick={() => {
                                    handleContent({
                                        original: image.link,
                                        thumbnail: image.link,
                                        type: 'video',
                                    });
                                }}
                                loading="lazy"
                            />
                        ) : image.message_type === 'SENT_AUDIO' ||
                          image.content_type === 'audio' ? (
                            <>
                                <AudioPlayer src={image.link} />
                            </>
                        ) : (
                            <img
                                alt=""
                                src={image.link}
                                className={cn(classes.img, isBlure(image) && classes.blur)}
                                onClick={() => {
                                    handleContent({
                                        original: image.link,
                                        thumbnail: image.link,
                                    });
                                }}
                                loading="lazy"
                            />
                        )}
                    </button>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SliderMIniPhoto;
