import { useSelector } from 'react-redux';

import { selectMyProfile } from 'redux/auth/selectors';

const useTemplate = () => {
    const user_avatar = useSelector(selectMyProfile)?.user_detail?.photo_link;
    const user_name = useSelector(selectMyProfile)?.user_detail?.name;
    const email_token = useSelector(selectMyProfile)?.user_detail?.email_token;

    return {
        user_avatar,
        user_name,
        email_token,
    };
};

export default useTemplate;
