import React from 'react';
import { TextNameonChat } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './CheckV.module.scss';

interface IProps {
    className?: string;
    label: string;
    value?: boolean;
    onChange?: () => void;
    error?: boolean;
}

const CheckV = ({ label, value, onChange, className, error }: IProps) => {
    return (
        <label className={cn(classes.wrap, className)}>
            {onChange !== undefined ? (
                <input
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                    className={classes.checkbox}
                />
            ) : (
                <input type="checkbox" className={classes.checkbox} defaultChecked={true} />
            )}
            <div
                className={cn(
                    classes.checkBox,
                    value && classes.checkBox_checked,
                    error && classes.checkBox_error,
                )}
            />
            <TextNameonChat text={label} className={classes.text} />
        </label>
    );
};

export default CheckV;
