import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'utils/sentryInit';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
        <Provider store={store}>
            <App />
        </Provider>
    </Sentry.ErrorBoundary>,
);
