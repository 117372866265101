import * as MIRRORdata from 'data/mirror';
import { MIRROR } from 'data/mirrorConfig';

import { GIFT_ACTIVE_FOR_USER_LIST, PAYMENT_TOTAL_HIDE_GIFT } from 'data/user';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { selectUser } from 'redux/auth/selectors';

const useHandleActiveBlocks = () => {
    const userState = useSelector(selectUser)?.user_info;

    const isVip = !!+userState?.is_verified;

    const isHideBefore2Purchase = false;
    const isNewMailCategory1 = !!(!!userState?.newMail && +userState?.newMailCategory === 1);
    const isPaymentUser = !!+userState?.payment_total;
    const isTwitterUser = userState?.t_user === 1; // by GT-2413
    const isRedUser = +userState?.configType === 1; // by GT-3045
    const isGiftStartDate = moment(userState?.created_at).isAfter('2023-08-16 15:00:00');
    const isForceActiveGift = GIFT_ACTIVE_FOR_USER_LIST.includes(userState?.external_id); // GT-3739
    const isContactRequest = !!(
        !isRedUser &&
        !isHideBefore2Purchase &&
        !MIRRORdata.HIDDEN_CONTACT_REQUEST.includes(
            MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
        ) &&
        +userState?.payment_total
    );

    const isHideGift = isForceActiveGift
        ? false
        : isRedUser ||
          !!(isNewMailCategory1 && !isPaymentUser) ||
          !!+userState?.hide_gift ||
          (isGiftStartDate
              ? +userState?.payment_total < PAYMENT_TOTAL_HIDE_GIFT
              : MIRRORdata.HIDDEN_GIFT.includes(
                    MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
                ) && +userState?.payment_total < PAYMENT_TOTAL_HIDE_GIFT) ||
          !!+userState?.gender;

    return {
        isRedUser,
        isNewMailCategory1,
        isPaymentUser,
        isHideGift,
        isContactRequest,
        isTwitterUser,
        isHideBefore2Purchase,
        isVip,
    };
};

export default useHandleActiveBlocks;
