import React from 'react';
import { Box, Button, TextNameonChat, Textonchat } from 'components/atomsKit/Elements';

import classes from './GiftCard.module.scss';

interface IProps {
    img: string;
    name: string;
    price: number;
    isInCart?: boolean;
    handleAddCart?: any;
}

const GiftCard = ({ img, name, price, isInCart, handleAddCart }: IProps) => {
    return (
        <Box className={classes.wrap}>
            <img alt="" src={img} loading="lazy" />
            <TextNameonChat text={name} textAlign={'center'} margin="14px 0 14px 0" />
            <Box>
                <Textonchat
                    text={`${+price} credits`}
                    textAlign={'center'}
                    className={classes.price}
                    margin="0 0 8px 0"
                />
                <Button
                    onClick={handleAddCart}
                    className={classes.addCart}
                    disabled={isInCart}
                    text={isInCart ? 'Added to cart' : 'Add to cart'}
                    iconSvg={isInCart ? 'check-circle-darkblue' : 'cart-white'}
                />
            </Box>
        </Box>
    );
};

export default GiftCard;
