import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uploadFile from 'utils/uploadFile';
import { postRequest } from 'utils/requests';

import { fetchUploadFiles } from 'redux/interface/operations';
import { fetchBalance } from 'redux/auth/operations';
import { setUploadLoader, togleGlobalModal } from 'redux/interface/slice';
import { getUploadFile, getUploadLoader } from 'redux/interface/selectors';

import { uploadFileNavConfig } from 'data/config';

export const useUploadFile = ({ userExternalId, isMessager }) => {
    const [navConfig, setNavConfig] = useState(uploadFileNavConfig);
    const [isOpenModaAddFile, setIsOpenModaAddFile] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);

    const dispatch = useDispatch();

    const uploadFiles = useSelector(getUploadFile);
    const uploadLoader = useSelector(getUploadLoader);

    const handleUploadFile = (files, AWS) => {
        const nameOperatiion = navConfig.find((item) => item.selected).nameCredit;
        dispatch(
            setUploadLoader({
                name: nameOperatiion,
                count: 1,
            }),
        );
        uploadFile(
            files[0],
            files[0]?.name?.split('.')[0],
            userExternalId,
            AWS,
            uploadFiles[navConfig.find((item) => item.selected === true).nameCredit].length,
            () => {
                dispatch(fetchUploadFiles());
                dispatch(fetchBalance());
            },
            'add',
            () => {
                dispatch(
                    setUploadLoader({
                        name: nameOperatiion,
                        count: 0,
                    }),
                );
                dispatch(fetchBalance());
            },
        );
    };

    const handleOpenUpploadFile = () => {
        if (isMessager) {
            setSelectedMedia([]);
        }
        dispatch(fetchUploadFiles());
        setIsOpenModaAddFile(true);
        dispatch(fetchBalance());
    };

    const handleUploadNavBtn = (id) => {
        if (isMessager) {
            setSelectedMedia([]);
        }
        setNavConfig(
            navConfig.map((item) =>
                item.id === id ? { ...item, selected: true } : { ...item, selected: false },
            ),
        );
    };

    const handleItemMedia = (item) => {
        const isSelected = selectedMedia?.some((media) => media.id === item.id);
        if (isSelected) {
            const updatedMedia = selectedMedia?.filter((media) => media?.id !== item?.id);
            setSelectedMedia(updatedMedia);
        } else {
            if (selectedMedia?.length < 5) {
                const updatedMedia = [...selectedMedia, item];
                setSelectedMedia(updatedMedia);
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: 'Attached files limit is exceeded',
                        description: 'You can attach a maximum of 5 files to one letter',
                        btnText: 'Got it',
                    }),
                );
            }
        }
    };

    const handleSendMedias = () => {
        setIsOpenModaAddFile(false);
    };

    const handleDelMedia = (item) => {
        postRequest('/api/files/deleteMedia', {
            id: item.id,
            user_id: userExternalId,
        }).then(() => {
            dispatch(fetchUploadFiles());
        });
    };

    return {
        handleUploadFile,
        uploadFiles,
        handleDelMedia,
        handleSendMedias,
        handleItemMedia,
        handleOpenUpploadFile,
        handleUploadNavBtn,
        uploadLoader,
        navConfig,
        isOpenModaAddFile,
        setIsOpenModaAddFile,
        selectedMedia,
        setSelectedMedia,
    };
};
