import React from 'react';
import { Icon } from 'components/atomsKit/Elements';
import { Counter } from 'components/atomsKit/Elements';
import cn from 'classnames';
import classes from './ButtonAlert.module.scss';

interface IProps {
    className?: string;
    onClick: () => void;
    icon?: string;
    isHorizontalVersion?: boolean;
    counter?: number;
}

const ButtonAlert = ({ className, onClick, icon, isHorizontalVersion, counter }: IProps) => {
    return (
        <div>
            <button
                onClick={onClick}
                className={cn(classes.btn, isHorizontalVersion && classes.btn_small, className)}
            >
                <Icon icon={icon || 'bell'} size={'1.75rem'} />
                <Counter
                    counter={counter}
                    classCounter={cn(isHorizontalVersion ? classes.counter_small : classes.counter)}
                />
            </button>
        </div>
    );
};

export default ButtonAlert;
