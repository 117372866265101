import { useEffect, useState } from 'react';
import {
    ARRAY_SMALL_ANSWER,
    MAX_LENGTH_ANSWER,
    MAX_LENGTH_TABLE,
    ANSWER_WITHOUT_DETAILS,
} from './constant';
import * as REGEXP from 'data/regExp';
import moment from 'moment';
import styles from './styles.module.scss';
import { compareDates } from 'utils/common';

export default function useHandleImbraForm() {
    const initImbraFormData = {
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '', // MM/DD/YYYY
        answer1: { checkbox: '', answer: '' },
        answer2: { checkbox: '', answer: '' },
        answer3: { checkbox: '', answer: '' },
        answer4: { checkbox: '', answer: '' },
        answer5: { checkbox: '', answer: '' },
        answer6: { checkbox: '', answer: '' },
        answer7: { checkbox: '', answer: '' },
        answer8: { checkbox: '', answer: '' },
        answer9: { checkbox: '', answer: '' },
        answer10: { checkbox: '', answer: '' },
        answer11: { checkbox: '', answer: '' },
        todayDate: moment().format('MM/DD/YYYY'),
    };
    const imbraDataStore = JSON.parse(sessionStorage.getItem('imbra-data'));

    const startImbraFormData = imbraDataStore ? imbraDataStore : initImbraFormData;

    const [imbraFormData, setImbraFormData] = useState(startImbraFormData);
    const [isActiveDownLoad, setIsActiveDownLoad] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [invalidDate, setInvalidDate] = useState('');

    const checkDateFormat = (name, value) => {
        const isSlash =
            value?.charAt(value.length - 3) === '/' && value?.charAt(value.length - 1) === '/';

        if (imbraFormData[name]?.length > value?.length) return value;
        if (isNaN(value?.slice(-1)) && value?.slice(-1) !== '/') return 'Invalid date';
        if (value.slice(-1) === '/' && imbraFormData[name].slice(-1) === '/') return 'Invalid date';
        if (value?.length === 2) return value?.slice(-1) !== '/' ? `${value}/` : `0${value}`;
        if (value?.length === 3)
            return value?.slice(-1) !== '/' ? `${imbraFormData[name]}/${value?.slice(-1)}` : value;
        if (value?.length === 5)
            return isSlash
                ? `${imbraFormData[name]?.slice(0, -1)}0${value?.charAt(value.length - 2)}/`
                : `${value}/`;
        if (
            value?.length === 10 &&
            (moment(value)?.format('MM/DD/YYYY') === 'Invalid date' ||
                !compareDates(moment()?.format('MM/DD/YYYY'), value))
        ) {
            setInvalidDate(name);
        }
        if (value?.length > 10) return 'Invalid date';

        return value;
    };

    const handleImbraTableData = (e) => {
        const { name, value } = e.currentTarget;
        // only Latin, numbers, symbols, signs and spaces
        if (!REGEXP.LATIN_SYMBOLS.test(value)) return false;

        if (name === 'dateOfBirth' || name === 'todayDate') {
            const valueFormat = checkDateFormat(name, value);

            if (valueFormat !== 'Invalid date') {
                setImbraFormData({
                    ...imbraFormData,
                    ...{ [name]: valueFormat?.trim() },
                });
                setInvalidDate('');
            }
        } else if (name?.toLowerCase()?.includes('name') && value?.length <= MAX_LENGTH_TABLE) {
            setImbraFormData({ ...imbraFormData, ...{ [name]: value } });
        }

        if (errorFields?.includes(name)) {
            setErrorFields(errorFields.filter((el) => el !== name));
        }
    };

    const handleAnswerCheckbox = (answerNum, value) => {
        if (answerNum && value) {
            setImbraFormData({
                ...imbraFormData,
                ...{
                    [answerNum]: { ...imbraFormData[answerNum], ...{ checkbox: value } },
                },
            });

            if (errorFields?.includes(answerNum)) {
                setErrorFields(errorFields.filter((el) => el !== answerNum));
            }
        }
    };

    const handleAnswerTextarea = (e) => {
        const { name, value } = e.currentTarget;
        // only Latin, numbers, symbols, signs and spaces
        if (!REGEXP.LATIN_SYMBOLS.test(value)) return false;
        const num = +name?.slice(-1);
        const valueLength = ARRAY_SMALL_ANSWER.includes(num)
            ? MAX_LENGTH_ANSWER / 2
            : MAX_LENGTH_ANSWER;

        if (name && value?.length <= valueLength) {
            setImbraFormData({
                ...imbraFormData,
                ...{ [name]: { ...imbraFormData[name], ...{ answer: value } } },
            });
        }

        setErrorFields(errorFields.filter((el) => Object.keys(el)[0] !== name));
    };

    // Click button DownLoad IMBRA form
    const checkAllFields = (e) => {
        if (!isActiveDownLoad) {
            e.stopPropagation();
            e.preventDefault();

            let errorArr = [];

            for (const i in imbraFormData) {
                if (typeof imbraFormData[i] === 'object') {
                    if (!imbraFormData[i]?.checkbox?.length) {
                        errorArr.push(i);
                    } else if (
                        imbraFormData[i]?.checkbox === 'Yes' &&
                        !imbraFormData[i].answer?.length
                    ) {
                        errorArr.push({ [i]: 'answer' });
                    }
                } else if (typeof imbraFormData[i] === 'string' && !imbraFormData[i].length) {
                    errorArr.push(i);
                } else if (i === 'dateOfBirth' || i === 'todayDate') {
                    if (
                        moment(imbraFormData[i])?.format('MM/DD/YYYY') === 'Invalid date' ||
                        imbraFormData[i]?.length !== 10 ||
                        !compareDates(moment()?.format('MM/DD/YYYY'), imbraFormData[i])
                    ) {
                        setInvalidDate(i);
                    }
                }
            }

            setErrorFields(errorArr);

            const startImbraForm = document.querySelector('#start-imbra-form');
            if (startImbraForm)
                startImbraForm.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    };

    const textareaClassError = (name) => {
        return errorFields.some((el) => el[name]) ? styles.error : '';
    };

    useEffect(() => {
        setTimeout(() => {
            sessionStorage.setItem('imbra-data', JSON.stringify(imbraFormData));

            for (const i in imbraFormData) {
                if (typeof imbraFormData[i] === 'object') {
                    const answerNum = i.split('answer')[1];

                    if (!imbraFormData[i]?.checkbox?.length) {
                        return setIsActiveDownLoad(false);
                    } else if (
                        imbraFormData[i]?.checkbox === 'Yes' &&
                        !imbraFormData[i].answer?.length &&
                        !ANSWER_WITHOUT_DETAILS.includes(+answerNum)
                    ) {
                        return setIsActiveDownLoad(false);
                    }
                } else if (typeof imbraFormData[i] === 'string' && !imbraFormData[i].length) {
                    return setIsActiveDownLoad(false);
                } else if (i === 'dateOfBirth' || i === 'todayDate') {
                    if (
                        moment(imbraFormData[i])?.format('MM/DD/YYYY') === 'Invalid date' ||
                        imbraFormData[i]?.length !== 10 ||
                        !compareDates(moment()?.format('MM/DD/YYYY'), imbraFormData[i])
                    ) {
                        return setIsActiveDownLoad(false);
                    }
                }
            }

            setIsActiveDownLoad(true);
        });
    }, [imbraFormData]);

    return {
        imbraFormData,
        handleImbraTableData,
        handleAnswerCheckbox,
        handleAnswerTextarea,
        isActiveDownLoad,
        checkAllFields,
        errorFields,
        textareaClassError,
        invalidDate,
    };
}
