export const configTutorial = [
    {
        title: 'Step 1',
        name: 'Instruction',
        icon: 'reset_instruction_1',
        text: 'We have sent you a letter to the email you provided for password recovery. The letter will be received within a few minutes',
    },
    {
        title: 'Step 2',
        icon: 'reset_instruction_2',
        text: 'Check your email and follow the link from the letter we sent you',
    },
    {
        title: 'Step 3',
        icon: 'reset_instruction_3',
        text: 'Enter new password',
    },
    {
        title: 'Step 4',
        icon: 'reset_instruction_4',
        text: 'Enjoy',
    },
];
