export const listIconDescription = {
    sofiadate: [
        {
            icon: '1',
            title: 'Free registration',
            decription: 'We provide free registration and transparent fees',
        },
        {
            icon: '2',
            title: 'Tons of free visual content',
            decription: 'The variety of free photo galleries for every profile',
        },
        {
            icon: '3',
            title: 'Great video shows',
            decription:
                'This service will help you to see your interlocutor in real life as if no distance was separating you both',
        },
        {
            icon: '4',
            title: 'Variety of convenient tools',
            decription:
                'You can use chats, letters and likes to search and communicate with your interlocutor',
        },
        {
            icon: '5',
            title: 'Passion for communication',
            decription: 'All participants are longing for lasting communication',
        },
        {
            icon: '6',
            title: 'Manually verified profiles',
            decription: "All members' profiles are manually verified by our moderation team",
        },
        {
            icon: '7',
            title: 'Constant access to correspondence',
            decription:
                'Your correspondence always stays yours. Chats and letters are stored forever',
        },
        {
            icon: '8',
            title: 'Safe payments',
            decription: 'Maximal safety and privacy during communication and payments',
        },
        {
            icon: '9',
            title: '24/7 support ',
            decription:
                'Our support team is always at your disposal 24/7 to help you with any issues',
        },
    ],
    AvoDate: [
        {
            icon: 'feature-1',
            title: 'Join for free',
            decription: 'You don’t pay for registration',
        },
        {
            icon: 'feature-2',
            title: 'Adorable free visual content',
            decription: 'See how gorgeous our members are',
        },
        {
            icon: 'feature-4',
            title: 'Effortless interaction tools',
            decription: 'Communicate efficiently with your sweet matches',
        },
        {
            icon: 'feature-6',
            title: 'All our participants are real',
            decription: 'Manual verification of every member’s profile',
        },
        {
            icon: 'feature-8',
            title: 'Date securely and keep it private',
            decription: 'Protected payments and info you provide the site with',
        },
        {
            icon: 'feature-9',
            title: 'Get help when you need it',
            decription: 'Expert support round the clock 7 days a week',
        },
    ],
    Okamour: [
        {
            icon: 'feature-1-okamour',
            title: 'Feel the benefits of free registration',
            decription: 'No funds needed to join',
        },
        {
            icon: 'feature-2-okamour',
            title: 'Numerous pictures to enjoy free of charge',
            decription: 'It’s a pleasure for your eyes to see how our members look',
        },
        {
            icon: 'feature-4-okamour',
            title: 'Fluent communication tools',
            decription: 'Send messages via chat or compose a nice letter',
        },
        {
            icon: 'feature-6-okamour',
            title: 'Manual verification of users’ profiles',
            decription: 'Every user’s identity is scrupulously checked',
        },
        {
            icon: 'feature-7-okamour',
            title: 'Awesome data protection',
            decription: 'Your chats and info are taken care of',
        },
        {
            icon: 'feature-8-okamour',
            title: 'Safe payments',
            decription: 'We care about customers’ safety',
        },
        {
            icon: 'feature-9-okamour',
            title: 'Quality and timely help online',
            decription: 'Professionals will give you advice if necessary',
        },
    ],
};
