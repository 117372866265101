import { useSelector } from 'react-redux';
import moment from 'moment';
import { sendErrToSentry } from 'utils/common';

import { checkTwoInt } from 'utils/common';
import { useDetectedDevice } from 'hooks';
import { GEO_EVENT_PUSH_V2, GEO_TIER1, GEO_EVENT_PUSH } from 'data/dictionary';

import { selectUser, selectTail } from 'redux/auth/selectors';

import { getProfileInfo } from 'redux/auth/operations';
import { postRequest } from 'utils/requests';

export const useGoogleDataLayer = () => {
    const tail = useSelector(selectTail)?.response?.tail;
    const info = useSelector(selectUser)?.user_info;
    const { smallInfo, isNotAndroid, isAndroid } = useDetectedDevice();
    const dataLayer = window.dataLayer;
    const osName = smallInfo().os.toLowerCase();

    const firstName = info?.name?.split(' ')[0];
    const lastName = info?.name?.split(' ')?.[1];

    const dobFormatDDMMYYYY = `${checkTwoInt(info?.b_day || 0)}${checkTwoInt(
        info?.b_month || 0,
    )}${info?.b_year}`;

    const userAge = +info?.age;

    const getValue = () => {
        if (!GEO_EVENT_PUSH?.includes(info?.country_code)) return false;

        if (userAge > 34 && userAge < 45) {
            if (osName.includes('android')) {
                return 200;
            }
            if (osName.includes('ios')) {
                return 350;
            }
            if (osName.includes('windows')) {
                return 350;
            }
            if (osName.includes('mac')) {
                return 500;
            }
        } else if (userAge > 44 && userAge < 55) {
            if (osName.includes('android')) {
                return 300;
            }
            if (osName.includes('ios')) {
                return 450;
            }
            if (osName.includes('windows')) {
                return 450;
            }
            if (osName.includes('mac')) {
                return 600;
            }
        } else if (userAge >= 55) {
            if (osName.includes('android')) {
                return 400;
            }
            if (osName.includes('ios')) {
                return 600;
            }
            if (osName.includes('windows')) {
                return 600;
            }
            if (osName.includes('mac')) {
                return 700;
            }
        }
        return false;
    };

    const getValueV2 = () => {
        if (!GEO_EVENT_PUSH?.includes(info?.country_code)) return false;
        const isCountryHighValue = info?.country_code === 'US';
        const isCountryMediumValue = ['GB', 'CA', 'AU'].includes(info?.country_code);

        if (userAge > 44 && userAge < 55) {
            if (isCountryHighValue) {
                if (osName.includes('ios')) {
                    return 450;
                }
                if (osName.includes('windows')) {
                    return 450;
                }
                if (osName.includes('mac')) {
                    return 600;
                }
            } else if (isCountryMediumValue) {
                if (osName.includes('ios')) {
                    return 350;
                }
                if (osName.includes('windows')) {
                    return 350;
                }
                if (osName.includes('mac')) {
                    return 500;
                }
            }
        } else if (userAge >= 55) {
            if (isCountryHighValue) {
                if (osName.includes('ios')) {
                    return 600;
                }
                if (osName.includes('windows')) {
                    return 600;
                }
                if (osName.includes('mac')) {
                    return 700;
                }
            } else if (isCountryMediumValue) {
                if (osName.includes('ios')) {
                    return 500;
                }
                if (osName.includes('windows')) {
                    return 500;
                }
                if (osName.includes('mac')) {
                    return 600;
                }
            }
        }

        return false;
    };

    const pushEventUniversalPurchase = ({ price, count }) => {
        try {
            dataLayer.push({
                event: 'universal_purchase',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventUniversalPurchase1 = ({ price, count }) => {
        try {
            dataLayer.push({
                event: 'universal_purchase_1',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    //GT-7414
    const pushEventUniqueUniversalPurchase = ({ price, count }) => {
        //If you purchase the package within 30 days from the date of registration.
        const isWithin30Days =
            moment(new Date()).diff(moment(new Date(info?.created_at)), 'days') <= 30;

        if (GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase()) && isWithin30Days) {
            try {
                dataLayer.push({
                    event: 'unique_universal_purchase',
                    value: price,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                        transaction_id: `${info?.external_id}${count}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventFirstPurchase = () => {
        try {
            dataLayer.push({
                event: 'first_purchase',
                value: 350, //important for marketing
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventFirstPurchaseValue = ({ count }) => {
        const eventValue = getValue();

        try {
            if (eventValue) {
                dataLayer.push({
                    event: 'first_purchase_value',
                    value: eventValue,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                        transaction_id: `${info?.external_id}${count}`,
                    },
                });
            }
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventFirstPurchaseTargetOs = ({ count }) => {
        const eventValue = getValueV2();
        if (!eventValue) return;
        try {
            dataLayer.push({
                event: 'first_purchase_target_os',
                value: eventValue,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventSecondPurchase = () => {
        try {
            dataLayer.push({
                event: 'second_purchase',
                value: 200, //important for marketing
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventThirdPurchaseValue = ({ price, count }) => {
        if (GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())) {
            try {
                dataLayer.push({
                    event: 'third_purchase_value',
                    value: price,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                        transaction_id: `${info?.external_id}${count}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventTryPay = () => {
        try {
            dataLayer.push({
                event: 'try-pay',
                value: 2.99, //static value
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // This applies only to bonuses - free credits
    const pushEventFirstCreditSpent = () => {
        try {
            dataLayer.push({
                event: 'first_credit_spent',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventEcommerce = () => {
        getProfileInfo().then((res) => {
            if (res?.purchase_history) {
                const purchaseMonth = Object.keys(res?.purchase_history)[0];
                const purchaseDay = Object.keys(res?.purchase_history[purchaseMonth])[0];
                const purchaseLast = res?.purchase_history[purchaseMonth][purchaseDay];

                if (purchaseMonth && purchaseDay && purchaseLast?.length && purchaseLast[0]) {
                    try {
                        dataLayer.push({
                            ecommerce: {
                                currencyCode: 'USD',
                                impressions: [
                                    {
                                        name: `${purchaseLast[0]?.amount} credits`,
                                        id: purchaseLast[0]?.id,
                                        position: purchaseLast[0]?.proposition_id,
                                        price: purchaseLast[0]?.price,
                                    },
                                ],
                            },
                        });
                    } catch (e) {
                        sendErrToSentry(e);
                    }
                }
            }
        });
    };

    // // Push DataLayer for FB
    const pushEventPurchase = ({ count }) => {
        try {
            dataLayer.push({
                event: 'purchase',
                value: 2.99, //static value
                currency: 'USD',
                order_id: `${info?.external_id}${count}`,
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // // Push DataLayer for FB
    const pushEventSpendAllCredit = () => {
        try {
            dataLayer.push({
                event: 'spend_all_credit',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // // Push DataLayer for FB
    //// ADD THIS PUSH !!!!!!!!
    const pushEventBeginCheckout = () => {
        try {
            dataLayer.push({
                event: 'begin_checkout',
                value: 2.99, //static value
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // // Push DataLayer for FB
    const pushEventConfirmedEmail = () => {
        try {
            dataLayer.push({
                event: 'confirmed_email',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // // Push DataLayer for FB
    const pushEventDolphin = () => {
        try {
            dataLayer.push({
                event: 'dolphin',
                value: 2000.0, //static value
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: !+info?.gender ? 'm' : 'f', //m or f
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    // // Push DataLayer for FB
    const pushEventLead = () => {
        if (GEO_TIER1?.includes(info?.country_code)) {
            try {
                dataLayer.push({
                    event: 'Lead',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(
                            info?.b_day,
                        )}`, //birthday format YYYYMMDD
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    // // Push DataLayer for FB
    const pushEventSecondVisit = () => {
        // If the user entered the site 24 hours after registration, we push an event.

        const isAfter24Hours =
            moment(new Date()).diff(moment(new Date(info?.created_at || '')), 'hours') >= 24;

        if (isAfter24Hours) {
            const secondVisitStore = localStorage.getItem('second_visit');

            if (!secondVisitStore) {
                localStorage.setItem('second_visit', '1');
                postRequest('/api/marketing/checkMarketingConversion', {
                    eventName: 'second_visit',
                }).then((resp) => {
                    if (resp?.status && resp?.response?.check) {
                        // Push DataLayer for FB
                        try {
                            dataLayer.push({
                                event: 'second_visit',
                                user_data: {
                                    email: info?.email,
                                    first_name: info?.name?.split(' ')[0],
                                    ...(info?.name?.split(' ')[1]
                                        ? { last_name: info?.name?.split(' ')[1] }
                                        : {}),
                                    gender: !+info?.gender ? 'm' : 'f', //m or f
                                    dob: `${info?.b_year}${checkTwoInt(
                                        info?.b_month,
                                    )}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                                    country: info?.country_code, //two letters ISO format
                                    ...(info?.city_name !== 'Not specified'
                                        ? { city: info?.city_name }
                                        : {}),
                                    external_id: `${info?.external_id}`,
                                },
                            });
                        } catch (e) {
                            sendErrToSentry(e);
                        }
                    }
                });
            }
        }
    };

    const pushEventRegistration = () => {
        if (GEO_TIER1?.includes(info?.country_code)) {
            try {
                dataLayer.push({
                    event: 'registration',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventUserId = () => {
        try {
            dataLayer.push({
                user_id: info?.external_id,
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventTarget45Event = () => {
        if (
            isNotAndroid &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            try {
                dataLayer.push({
                    event: 'target45_event',
                    value: 0,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventAndroid45Event = () => {
        if (
            isAndroid &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            try {
                dataLayer.push({
                    event: 'android45_event',
                    value: 0,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventIos45Event = () => {
        if (
            osName?.includes('ios') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            try {
                dataLayer.push({
                    event: 'ios45_event',
                    value: 0,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventWin45Event = () => {
        if (
            osName?.includes('windows') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            try {
                dataLayer.push({
                    event: 'win45_event',
                    value: 0,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: info?.name?.split(' ')[0],
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    const pushEventMac45Event = () => {
        if (
            osName?.includes('mac') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            try {
                dataLayer.push({
                    event: 'mac45_event',
                    value: 0,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: firstName,
                        ...(info?.name?.split(' ')[1]
                            ? { last_name: info?.name?.split(' ')[1] }
                            : {}),
                        gender: !+info?.gender ? 'm' : 'f', //m or f
                        dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${
                            info?.b_year
                        }`, //birthday format DDMMYYYY
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    };

    //GT-9481
    const pushEventUniversalPurchaseOS = ({ price, count }) => {
        const isValidCountry = GEO_EVENT_PUSH.includes(info?.country_code?.toUpperCase() || '');

        if (isNotAndroid && userAge >= 45 && isValidCountry) {
            try {
                dataLayer.push({
                    event: 'universal_purchase_target_os',
                    value: price,
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: firstName,
                        ...(lastName ? { last_name: lastName } : {}),
                        gender: !+info?.gender ? 'm' : 'f',
                        dob: dobFormatDDMMYYYY,
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                        transaction_id: `${info?.external_id}${count}`,
                    },
                });
            } catch (error) {
                sendErrToSentry(error);
            }
        }
    };

    const pushEventFirstPurchaseOs = () => {
        if (isNotAndroid && userAge >= 45) {
            try {
                dataLayer.push({
                    event: 'first_purchase_os',
                    value: 350, //important for marketing
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: firstName,
                        ...(lastName ? { last_name: lastName } : {}),
                        gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                        dob: dobFormatDDMMYYYY,
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (error) {
                sendErrToSentry(error);
            }
        }
    };

    const pushEventSecondPurchaseOs = () => {
        if (isNotAndroid && userAge >= 45) {
            try {
                dataLayer.push({
                    event: 'second_purchase_os',
                    value: 200, //important for marketing
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: firstName,
                        ...(lastName ? { last_name: lastName } : {}),
                        gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                        dob: dobFormatDDMMYYYY,
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (error) {
                sendErrToSentry(error);
            }
        }
    };

    const pushEventThirdPurchaseOs = () => {
        if (isNotAndroid && userAge >= 45) {
            try {
                dataLayer.push({
                    event: 'third_purchase_os',
                    value: 200, //important for marketing
                    currency: 'USD',
                    user_data: {
                        email: info?.email,
                        first_name: firstName,
                        ...(lastName ? { last_name: lastName } : {}),
                        gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                        dob: dobFormatDDMMYYYY,
                        country: info?.country_code, //two letters ISO format
                        ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                        external_id: `${info?.external_id}`,
                    },
                });
            } catch (error) {
                sendErrToSentry(error);
            }
        }
    };

    return {
        pushEventUniversalPurchase,
        pushEventUniversalPurchase1,
        pushEventUniversalPurchaseOS,
        pushEventUniqueUniversalPurchase,
        pushEventFirstPurchase,
        pushEventPurchase,
        pushEventFirstPurchaseValue,
        pushEventFirstPurchaseTargetOs,
        pushEventSecondPurchase,
        pushEventThirdPurchaseValue,
        pushEventTryPay,
        pushEventFirstCreditSpent,
        pushEventSpendAllCredit,
        pushEventBeginCheckout,
        pushEventConfirmedEmail,
        pushEventEcommerce,
        pushEventDolphin,
        pushEventLead,
        pushEventRegistration,
        pushEventUserId,
        pushEventTarget45Event,
        pushEventAndroid45Event,
        pushEventIos45Event,
        pushEventWin45Event,
        pushEventMac45Event,
        pushEventSecondVisit,
        pushEventFirstPurchaseOs,
        pushEventSecondPurchaseOs,
        pushEventThirdPurchaseOs,
    };
};
