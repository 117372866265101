import React from 'react';
import { useState } from 'react';

import { ModalWindowModern } from 'components/atomsKit/Elements';
import { MIRROR } from 'data/mirrorConfig';

import classes from './ModalRegisteredUser.module.scss';

interface IProps {
    closeModal?: any;
    modalIsOpen?: any;
}

const ModalRegisteredUser = ({ modalIsOpen }: IProps) => {
    const [name, setName] = useState('');

    const userEmail = '';

    const userPassword = localStorage.getItem('user_password');

    const handleName = (e: any) => {
        setName(e.target.value);
    };

    return (
        <>
            <ModalWindowModern modalIsOpen={modalIsOpen} hideCloseButton closeModal={() => {}}>
                <div className={classes.wrap}>
                    <div className={classes.lnd_reg_popup} data-testid="success-mini-reg-modal">
                        <div className={classes.lnd_reg_popup_title} data-testid="title">
                            You’ve registered at
                            {MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].NAME}
                        </div>
                        <div className={classes.lnd_reg_popup_body}>
                            <div className={classes.lnd_reg_popup_item}>
                                <div className={classes.title}>Your name</div>
                                <input
                                    type="text"
                                    data-testid="name"
                                    value={name}
                                    onChange={handleName}
                                    className={`${classes.text} ${classes.text_input}`}
                                />
                                <div className={classes.lnd_reg_popup_copy}>
                                    You can change it here
                                </div>
                            </div>
                            <div className={classes.lnd_reg_popup_item} data-testid="email">
                                <div className={classes.title}>Your login</div>
                                <div className={classes.text}>{userEmail ?? ''}</div>
                            </div>
                            <div className={classes.lnd_reg_popup_item} data-testid="password">
                                <div className={classes.title}>Your password</div>
                                <div className={classes.text}>{userPassword ?? ''}</div>
                            </div>
                            <div
                                className={classes.lnd_reg_popup_ok_btn}
                                onClick={() => {}}
                                data-testid="ok-btn"
                            >
                                Ok
                            </div>
                            <div className={classes.lnd_reg_popup_copy}>
                                Copy of this information will be sent to your email
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWindowModern>
        </>
    );
};

export default ModalRegisteredUser;
