import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CheckboxTogle, Input, Box, SelectDropDown } from 'components/atomsKit/Elements';

import { ageOptions } from 'utils/common';

import cn from 'classnames';

import classes from './ChatFilterPanel.module.scss';

interface IProps {
    className?: string;
    handleIsOnline: () => void;
    isOnline?: boolean;
    templateProp?: any;
    search?: string;
    setSearch?: (e: string) => void;
    configFilterChat?: any;
    hideSerchPanel?: boolean;
    onChangeOption?: any;
    valuesFilter?: any;
}

const getFilterFromLink = (link: string): string => {
    const match = link.match(/filter=([^&]*)/) || '';
    return match ? match[1] : '';
};

const ChatFilterPanel = ({
    handleIsOnline,
    isOnline,
    search,
    setSearch,
    configFilterChat,
    hideSerchPanel,
    valuesFilter,
    onChangeOption,
}: IProps) => {
    const location = useLocation();
    const { search: param } = location;
    const queryParams = new URLSearchParams(param);
    const filterValue = queryParams.get('filter') || '';

    return (
        <section className={classes.panelWrap}>
            {configFilterChat?.length > 0 && (
                <ul className={classes.ul}>
                    {configFilterChat.map(
                        (
                            item: { name: string; link: string; haveNew?: boolean },
                            index: number,
                        ) => (
                            <Link
                                key={item.name}
                                to={item.link}
                                className={cn(
                                    classes.link,
                                    (filterValue === getFilterFromLink(item.link) ||
                                        (index === 0 && !filterValue)) &&
                                        classes.link_isActive,
                                )}
                            >
                                <p className={classes.name}>
                                    {item.name}
                                    {item && item.haveNew && <span className={classes.new} />}
                                </p>
                            </Link>
                        ),
                    )}
                </ul>
            )}
            {setSearch && !hideSerchPanel && (
                <Box flex width100 justify="between" align="center">
                    {isOnline && (
                        <CheckboxTogle
                            value="ONLINE"
                            onChange={handleIsOnline}
                            checked={isOnline}
                            className={classes.onlineWrap}
                        />
                    )}
                    {onChangeOption && (
                        <Box flex align="center">
                            <p className={classes.ageTitle}>Age range</p>
                            <SelectDropDown
                                offDropDownContainer
                                placeholder={'From'}
                                options={ageOptions()}
                                value={valuesFilter.from}
                                onChange={(value: any) =>
                                    onChangeOption('from', { ...value, id: value.value })
                                }
                                className={classes.ageDrop}
                            />
                            <SelectDropDown
                                offDropDownContainer
                                placeholder={'To'}
                                options={ageOptions()}
                                value={valuesFilter.to}
                                onChange={(value: any) =>
                                    onChangeOption('to', { ...value, id: value.value })
                                }
                                className={classes.ageDrop}
                            />
                        </Box>
                    )}
                    <Input
                        placeholder="Search ID"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search ?? ''}
                        className={classes.search}
                    />
                </Box>
            )}
        </section>
    );
};

export default ChatFilterPanel;
