// HIDDEN VIDEO SHOW by SITE.ID
export const HIDDEN_VIDEO_SHOW = [2, 4, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_TUTORIAL_PAGE = [9, 10];
export const HIDDEN_GIFT = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const NOT_HIDE_STRING_STAR = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_CONTACT_REQUEST = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_MEETING_REQUEST = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_POPULAR_CITY = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_POPULAR_COUNTRY = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];
export const HIDDEN_VISA_TRUST = [1, 2, 5, 6, 15, 16, 17, 18, 19, 20, 21, 22];
// export const FB_CONVERSION = [2, 6, 9, 10, 11, 12, 15, 16]; //Replaced with userState?.isFBConversion
export const SNAP_CHAT_CONVERSION = [10];
export const SPECIAL_POLICY_PAGE = [2, 9, 10, 17, 18, 19, 20, 21, 22]; //msd and its clones, special policy page with prefix -200

//Added LEGAL_ENTITY_INFO to pages
export const SITE_SEMPITERNA = [7, 8, 9, 10, 11, 12, 20, 21, 22];
export const SITE_ASTRASOFT = [1, 2, 5, 6, 13, 14, 17, 18, 19];
export const SITE_KRISTALDIGITAL = [15, 16];
export const INFO_SEMPITERNA =
    'SEMPITERNA DIGITAL LIMITED, Code: ΗΕ 440404, address: ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY';
export const INFO_ASTRASOFT =
    'ASTRASOFT PROJECTS LTD, Reg. #ΗΕ 409810, Ifigeneias 14, 3036, Limassol, Cyprus';
export const INFO_KRISTALDIGITAL =
    'KRISTALDIGITAL OÜ, Reg No 16742040, Vesivärava 50-201, 10152 Tallinn Estonia';

export const LATINA_SITES = [11, 13, 16];
export const ASIA_SITES = [12, 14, 15];
