export const PROVIDER_PUBNUB = 'PubnubSender';
export const PROVIDER_PUSHER = 'PusherSender';
export const PROVIDER_SOCKET = 'SocketSender';

export const PROVIDER_STORAGE_KEY = 'sync-provider';
export let PROVIDER_ACTIVE = localStorage.getItem(PROVIDER_STORAGE_KEY) ?? PROVIDER_PUBNUB; // Set active provider

export const MESSAGE_EVENT = 'message';

export const NOTIFY_SENT_TEXT = 'SENT_TEXT';
export const NOTIFY_SENT_AUDIO = 'SENT_AUDIO';
export const NOTIFY_SENT_STICKER = 'SENT_STICKER';
export const NOTIFY_SENT_VIDEO = 'SENT_VIDEO';
export const NOTIFY_SENT_IMAGE = 'SENT_IMAGE';
export const NOTIFY_SENT_WINK = 'SENT_WINK';
export const NOTIFY_LIST = [
    NOTIFY_SENT_TEXT,
    NOTIFY_SENT_AUDIO,
    NOTIFY_SENT_STICKER,
    NOTIFY_SENT_VIDEO,
    NOTIFY_SENT_IMAGE,
];
export const NOTIFY_ALL_MEDIA_LIST = [NOTIFY_SENT_AUDIO, NOTIFY_SENT_VIDEO, NOTIFY_SENT_IMAGE];
// export const MESS_ACTION = 'message';
export const TEMPORARY_INVITE = 'TEMPORARY_INVITE';
export const PERSONALISED_INVITE = 'PERSONALISED_INVITE';
export const PERSONALISED_LETTER = 'PERSONALISED_LETTER';
export const INVITE_ACTION = 'INVITE';
export const NOTIFY_NEW_GIRL_ACTION = 'NEW_GIRL';
export const NOTIFY_STRANGER_ACTION = 'STRANGER';
export const NOTIFY_POSSIBLE_MATCH_ACTION = 'POSSIBLE_MATCH';
export const NOTIFY_PROFILE_UPDATE_ACTION = 'PROFILE_UPDATE';
export const NOTIFY_SEARCH_PARTNER_ACTION = 'SEARCH_PARTNER';
export const NOTIFY_VIEW_PROFILE_ACTION = 'VIEW_PROFILE';
export const NOTIFY_LIKE_ACTION = 'LIKE';
export const NOTIFY_MATCH_ACTION = 'MATCH';
export const NOTIFY_ONLINE_NOW = 'ONLINE_NOW';
export const NOTIFY_MAYBE_APPEAL = 'MAYBE_APPEAL';
export const NOTIFY_NO_CHAT = 'NO_CHAT';
export const NOTIFY_MAYBE_CHAT = 'MAYBE_CHAT';
export const NOTIFY_LETTER = 'LETTER';
export const TRANSACTION_NOTIFY_LIST = [
    NOTIFY_LETTER,
    NOTIFY_ONLINE_NOW,
    NOTIFY_MAYBE_APPEAL,
    NOTIFY_NO_CHAT,
    NOTIFY_MAYBE_CHAT,
    NOTIFY_NEW_GIRL_ACTION,
    NOTIFY_STRANGER_ACTION,
    NOTIFY_POSSIBLE_MATCH_ACTION,
    NOTIFY_PROFILE_UPDATE_ACTION,
    NOTIFY_SEARCH_PARTNER_ACTION,
    NOTIFY_VIEW_PROFILE_ACTION,
    NOTIFY_LIKE_ACTION,
    NOTIFY_MATCH_ACTION,
];
export const TRANSACTION_CONFIG_LIST = [
    ...TRANSACTION_NOTIFY_LIST,
    ...[INVITE_ACTION, TEMPORARY_INVITE, PERSONALISED_INVITE, PERSONALISED_LETTER],
];

export const MESS_WINKED = 'winked';
export const MESS_ACTION = 'message';
export const LIKE_ACTION = 'liked';
export const VIEW_ACTION = 'viewed';
export const MAIL_ACTION = 'mail';
export const READ_MAIL_ACTION = 'read_mail';
export const SUBSCRIBE_ACTION = 'subscribe';
export const CREDITS_ACTION = 'credits';
export const CREDITS_UNAPPROVED = 'credits_unapproved';
export const CREDITS_ISSUE = 'credits_issue';
export const CREDITS_CLOSE = 'credits_close';
export const ABADON_CART = 'abadon_cart';
export const LESS_THEN_CREDITS = 'less_then_credits';
export const GA_EVENT = 'ga_event';
export const TOKEN_ACTION = 'token';
export const ONLINE_STATUS = 'online_status';
export const OPEN_CHAT = 'open_chat';
export const NOTIFY_SETTINGS = 'notification_settings';
export const AB_CONFIG_UPDATE = 'update_transaction_config';
export const GLOBAL_UPDATE = 'global_update';
export const LOGOUT = 'user_logout';
export const QUIZ_ADD_PROMO_CREDITS = 'QUIZ_ADD_PROMO_CREDITS';
export const ADD_BONUS_CREDITS = 'ADD_BONUS_CREDITS';
export const SUPPORT_ADD_BONUS_CREDITS = 'SUPPORT_ADD_BONUS_CREDITS';
export const FRONT_SYNC_TRANSACTION = 'FRONT_SYNC_TRANSACTION';
export const CONFIG_UPDATED = 'CONFIG_UPDATED';
export const SUPPORT_SWITCH_CALL_BUTTON = 'SUPPORT_SWITCH_CALL_BUTTON';
export const CHANGE_SUBSCRIPTION = 'change_subscription';
export const IMBRA_UPDATED = 'IMBRA_UPDATED';

export const SOCKET_PATH = '/api/v2/ws';
