import React from 'react';
import { Image, Textonchat } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './AccordionMenu.module.scss';

interface IProps {
    text: string;
    isOpenOption?: boolean;
    handleOption: () => void;
    marker?: boolean;
    children?: any;
}

const AccordionMenu = ({ text, isOpenOption, handleOption, marker, children }: IProps) => {
    return (
        <>
            <button
                className={cn(classes.optionBtn, isOpenOption && classes.optionBtn_isOpen)}
                onClick={handleOption}
            >
                <Textonchat
                    textAlign={'start'}
                    text={text}
                    size={16}
                    className={cn(classes.option, marker && classes.option_new)}
                />
                <Image
                    name="notif-arr-blue"
                    className={cn(classes.selector, isOpenOption && classes.selector_isOpen)}
                />
            </button>
            {isOpenOption && <div className={classes.list}>{children}</div>}
        </>
    );
};

export default AccordionMenu;
