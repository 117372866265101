import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/atomsKit/Elements';
import classes from './Input.module.scss';

interface IProps {
    onChange: (e: any) => void;
    value: string;
    placeholder?: string;
    icon?: string;
    noBorder?: boolean;
    className?: string;
    type?: string;
    onClickIcon?: any;
    colorIcon?: string;
}

const Input = ({
    onChange,
    value,
    placeholder,
    icon = 'search',
    colorIcon,
    noBorder = true,
    className,
    type,
    onClickIcon,
}: IProps) => {
    return (
        <div className={cn(classes.wrap, className, noBorder && classes.wrap_noBorder)}>
            <input
                className={classes.input}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                type={type}
            />
            {icon && (
                <div className={classes.iconContainer} onClick={onClickIcon}>
                    <Icon
                        icon={icon}
                        size={'100%'}
                        className={classes.icon}
                        style={{ color: colorIcon }}
                    />
                </div>
            )}
        </div>
    );
};

export default Input;
