import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { getLetters } from 'redux/letters/selectors';
import { selectUser } from 'redux/auth/selectors';
import { getReFetchAction } from 'redux/interface/selectors';
import { fetchBalance } from 'redux/auth/operations';

import { getQueryParam, sanitizedSearchItem } from 'utils/common';

import { togleModalContent, setDataModalContent, togleGlobalModal } from 'redux/interface/slice';
import { changePayStatusContent } from 'redux/letters/slice';

import { fetchLetters, delCard } from 'redux/letters/operations';
import { postRequest, getRequest } from 'utils/requests';
import { useDebouncedState } from 'hooks';

const useLettersPage = () => {
    const isFirstRender = useRef(true);
    const [pagination, setPagination] = useState(1);
    const [isOnline, setIsOnline] = useState(false);
    const [search, debouncedSearch, setSearch] = useDebouncedState('');

    const [similarList, setSimilarList] = useState([]);

    const filterChat = getQueryParam('filter') || 'inbox';

    const letters = useSelector(getLetters);
    const userInfo = useSelector(selectUser);

    const socketAction = useSelector(getReFetchAction);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        const isMan = !+userInfo?.user_info?.gender;
        const url = isMan ? '/api/v3/woman/similar' : '/api/v3/man/similar';
        getRequest(url).then((res) => {
            setSimilarList(res?.result);
        });
    }, [userInfo]);

    useEffect(() => {
        getFirsPage();
    }, [filterChat, isOnline, debouncedSearch]);

    useEffect(() => {
        if (isFirstRender?.current) {
            isFirstRender.current = false;
            return;
        }
        if (socketAction?.action === 'mail') getFirsPage();
    }, [socketAction]);

    const getFirsPage = () => {
        setPagination(1);
        dispatch(
            fetchLetters({
                user_id: userInfo?.user_info?.external_id,
                folder: filterChat,
                girl_filter: debouncedSearch ? 'id' : 'all',
                girl_id: sanitizedSearchItem(debouncedSearch),
                letter_filter: 'all',
                online_filter: isOnline ? 'online' : '',
                page: 0,
            }),
        );
    };

    const handleScroll = () => {
        setPagination(pagination + 1);
        dispatch(
            fetchLetters({
                user_id: userInfo?.user_info?.external_id,
                folder: filterChat,
                girl_filter: debouncedSearch ? 'id' : 'all',
                girl_id: sanitizedSearchItem(debouncedSearch),
                letter_filter: 'all',
                online_filter: isOnline ? 'online' : '',
                page: pagination + 1,
            }),
        );
    };

    const handleDel = (chat_id) => {
        dispatch(delCard(chat_id));
    };

    const handleRestore = (chat_id) => {
        const REQ_BODY = {
            mail_id: chat_id,
            user_id: userInfo?.user_info?.external_id,
        };
        postRequest('/api/mailbox/restoremail', REQ_BODY).then(() => {
            getFirsPage();
        });
    };

    const handleContent = (content, playFunc) => {
        if (content.isNotPayed) {
            if (content?.type === 'SENT_IMAGE') {
                dispatch(setDataModalContent([content]));
                dispatch(togleModalContent(true));
            } else if (content?.type === 'SENT_VIDEO') {
                postRequest('/api/mailbox/readvideo', {
                    attachment_id: content?.content?.attachment_id,
                    user_id: userInfo?.user_info?.external_id,
                }).then((res) => {
                    if (res?.status === true) {
                        dispatch(fetchBalance());
                        dispatch(
                            changePayStatusContent({
                                chat_id: content?.chat_id,
                                attachment_id: content?.content?.attachment_id,
                                link: res?.message_content,
                                id: content?.id,
                            }),
                        );
                        dispatch(
                            setDataModalContent([
                                {
                                    ...content,
                                    original: res?.message_content,
                                    thumbnail: res?.message_content,
                                },
                            ]),
                        );
                        dispatch(togleModalContent(true));
                    } else {
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: res?.title,
                                description: res?.message,
                                btnText: 'Got it',
                            }),
                        );
                    }
                });
            } else {
                postRequest('/api/mailbox/readaudio', {
                    attachment_id: content?.attachment_id,
                    user_id: userInfo?.user_info?.external_id,
                }).then((res) => {
                    if (res.status === true) {
                        playFunc().src = res?.message_content;
                        playFunc().play();
                        dispatch(fetchBalance());
                    } else {
                        playFunc().pause();
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: res?.title,
                                description: res?.message,
                                btnText: 'Got it',
                            }),
                        );
                    }
                });
            }
        } else {
            if (content?.type !== 'SENT_AUDIO') {
                dispatch(setDataModalContent([content]));
                dispatch(togleModalContent(true));
            } else {
                playFunc().play();
            }
        }
    };

    const handleSimilar = (external_id) => {
        navigate(`/profile-woman/${external_id}`);
    };

    return {
        letters,
        handleDel,
        handleRestore,
        handleScroll,
        isOnline,
        setIsOnline,
        search,
        setSearch,
        handleContent,
        handleSimilar,
        similarList,
        filterChat,
    };
};

export default useLettersPage;
