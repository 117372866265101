import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Box,
    Button,
    ModalWindowModern,
    InputModern,
    TextNameonChat,
    Textonchat,
} from 'components/atomsKit/Elements';

import { selectMyProfile } from 'redux/auth/selectors';
import { togleGlobalModal } from 'redux/interface/slice';

import { postRequest } from 'utils/requests';

import classes from './ModalConfirmMail.module.scss';

interface IProps {
    modalIsOpen: boolean;
    closeModal: any;
}

const ModalConfirmMail = ({ modalIsOpen, closeModal }: IProps) => {
    const [value, setValue] = useState(useSelector(selectMyProfile)?.user_detail?.email);

    const dispatch = useDispatch();

    const onChangeMail = (e: any) => {
        setValue(e.target.value);
    };

    const handleConfirm = (e: any) => {
        e.preventDefault();
        postRequest('/api/user/changeEmail', { email: value }).then((res: any) => {
            if (res.status) {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',

                        text: 'Confirmation was sent',
                        description: `Please go to your Inbox ${value}. Check for a confirmation email. If you didn't receive the email, please check your Spam/Junk folder.`,

                        btnText: 'Got it',
                    }),
                );
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: res.message,
                        btnText: 'Got it',
                    }),
                );
            }
            closeModal();
        });
    };

    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <form onSubmit={handleConfirm}>
                <Box
                    className={classes.modalBody}
                    flex
                    align="center"
                    justify="center"
                    directionColumn
                >
                    <TextNameonChat
                        className={classes.title}
                        text="Confirm your email"
                        size={18}
                        textAlign={'center'}
                    />
                    <Textonchat
                        text="A confirmation email will be sent to the email address you provided."
                        margin="20px 0 4px 0"
                    />
                    <Textonchat
                        textAlign={'center'}
                        className={classes.description}
                        text="Important: after confirming the mail, your login to the site will be changed."
                    />

                    <InputModern
                        type={'email'}
                        placeholder="Your email"
                        value={value}
                        onChange={onChangeMail}
                    />

                    <Button
                        text="Confirm email"
                        icon="x1"
                        margin="24px 0 0 0"
                        fitContent
                        padding="0 40px 0 40px"
                    />
                </Box>
            </form>
        </ModalWindowModern>
    );
};

export default ModalConfirmMail;
