import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import usePageUtils from 'hooks/usePageUtils';
import { useUploadFile, useSendSupportReq } from 'hooks';

import {
    getWomanData,
    getLettersUser,
    isLoadingLetters,
    getLettersChat,
} from 'redux/letters/selectors';
import { selectMyProfile } from 'redux/auth/selectors';
import {
    togleGlobalModal,
    togleModalContent,
    setDataModalContent,
    changeProfileForGift,
} from 'redux/interface/slice';
import { changePayStatusContent } from 'redux/letters/slice';
import { fetchBalance } from 'redux/auth/operations';
import {
    fetchLettersUser,
    setbookmark,
    unsetbookmark,
    trashmail,
    setFavorite,
} from 'redux/letters/operations';
import { changePayStatus } from 'redux/letters/slice';
import { chatCounter } from 'redux/interface/operations';

import { parserSelectedMediaToMailAttachments } from 'utils/common';
import { postRequest } from 'utils/requests';
import { getQueryParam } from 'utils/common';

const useLetterProfilePage = () => {
    const [valueMessage, setValueMessage] = useState('');

    const [isOpenModalAddBlock, setIsOpenModalAddBlock] = useState(false);

    const isLoading = useSelector(isLoadingLetters);
    const womanData = useSelector(getWomanData);
    const chats = useSelector(getLettersUser);
    const chat = useSelector(getLettersChat);

    const isFavorite = chat?.female_favorite;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_name = useSelector(selectMyProfile)?.user_detail?.name;

    // const { external_id } = useParams();
    const external_id = getQueryParam('profileId');

    const {
        pagination,
        setPagination,
        wrapListRef,
        userInfo,
        userExternalId,
        onScrollBottom,
        setOnScrollBottom,
        togle,
        togleAction,
        setPositionScroll,
    } = usePageUtils({
        startPage: 1,
        trigerScrollDown: [chats],
        rulesSocket: {
            func: (action) => {
                if (action.action === 'read_mail' && external_id === action?.external_id) {
                    return true;
                } else {
                    return false;
                }
            },
            rules: ['message'],
        },
    });

    const {
        handleUploadFile,
        uploadFiles,
        handleDelMedia,
        handleSendMedias,
        handleItemMedia,
        handleOpenUpploadFile,
        handleUploadNavBtn,
        uploadLoader,
        navConfig,
        isOpenModaAddFile,
        setIsOpenModaAddFile,
        selectedMedia,
        setSelectedMedia,
    } = useUploadFile({
        userExternalId,
    });

    const {
        sendSupportReq,
        selectFileToComplaint,
        setSelectFileToComplaint,
        describe,
        isOpenModalComplaint,
        setIsOpenModalComplaint,
        mail,
        changeFileToComplaint,
        handleMail,
        onChangeDescribe,
    } = useSendSupportReq();

    useEffect(() => {
        setPositionScroll(chats[0]?.mail.id);
        dispatch(
            fetchLettersUser({
                folder: 'dialog',
                girl_id: +external_id,
                page: pagination,
                user_id: userInfo?.user_info?.external_id,
            }),
        );
    }, [pagination, togle]);

    useEffect(() => {
        dispatch(chatCounter(userExternalId));
    }, []);

    useEffect(() => {
        if (chats?.length > 0) {
            const isUserCard =
                chats[chats?.length - 1]?.mail?.male_id ===
                chats[chats?.length - 1]?.mail?.sender_id
                    ? true
                    : false;
            const paid_read = chats[chats?.length - 1]?.mail?.paid_read;
            const payed = chats[chats?.length - 1]?.mail?.payed;
            const read_status = chats[chats?.length - 1]?.mail?.read_status;

            postRequest('/api/mailbox/readmail', {
                girl_id: +external_id,
                mail_id: chats[chats?.length - 1]?.mail?.id,
                user_id: userInfo?.user_info?.external_id,
            });

            if (!isUserCard && paid_read === 1 && payed === 1 && read_status === 0) {
                postRequest('/api/mailbox/paidReadMail', {
                    girl_id: womanData?.external_id,
                    mail_id: chats[chats?.length - 1]?.mail?.id,
                });
            }
        }
    }, [chats]);

    const handleValueMessage = (e) => {
        if (onScrollBottom === false) {
            setOnScrollBottom(true);
        }
        setValueMessage(e.target.value);
    };

    const onChangeSendMessage = () => {
        const trimmedText = valueMessage.trim();
        if (trimmedText !== '') {
            if (onScrollBottom === false) {
                setOnScrollBottom(true);
            }
            const REQ_BODY = {
                attachments: parserSelectedMediaToMailAttachments(selectedMedia),
                message_content: valueMessage,
                message_type: 'SENT_TEXT',
                parent_mail_id: null,
                recipients: [+womanData?.external_id],
                user_id: userExternalId,
            };
            postRequest('/api/mailbox/mail', REQ_BODY)
                .then((res) => {
                    if (res?.status) {
                        dispatch(fetchBalance());
                        setPagination(1);
                        togleAction();
                    } else {
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: res?.title,
                                description: res?.message,
                                btnText: 'Got it',
                            }),
                        );
                    }
                })
                .catch(() => {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: 'You lack credits',
                            btnText: 'Get credits',
                            description: 'You need to get more credits to be able to watch video',
                        }),
                    );
                });
            setValueMessage('');
            setSelectedMedia([]);
        }
    };

    const onChangeSetbookmark = (mail_id, user_id, bookmark) => {
        dispatch(
            bookmark === 0
                ? setbookmark({
                      mail_id: mail_id,
                      user_id: user_id,
                  })
                : unsetbookmark({
                      mail_id: mail_id,
                      user_id: user_id,
                  }),
        );
    };

    const onChangeTrashmail = (mail_id, user_id, outbox) => {
        dispatch(
            trashmail(
                outbox
                    ? { mail_id: mail_id, outbox: true, user_id: user_id }
                    : { mail_id: mail_id, user_id: user_id },
            ),
        );
    };

    const handleFavorite = (favorite) => {
        dispatch(
            setFavorite({
                chat_uid: chat?.chat_uid,
                favorite: !favorite,
                user_id: userExternalId,
            }),
        );
    };

    const handleDropBtnLeter = () => {
        navigate(`/chat/${chat?.chat_uid}?profile=${womanData?.external_id}`);
    };

    const handleDropBtnBlock = () => {
        setIsOpenModalAddBlock(true);
    };
    const handleDropBtnComplain = () => {
        setIsOpenModalComplaint(true);
    };

    const handleAddBlock = () => {
        postRequest('/api/chat/addBlackList', {
            user_id: userExternalId,
            chat_uid: chat?.chat_uid,
        }).then(() => {
            navigate(`/letters/inbox`);
        });
    };

    const handleProfile = (id) => {
        navigate(`/profile-woman/${id}`);
    };

    const handleContent = (content, playFunc) => {
        if (content?.isNotPayed) {
            if (content?.type === 'SENT_IMAGE') {
                dispatch(setDataModalContent([content]));
                dispatch(togleModalContent(true));
            } else if (content?.type === 'SENT_VIDEO') {
                postRequest('/api/mailbox/readvideo', {
                    attachment_id: content?.content?.attachment_id,
                    user_id: userInfo?.user_info?.external_id,
                }).then((res) => {
                    if (res?.status === true) {
                        dispatch(
                            changePayStatusContent({
                                chat_id: content?.chat_id,
                                attachment_id: content?.content?.attachment_id,
                                link: res?.message_content,
                                id: content?.id,
                            }),
                        );
                        dispatch(
                            setDataModalContent([
                                {
                                    ...content,
                                    original: res?.message_content,
                                    thumbnail: res?.message_content,
                                },
                            ]),
                        );
                        dispatch(togleModalContent(true));
                    } else {
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: res?.title,
                                description: res?.message,
                                btnText: 'Got it',
                            }),
                        );
                    }
                });
            } else {
                postRequest('/api/mailbox/readaudio', {
                    attachment_id: content?.attachment_id,
                    user_id: userInfo?.user_info?.external_id,
                }).then((res) => {
                    if (res?.status === true) {
                        dispatch(fetchBalance());
                        playFunc().src = res?.message_content;
                        playFunc().play();
                    } else {
                        playFunc().pause();
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: res?.title,
                                description: res?.message,
                                btnText: 'Got it',
                            }),
                        );
                    }
                });
            }
        } else {
            if (content?.type !== 'SENT_AUDIO') {
                dispatch(setDataModalContent([content]));
                dispatch(togleModalContent(true));
            } else {
                playFunc().play();
            }
        }
    };

    const handlePay = (id) => {
        postRequest('/api/mailbox/paidReadMail', {
            girl_id: +womanData?.external_id,
            mail_id: id,
        }).then((res) => {
            if (res?.status) {
                dispatch(changePayStatus({ id, message_content: res?.message_content }));
                dispatch(chatCounter(userExternalId));
                dispatch(fetchBalance());
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: res?.title,
                        description: res?.message,
                        btnText: 'Got it',
                    }),
                );
            }
        });
    };

    const handleSendGift = () => {
        const infoForSend = {
            id: womanData?.external_id,
            public_external_id: womanData?.public_external_id,
            name: womanData?.name,
            age: womanData?.age,
            isOnline: womanData?.online,
            flag: womanData?.country_icon,
            avatar: womanData?.photo_link,
            city: womanData?.city_name,
        };
        dispatch(changeProfileForGift(infoForSend));
        navigate('/gifts');
    };

    return {
        womanData,
        isLoading,
        chats,
        connect: chat?.connect,
        valueMessage,
        handleValueMessage,
        onChangeSendMessage,
        wrapListRef,
        userExternalId,
        onChangeSetbookmark,
        onChangeTrashmail,
        handleFavorite,
        isFavorite,
        handleDropBtnLeter,
        handleDropBtnBlock,
        handleDropBtnComplain,
        isOpenModalAddBlock,
        setIsOpenModalAddBlock,
        handleAddBlock,
        isOpenModalComplaint,
        setIsOpenModalComplaint,
        changeFileToComplaint,
        selectFileToComplaint,
        setSelectFileToComplaint,
        handleMail,
        mail,
        onChangeDescribe,
        describe,
        sendSupportReq,
        handleOpenUpploadFile,
        handleContent,

        selectedMedia,
        handleUploadFile,
        navConfig,
        isOpenModaAddFile,
        setIsOpenModaAddFile,
        handleUploadNavBtn,
        uploadFiles,
        handleItemMedia,
        handleSendMedias,
        handleDelMedia,
        handleProfile,
        handlePay,
        user_name,
        uploadLoader,
        handleSendGift,
    };
};

export default useLetterProfilePage;
