import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { ChatList, ChatFilterPanel, PageWrap, EmptyState } from 'components/atomsKit/Elements';
import { configFilterChat } from 'data/config';
import useChatPage from './useChatPage';
import classes from './ChatPage.module.scss';

const ChatPage = () => {
    const {
        chatList,
        handleIsOnline,
        isOnline,
        setSearch,
        search,
        handleDelCardChat,
        handleCard,
        handleScroll,
    } = useChatPage();

    const navigate = useNavigate();

    return (
        <>
            <PageWrap height100>
                <div className={classes.baners}>
                    <ChatFilterPanel
                        handleIsOnline={handleIsOnline}
                        isOnline={isOnline}
                        search={search}
                        setSearch={setSearch}
                        configFilterChat={configFilterChat}
                    />
                </div>
                <div className={classes.pageContent}>
                    {chatList.length > 0 ? (
                        <>
                            <InfiniteScroll
                                dataLength={chatList.length}
                                next={handleScroll}
                                hasMore={true}
                                scrollableTarget="forScrollChat"
                                scrollThreshold={0.7}
                                loader={<></>}
                            >
                                <></>
                            </InfiniteScroll>
                            <ChatList
                                scrollableTarget="forScrollChat"
                                chatList={chatList}
                                handleCard={handleCard}
                                handleDelCardChat={handleDelCardChat}
                            />
                        </>
                    ) : (
                        <EmptyState
                            config={{
                                title: 'No chats found',
                                description:
                                    'To find someone to chat with, you may use the following options:',
                                buttons: [
                                    {
                                        text: 'Like,',
                                        onClick: () => {
                                            navigate('/like');
                                        },
                                    },
                                    {
                                        text: 'Search',
                                        onClick: () => {
                                            navigate('/search');
                                        },
                                    },
                                    // {
                                    //   text: "Video",
                                    //   onClick: () => {
                                    //     navigate("/menu/video");
                                    //   },
                                    // },
                                ],
                            }}
                        />
                    )}
                </div>
            </PageWrap>
        </>
    );
};

export default ChatPage;
