export const MAX_LENGTH_TABLE = 25;
export const MAX_LENGTH_ANSWER = 270;

export const ARRAY_SMALL_ANSWER = [6, 7, 9];
export const ANSWER_WITHOUT_DETAILS = [4, 11];

export const QUESTIONS_DATA = [
    {
        id: 1,
        question:
            '1. Do you have any temporary or permanent civil protection order or restraining order issued against you?',
        questionDetails:
            'If Yes, Please provide details of such order including date, city and State.',
    },
    {
        id: 2,
        question:
            '2. Any arrest or conviction of the client or person for homicide, murder, manslaughter, assault, battery, domestic violence, rape, sexual assault, abusive sexual contact, sexual exploitation, incest, child abuse or neglect, torture, trafficking, peonage, holding hostage, involuntary servitude, slave trade, kidnapping, abduction, unlawful criminal restraint, false imprisonment, stalking, or any similar activity in violation of Federal, State or local criminal law?',
        questionDetails: 'If Yes so please provide details.',
    },
    {
        id: 3,
        question:
            '3. Have you ever had any Federal, State, or local arrest or conviction for offenses related to controlled substances or alcohol?',
        questionDetails: 'If Yes so please provide details',
    },
    {
        id: 4,
        question: '4. Are you currently married?',
        questionDetails: '',
    },
    {
        id: 5,
        question: '5. Have you ever been married?',
        questionDetails:
            'If Yes, indicate how many times you have been married, date and place of marriage, and date and place of dissolution/termination of each marriage?',
    },
    {
        id: 6,
        question: '6. Has your spouse ever died?',
        questionDetails: 'If Yes, for what reason?',
    },
    {
        id: 7,
        question:
            '7. Have you previously sponsored an alien fiancée to whom you were engaged or married?',
        questionDetails: 'If Yes please provide details.',
    },
    {
        id: 8,
        question: '8. Do you have children?',
        questionDetails: 'If Yes, where do they live (address).',
    },
    {
        id: 9,
        question: '9. Do you have any children who are under the age of 18?',
        questionDetails: ' If Yes please list how many. First Names and ages of Children.',
    },
    {
        id: 10,
        question:
            '10. List all States and countries in which you have resided since the age of 18:',
        questionDetails:
            'If  Yes, Please provide details of such order including date, city and State.',
    },
    {
        id: 11,
        question: '11. Are there any records on your name here: ',
        questionLink: 'http://www.nsopr.gov',
        questionDetails: '',
    },
];
