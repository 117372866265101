import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectTail } from 'redux/auth/selectors';

export const useTransition = () => {
    const navigate = useNavigate();
    const currentTail = useSelector(selectTail);
    const addTail = (tail, userTail = false) => {
        const secondPartTail =
            currentTail?.response?.tail?.substring(0, 1) === '?'
                ? currentTail?.response?.tail?.slice(1)
                : currentTail?.response?.tail;

        if (userTail && secondPartTail?.length > 0) {
            navigate(`${window.location.pathname}${tail ?? ''}&${secondPartTail}`);
        } else {
            navigate(`${window.location.pathname}${tail ?? ''}`);
        }
    };

    return {
        addTail,
    };
};
