import React from 'react';
import Step1 from './Step1/index';
import Step2 from './Step2/index';
import Step3 from './Step3/index';
import Step4 from './Step4/index';
import Step5 from './Step5/index';
import Step6 from './Step6/index';

export const steepConfig = [
    {
        title: 'Complete your profile',
        text: 'BLet other users get to know you better and make your conversation more pleasant and productive. Add your profile photo, information about yourself and a person you would like to find on the sitelablalba',
    },
    {
        title: 'Choose “Search” page to look for a member',
        text: `Our platform's "Search" feature allows members to easily find and connect with others.`,
    },
    {
        title: 'Learn everything about a member using the profile',
        text: `There is all the info about a member: photos, videos, the purpose of staying on our site and what partner the member is looking for`,
    },
    {
        title: 'Find a member you’ve got interested in',
        text: `On our site, you will find more than 800 charming members focused on a long-term communication.`,
    },
    {
        title: 'Use chats for great communication',
        text: `Share your thoughts and feelings sending messages in the chat. For more convenient communication, add a member you like to your favorites.`,
    },
    {
        title: 'Notifications will help you not to miss important info',
        text: `Attention: if you've liked a member's profile and he/she in turn has liked yours you get matched. Start communicating with this user at once, something great may be waiting for you!`,
    },
];

export const steepJSX = {
    0: () => <Step1 />,
    1: () => <Step2 />,
    2: () => <Step3 />,
    3: () => <Step4 />,
    4: () => <Step5 />,
    5: () => <Step6 />,
};
