import { createSlice } from '@reduxjs/toolkit';
import {
    fetchLetters,
    fetchLettersUser,
    delCard,
    setbookmark,
    unsetbookmark,
    trashmail,
    setFavorite,
} from './operations';

import { updateStatusOnline } from 'utils/common';

const lettersInitialState = {
    letters: [],
    currentChat: { chats: [] },
    isLoading: false,
};

const lettersSlice = createSlice({
    name: 'letters',
    initialState: lettersInitialState,
    reducers: {
        changePayStatus(state, action) {
            state.currentChat.chats = state.currentChat.chats?.map((item) =>
                item.mail?.id !== action.payload.id
                    ? item
                    : {
                          ...item,
                          mail: {
                              ...item.mail,
                              read_status: 1,
                              message_content: action.payload.message_content,
                          },
                      },
            );
        },
        changePayStatusContent(state, action) {
            state.letters = state.letters.map((item) =>
                item.mail.chat_id !== action.payload.chat_id
                    ? item
                    : {
                          ...item,
                          attachments: {
                              ...item.attachments,
                              [action.payload.attachment_id]: {
                                  ...item.attachments[action.payload.attachment_id],
                                  attachment_payed: 1,
                                  link: action.payload.link,
                              },
                          },
                      },
            );
            state.currentChat.chats = state.currentChat.chats.map((item) =>
                item?.mail?.id !== action.payload.id
                    ? item
                    : {
                          ...item,
                          attachments: {
                              ...item.attachments,
                              [action.payload.attachment_id]: {
                                  ...item.attachments[action.payload.attachment_id],
                                  attachment_payed: 1,
                                  link: action.payload.link,
                              },
                          },
                      },
            );
        },

        updateStatusOnlineLetterList(state, action) {
            const data = state?.letters;
            if (data) {
                state.letters = updateStatusOnline(
                    action.payload,
                    data,
                    false,
                    'mail',
                    'sender_online',
                    'sender_id',
                );
            }
        },
        updateStatusOnlineLetterProfile(state, action) {
            const data = state?.currentChat?.womanData;
            if (data) {
                state.currentChat.womanData = updateStatusOnline(action.payload, data);
            }
        },
    },
    extraReducers: {
        [fetchLetters.pending](state) {
            state.currentChat = { chats: [] };
            state.isLoading = true;
        },
        [fetchLetters.fulfilled](state, action) {
            state.isLoading = false;
            state.letters =
                action.payload.page === 0
                    ? action.payload.res
                    : [...(state.letters || []), ...(action.payload.res || [])];
        },
        [fetchLetters.rejected](state) {
            state.isLoading = false;
        },

        [delCard.pending](state) {
            state.isLoading = true;
        },
        [delCard.fulfilled](state, action) {
            state.letters = state.letters.filter((item) => item.mail?.chat_id !== action.payload);
            state.isLoading = false;
        },
        [delCard.rejected](state) {
            state.isLoading = false;
        },

        [fetchLettersUser.pending](state, action) {
            if (action.meta.arg.page === 1) {
                state.isLoading = true;
                state.items = [];
            }
        },
        [fetchLettersUser.fulfilled](state, action) {
            if (action.meta.arg.page === 1) {
                state.currentChat.chats = action?.payload?.mails || [];
                state.currentChat.womanData = action.payload.womanInfo;
                state.currentChat.chat = action.payload.chat;
            } else {
                state.currentChat.chats = [
                    ...(action.payload.mails || []),
                    ...(state.currentChat.chats || []),
                ];
            }
            state.isLoading = false;
        },
        [fetchLettersUser.rejected](state) {
            state.isLoading = false;
        },

        [setbookmark.fulfilled](state, action) {
            state.currentChat.chats = state.currentChat.chats?.map((item) =>
                item.mail.id !== action.meta.arg.mail_id
                    ? item
                    : {
                          ...item,
                          mail: { ...item.mail, bookmark: 1 },
                      },
            );
        },
        [unsetbookmark.fulfilled](state, action) {
            state.currentChat.chats = state.currentChat.chats?.map((item) =>
                item.mail.id !== action.meta.arg.mail_id
                    ? item
                    : {
                          ...item,
                          mail: { ...item.mail, bookmark: 0 },
                      },
            );
        },

        [trashmail.fulfilled](state, action) {
            state.currentChat.chats = state.currentChat.chats?.filter(
                (item) => item.mail.id !== action.meta.arg.mail_id,
            );
        },

        [setFavorite.fulfilled](state, action) {
            state.currentChat.chat.female_favorite = action.payload;
        },
    },
});

export const {
    changePayStatus,
    changePayStatusContent,
    updateStatusOnlineLetterList,
    updateStatusOnlineLetterProfile,
} = lettersSlice.actions;

export const lettersReducer = lettersSlice.reducer;
