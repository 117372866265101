import { createAsyncThunk } from '@reduxjs/toolkit';

import { postRequest, getRequest } from 'utils/requests';
import { togleGlobalModal } from '../interface/slice';
import { fetchBalance } from 'redux/auth/operations';

export const fetchChatList = createAsyncThunk('chat/fetchChatList', async (REQ_BODY, thunkAPI) => {
    try {
        const response = await postRequest('/api/chatList/mobile/chatlist', REQ_BODY);

        return response.response;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
    }
});

export const delCardChat = createAsyncThunk('chat/delcardchat', async (REQ_BODY, thunkAPI) => {
    try {
        await postRequest('/api/chat/hideChat', REQ_BODY);
        return REQ_BODY;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
    }
});

// !!!!!!!! убрать этот костыль с созданием чат комнаты - она должна создаваться на бэке
// или переходить не по номеру комнаты а по айдишнику девушки и данный по комнате получать
// именно по айдишнику
export const getMessages = createAsyncThunk('chat/getmessages', async (action, thunkAPI) => {
    let newChat_uid = '';
    try {
        if (action.chat_uid === 'null') {
            await getRequest(
                `api/chat/chatWithProfile?male_id=${action.male_id}&female_id=${action.female_id}`,
            ).then((res) => {
                newChat_uid = res.chat_uid;
            });
        }
        const response = await postRequest(
            'api/chatList/mobile/manChatHistory',
            action.chat_uid === 'null' ? { ...action, chat_uid: newChat_uid } : action,
        );
        return response.response;
    } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
    }
});

export const setFavorite = createAsyncThunk('chat/setfavorite', async (req_body, thunkAPI) => {
    try {
        await postRequest('/api/chat/setFavorite', {
            chat_uid: req_body.chat_uid,
            favorite: req_body.favorite,
            user_id: req_body.user_id,
        });
        return req_body.favorite;
    } catch (e) {
        thunkAPI.dispatch(
            togleGlobalModal({
                isOpen: 'open',
                text: 'You lack credits',
                description: `You need to get more credits to be able to send messages.`,
                btnText: 'Get credits',
            }),
        );
        return thunkAPI.rejectWithValue(e.message);
    }
});

export const sendMessage = createAsyncThunk('chat/sendmessage', async (REQ_BODY, thunkAPI) => {
    try {
        const response = await postRequest('/api/chat/message', REQ_BODY);
        if (response.status) {
            thunkAPI.dispatch(fetchBalance());
            return response?.response?.message_object;
        }
        thunkAPI.dispatch(
            togleGlobalModal({
                isOpen: 'open',
                text: 'You lack credits',
                description: `You need to get more credits to be able to send messages.`,
                btnText: 'Get credits',
            }),
        );
        return thunkAPI.rejectWithValue();
    } catch (e) {
        thunkAPI.dispatch(
            togleGlobalModal({
                isOpen: 'open',
                text: 'You lack credits',
                description: `You need to get more credits to be able to send messages.`,
                btnText: 'Get credits',
            }),
        );
        return thunkAPI.rejectWithValue(e.message);
    }
});
