import React from 'react';
import cn from 'classnames';
import classes from './ProgressBarPagination.module.scss';

interface IProps {
    steep: number;
    length: number;
}

const ProgressBarPagination = ({ steep, length }: IProps) => {
    return (
        <ul className={classes.wrap}>
            {Array.from({ length }, (_, i) => (
                <li key={i} className={cn(classes.steep, i === steep && classes.steep_current)} />
            ))}
        </ul>
    );
};

export default ProgressBarPagination;
