import React from 'react';
import { Avatar, ButtonIcon, ButtonWithDropdown, Box, Image } from 'components/atomsKit/Elements';

import classes from './ProfileHeder.module.scss';

interface IProps {
    name: string;
    age: number;
    external_id: number;
    public_external_id: number;
    country: string;
    city: string;
    isOnline?: boolean;
    isFavorire?: boolean;
    isLike?: boolean;
    is_blocked?: boolean;
    imgLink: string;
    country_icon?: string;
    country_code?: string;
    handleProfile: any;
    handleFavorite: any;
    bodyMoreBtn?: any;
}

const ProfileHeder = ({
    name,
    age,
    external_id,
    public_external_id,
    city,
    isOnline,
    isFavorire,
    isLike,
    is_blocked,
    imgLink,
    country_icon,
    country_code,
    handleProfile,
    handleFavorite,
    bodyMoreBtn,
}: IProps) => {
    return (
        <div className={classes.wrpapFixPanel}>
            <div
                className={classes.wrapAvatarInfo}
                onClick={() => {
                    handleProfile(external_id);
                }}
            >
                <Avatar small isOnline={isOnline} imgLink={imgLink} />
                <div className={classes.info}>
                    <p className={classes.name}>
                        {name}, {age}
                        {!!isLike && <Image name="c3-like" className={classes.like} />}
                    </p>
                    <div className={classes.location}>
                        <p className={classes.id}>ID:{public_external_id}</p>
                        {country_icon ? (
                            <img
                                src={country_icon}
                                alt=""
                                width="15px"
                                className={classes.flag}
                                loading="lazy"
                            />
                        ) : (
                            <img
                                src={country_code}
                                alt=""
                                width="15px"
                                className={classes.flag}
                                loading="lazy"
                            />
                        )}

                        <span className={classes.id}>{city}</span>
                    </div>
                </div>
            </div>
            <Box flex>
                {!is_blocked && (
                    <ButtonIcon
                        icon="star"
                        text="Favorites"
                        onClick={() => handleFavorite(isFavorire)}
                        color={isFavorire ? '#F2C94C' : 'white'}
                    />
                )}
                <ButtonWithDropdown>{bodyMoreBtn}</ButtonWithDropdown>
            </Box>
        </div>
    );
};

export default ProfileHeder;
