import React from 'react';
import { LetterCard } from 'components/atomsKit/Elements';

import classes from './LetterList.module.scss';

interface IProps {
    letterList: any;
    handleDel: (chat_id: number) => void;
    handleRestore: (chat_id: number) => void;
    scrollableTarget: string;
    handleContent?: any;
    price:
        | {
              [key: string]: number;
          }
        | undefined;
    filterChat: string;
}

const LetterList = ({
    letterList,
    handleDel,
    scrollableTarget,
    handleContent,
    price,
    filterChat,
    handleRestore,
}: IProps) => {
    const isNotPayed = (chat: any) => {
        const isUserCard = chat?.mail?.male_id === chat?.mail?.sender_id;
        const paid_read = chat?.mail?.paid_read;
        const payed = chat?.mail?.payed;
        const read_status = chat?.mail?.read_status;

        return !isUserCard && paid_read === 0 && payed === 1 && read_status === 0 ? true : false;
    };

    const isFreeReading = (chat: any) => {
        const isUserCard = chat?.mail?.male_id === chat?.mail?.sender_id;
        const paid_read = chat?.mail?.paid_read;
        const payed = chat?.mail?.payed;
        const read_status = chat?.mail?.read_status;

        return !isUserCard && paid_read === 1 && payed === 1 && read_status === 0 ? true : false;
    };

    return (
        <ul className={classes.wrap} id={scrollableTarget}>
            {letterList.map((item: any) => (
                <LetterCard
                    filterChat={filterChat}
                    price={price}
                    freeRead={isFreeReading(item)}
                    blure={isNotPayed(item)}
                    handleContent={handleContent}
                    cardInfo={item.mail}
                    attachments={Object.keys(item.attachments).map(
                        (option) => item.attachments[option],
                    )}
                    key={item.mail.id}
                    handleDel={handleDel}
                    handleRestore={handleRestore}
                />
            ))}
        </ul>
    );
};

export default LetterList;
