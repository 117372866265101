import React from 'react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const HardResetPage = () => {
    useEffect(() => {
        window.location.reload();
    }, []);
    return <Navigate to="/login" />;
};

export const HardResetStorePage = () => {
    useEffect(() => {
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.trim().startsWith('expires')
                ? c
                : `${c}; expires=${new Date(0).toUTCString()}`;
        });
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }, []);
    return <Navigate to="/login" />;
};

export const HardResetReloginPage = () => {
    useEffect(() => {
        document.cookie.split(';').forEach((c) => {
            if (c.trim().startsWith('token')) {
                document.cookie = `${c}; expires=${new Date(0).toUTCString()}`;
            }
        });
        localStorage.removeItem('token');
        localStorage.removeItem('appState');
        localStorage.removeItem('fingerprintHash');
        window.location.reload();
    }, []);
    return <Navigate to="/login" />;
};
