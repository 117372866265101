import { useEffect } from 'react';
import { getUrlParameter, addUrlParameter, injectParam } from 'utils/common';

import { useNavigate } from 'react-router-dom';
import { getRequest } from 'utils/requests';

export const useInitCheckIP = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const tailFromLS = localStorage.getItem('lnd_tail') ?? '';

        if (!tailFromLS?.includes('utm_unik')) {
            getRequest('/api/login/checkCountIp').then((res) => {
                const newParam = `?utm_unik=${!+res?.count}`;
                const params = injectParam(window?.location?.search?.substring(1), newParam, 0);
                const myUrlArr = params?.split(/[/&]|[/?]/);
                const filteredMyUrl = `?${myUrlArr
                    ?.filter(
                        (item, index) =>
                            myUrlArr?.indexOf(item) === index &&
                            item?.length > 0 &&
                            item !== `utm_unik=${!!+res?.count}`,
                    )
                    ?.join('&')}`;

                if (filteredMyUrl?.length) navigate(filteredMyUrl, { replace: true });
            });
        } else if (
            !window?.location?.search?.includes('utm_unik') ||
            !window?.location?.search?.length
        ) {
            const accessKey = getUrlParameter('accessKey');
            const accessKeyUtm = accessKey?.length ? `&accessKey=${accessKey}` : '';
            addUrlParameter('utm_unik', false);

            if (tailFromLS) navigate(`${tailFromLS}${accessKeyUtm}`);
        }
    }, [navigate]);
};
