import React from 'react';
import { Button, Stick, InfoGirl, Box, ButtonIconNav, Image } from 'components/atomsKit/Elements';

import classes from './CardProfile.module.scss';

interface IProps {
    public_external_id: number;
    external_id: number;
    chat_uid: string;
    name?: string;
    age?: number;
    isLiked?: boolean;
    online?: boolean;
    video?: boolean;
    img: string;
    handleProfile: (e: number) => void;
    handleChat: (e: string, id: number) => void;
    handleLike: (e: number) => void;
    handlePlayVideo: (e: number) => void;
    handlCardPhoto: any;
    isVideoCard?: boolean;
    avatar?: string | undefined;
    city_name?: string;
    country_icon?: string;
    handleLetters: (e: number) => void;
    screenVideoImg?: (e: number) => string;
    wink?: number | boolean;
}

const CardProfile = ({
    external_id,
    chat_uid,
    wink,
    name,
    age = 34,
    isLiked,
    img,
    handleProfile,
    handleChat,
    handleLike,
    handlePlayVideo,
    online,
    handlCardPhoto,
    isVideoCard,
    avatar,
    city_name,
    country_icon,
    handleLetters,
    screenVideoImg,
}: IProps) => {
    return (
        <>
            {!isVideoCard ? (
                <li className={classes.card}>
                    {online && <Stick text="I'm online now" className={classes.onLine} />}
                    <div className={classes.imgWrap}>
                        <button
                            className={classes.search_item_photo_all_btn}
                            data-testid="item-all-photo-btn"
                            onClick={() => {
                                handlCardPhoto(chat_uid, external_id, wink);
                            }}
                        >
                            <Image name="c2-photo-ico-avo" size={12} />
                            <p className={classes.photoBtn}> Photos</p>
                        </button>
                        <img
                            src={img}
                            alt=""
                            className={classes.img}
                            onClick={() => handlCardPhoto(chat_uid, external_id, wink)}
                            loading="lazy"
                        />
                        <div className={classes.imgRamp}></div>
                        <div className={classes.wrapBtn}>
                            <p className={classes.name}>{`${name}, ${age}`}</p>
                            <div className={classes.chatBtnWrap}>
                                <Button
                                    className={classes.chatBtn}
                                    onClick={() => handleChat(chat_uid, external_id)}
                                    text="Chat Now"
                                />
                                <Button
                                    isWhite
                                    className={classes.chatBtn}
                                    onClick={() => {
                                        handleLike(external_id);
                                    }}
                                    text={isLiked ? 'Liked' : 'Like'}
                                    disabled={isLiked}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.actionBtn}>
                        <Button
                            onClick={() => {
                                handleProfile(external_id);
                            }}
                            iconSvg="userMenu"
                            text="Profile"
                            isWhite
                            borderOff
                            bgImg
                            className={classes.btnProfile}
                            classNameIcon="btnIco"
                        />
                    </div>
                </li>
            ) : (
                <li className={classes.wrapVideoCard}>
                    <div className={classes.wrapVideoImage}>
                        <button
                            className={classes.btnPlayVideo}
                            onClick={() => handlePlayVideo(external_id)}
                        >
                            <img
                                src={screenVideoImg && screenVideoImg(external_id)}
                                alt=""
                                className={classes.previewVideoImg}
                                loading="lazy"
                            />
                            <Image name="play-btn" className={classes.playIco} />
                        </button>
                    </div>
                    <div className={classes.panelVideoCard}>
                        <InfoGirl
                            imgLink={avatar}
                            isOnline={online}
                            name={name}
                            age={age}
                            city={city_name}
                            country_icon={`/assets/flags/${country_icon?.toLocaleLowerCase()}.jpg`}
                            isSmallAvatar={true}
                        />
                        <Box flex align="center">
                            <Button
                                text="Chat Now"
                                padding="0 8px"
                                onClick={() => handleChat(chat_uid, external_id)}
                            />
                            <ButtonIconNav
                                text="Letter"
                                icon="c3mp-letter"
                                className={classes.panelBtn}
                                onClick={() => {
                                    handleLetters(external_id);
                                }}
                            />
                            <ButtonIconNav
                                text={isLiked ? 'Liked' : 'Like'}
                                icon={isLiked ? 'liked-violet' : 'like-violet'}
                                className={classes.panelBtn}
                                onClick={() => {
                                    handleLike(external_id);
                                }}
                            />
                        </Box>
                    </div>
                </li>
            )}
        </>
    );
};

export default CardProfile;
