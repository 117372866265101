import { useState } from 'react';

export const useInitReristerOneStepModal = () => {
    const [isOpenOneStepModal, setIsOpenOneStepModal] = useState(false);

    return {
        isOpenOneStepModal,
        setIsOpenOneStepModal,
    };
};
