import {
    osVersion,
    osName,
    isMobile,
    isTablet,
    isIOS,
    browserName,
    fullBrowserVersion,
    mobileModel,
    mobileVendor,
    deviceType,
    isAndroid,
    getUA,
} from 'react-device-detect';
import { useState, useEffect } from 'react';

export const useDetectedDevice = () => {
    const [isHorizontal, setIsHorizontal] = useState(
        window.matchMedia('(orientation: landscape)').matches,
    );

    const updateOrientation = () => {
        setIsHorizontal(window.matchMedia('(orientation: landscape)').matches);
    };

    useEffect(() => {
        updateOrientation();

        window.addEventListener('resize', updateOrientation);

        return () => {
            window.removeEventListener('resize', updateOrientation);
        };
    }, []);
    const isMobileIOS = !!(isMobile && isIOS);

    const smallInfo = () => {
        return {
            device: isMobile ? 'mob' : 'desk',
            os: `${osName} ${osVersion}`,
        };
    };

    const fullInfo = () => {
        return {
            os: `${osName} ${osVersion}`,
            browser: `${browserName} ${fullBrowserVersion}`,
            mobile: mobileModel,
            device: deviceType,
        };
    };

    const isNotAndroid = !osName?.toLowerCase()?.includes('android');

    return {
        smallInfo,
        fullInfo,
        isMobileIOS,
        isNotAndroid,
        osVersion,
        isAndroid,
        mobileModel,
        mobileVendor,
        getUA,
        isMobile,
        isTablet,
        isHorizontal,
    };
};
