import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { MIRROR } from 'data/mirrorConfig';

import tut_step_4_girl_primary from 'img/tutorial/tut_step_4_primary_girl.png';
import tut_step_4_man_primary from 'img/tutorial/tut_step_4_primary_man.png';
import tut_step_4_girl_secondary_1 from 'img/tutorial/tut_step_4_girl_secondary_1.png';
import tut_step_4_girl_secondary_2 from 'img/tutorial/tut_step_4_girl_secondary_2.png';
import tut_step_4_girl_secondary_3 from 'img/tutorial/tut_step_4_girl_secondary_3.png';
import tut_step_4_man_secondary_1 from 'img/tutorial/tut_step_4_man_secondary_1.png';
import tut_step_4_man_secondary_2 from 'img/tutorial/tut_step_4_man_secondary_2.png';
import tut_step_4_man_secondary_3 from 'img/tutorial/tut_step_4_man_secondary_3.png';
import tut_step_4_girl_small_1 from 'img/tutorial/tut_step_4_girl_small_1.png';
import tut_step_4_girl_small_2 from 'img/tutorial/tut_step_4_girl_small_2.png';
import tut_step_4_man_small_1 from 'img/tutorial/tut_step_4_man_small_1.png';
import tut_step_4_man_small_2 from 'img/tutorial/tut_step_4_man_small_2.png';

export default function Step4() {
    const userState = useSelector((state) => state?.auth?.user?.user_info);

    const switcherGirlName = () => {
        switch (MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID) {
            case 1:
                return {
                    name: 'Kristina, 26',
                    birthday: 'May 17, 1993',
                    country: 'Kyiv, Ukraine',
                };
            case 2:
                return {
                    name: 'Kristina, 26',
                    birthday: 'October 15, 1993',
                    country: 'Kyiv, Ukraine',
                };
            case 5:
                return {
                    name: 'Elsa, 25',
                    birthday: 'March 25, 1998',
                    country: 'Los Angeles, United States',
                };
            case 6:
                return {
                    name: 'Kateryna, 29',
                    birthday: 'July 14, 1995',
                    country: 'Kherson, Ukraine',
                };
            case 7:
                return {
                    name: 'Iryna, 24',
                    birthday: 'October 16, 1998',
                    country: 'Odessa, Ukraine',
                };
            case 8:
                return {
                    name: 'Maria, 27',
                    birthday: 'May 15, 1997',
                    country: 'Kyiv, Ukraine',
                };
            case 9:
                return {
                    name: 'Olga, 39',
                    birthday: 'October 5, 1983',
                    country: 'Rivne, Ukraine',
                };
            case 10:
                return {
                    name: 'Vlada, 36',
                    birthday: 'June 4, 1987',
                    country: 'Odessa, Ukraine',
                };
            case 11:
                return {
                    name: 'Julia, 22',
                    birthday: 'March 30, 2001',
                    country: 'Córdoba, Argentina',
                };
            case 12:
                return {
                    name: 'Lea, 26',
                    birthday: 'September 20, 1996',
                    country: 'Quezon City, Philippines',
                };
            case 13:
                return {
                    name: 'Carla, 28',
                    birthday: 'September 7, 1994',
                    country: 'São Paulo, Brazil',
                };
            case 14:
                return {
                    name: 'Yunita, 25',
                    birthday: 'July 28, 1998',
                    country: 'Manajao, Philippines',
                };
            case 15:
                return {
                    name: 'Diana, 23',
                    birthday: 'March 31, 2000',
                    country: 'Kharkiv, Ukraine',
                };
            case 16:
                return {
                    name: 'Iryna, 24',
                    birthday: 'October 22, 1998',
                    country: 'Kyiv, Ukraine',
                };
            case 17:
                return {
                    name: 'Natali, 25',
                    birthday: 'July 2, 1998',
                    country: 'Odessa, Ukraine',
                };
            case 18:
                return {
                    name: 'Amina, 27',
                    birthday: 'October 18, 1995',
                    country: 'Odessa, Ukraine',
                };
            case 19:
                return {
                    name: 'Vita, 28',
                    birthday: 'June 11, 1995',
                    country: 'Kyiv, Ukraine',
                };
            case 20:
                return {
                    name: 'Svetlana, 38',
                    birthday: 'June 24, 1985',
                    country: 'Kyiv, Ukraine',
                };
            case 21:
                return {
                    name: 'Kira, 29',
                    birthday: 'November 4, 1993',
                    country: 'Odessa, Ukraine',
                };
            case 22:
                return {
                    name: 'Rose, 28',
                    birthday: 'January 4, 1995',
                    country: 'Odessa, Ukraine',
                };
            default:
                return {
                    name: 'Kristina, 26',
                    birthday: 'May 17, 1993',
                    country: 'Kyiv, Ukraine',
                };
        }
    };

    const switcherMenName = () => {
        switch (MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID) {
            case 5:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 7:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 9:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 11:
                return {
                    name: 'Tom, 28',
                    birthday: 'January 31, 1995',
                    country: 'Not Specified, Bahamas',
                };
            case 12:
                return {
                    name: 'Wang, 62',
                    birthday: 'May 9, 1961',
                    country: 'Not Specified, China',
                };
            case 13:
                return {
                    name: 'Tom, 28',
                    birthday: 'January 31, 1995',
                    country: 'Not Specified, Bahamas',
                };
            case 14:
                return {
                    name: 'Wang, 62',
                    birthday: 'May 9, 1961',
                    country: 'Not Specified, China',
                };
            case 16:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 18:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 20:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 22:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            default:
                return {
                    name: 'Maickel, 32',
                    birthday: 'September 10, 1990',
                    country: 'Lyon, France',
                };
        }
    };

    const EmailIcon = () => {
        return (
            <svg
                className={styles.step_select_button_icon}
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
            >
                <g clipPath="url(#clip0_215_535)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.98539 2.32248C1.98539 2.23204 2.0587 2.15873 2.14914 2.15873H4.76914H7.38914C7.39312 2.15873 7.43822 2.16567 7.49209 2.21953C7.54595 2.2734 7.55289 2.3185 7.55289 2.32248V2.52327L4.76914 4.05433L1.98539 2.52327V2.32248ZM1.49414 2.81373V2.32248C1.49414 2.15873 1.49414 1.66748 1.98539 1.66748H2.14914H4.76914H7.38914C7.75089 1.66748 8.04414 1.96073 8.04414 2.32248V2.81373V6.25248C8.04414 6.61423 7.75089 6.90748 7.38914 6.90748H2.14914C1.78739 6.90748 1.49414 6.61423 1.49414 6.25248V2.81373ZM7.55289 3.08392V6.25248C7.55289 6.34292 7.47958 6.41623 7.38914 6.41623H2.14914C2.0587 6.41623 1.98539 6.34292 1.98539 6.25248V3.08392L4.76914 4.61498L7.55289 3.08392Z"
                        fill="url(#paint0_linear_215_535)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_215_535"
                        x1="1.49414"
                        y1="1.66748"
                        x2="8.05848"
                        y2="1.68551"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00317B" />
                        <stop offset="1" stopColor="#720066" />
                    </linearGradient>
                    <clipPath id="clip0_215_535">
                        <rect
                            width="7.86"
                            height="7.85998"
                            fill="white"
                            transform="translate(0.838867 0.357422)"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    };

    const ChatIcon = () => {
        return (
            <svg
                className={styles.step_select_button_icon}
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
            >
                <g clipPath="url(#clip0_215_539)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.2891 2.64986C1.2891 2.06203 1.76564 1.58549 2.35348 1.58549H6.28348C6.87132 1.58549 7.34785 2.06203 7.34785 2.64986V4.61486C7.34785 5.2027 6.87132 5.67924 6.28348 5.67924H6.03785V5.92486V6.64187L5.14716 5.75118L5.07522 5.67924H4.97348H2.35348C1.76564 5.67924 1.2891 5.2027 1.2891 4.61486V2.64986ZM2.35348 1.09424C1.49433 1.09424 0.797852 1.79072 0.797852 2.64986V4.61486C0.797852 5.47401 1.49433 6.17049 2.35348 6.17049H4.87174L5.94207 7.24082C6.1587 7.45745 6.5291 7.30403 6.5291 6.99767V6.15121C7.27154 6.03344 7.8391 5.39043 7.8391 4.61486V2.64986C7.8391 1.79072 7.14262 1.09424 6.28348 1.09424H2.35348ZM2.10785 2.97736C2.10785 2.84171 2.21782 2.73174 2.35348 2.73174L6.28348 2.73174C6.41913 2.73174 6.5291 2.84171 6.5291 2.97736C6.5291 3.11302 6.41913 3.22299 6.28348 3.22299L2.35348 3.22299C2.21782 3.22299 2.10785 3.11302 2.10785 2.97736ZM2.10785 4.28736C2.10785 4.15171 2.21782 4.04174 2.35348 4.04174H6.28348C6.41913 4.04174 6.5291 4.15171 6.5291 4.28736C6.5291 4.42302 6.41913 4.53299 6.28348 4.53299H2.35348C2.21782 4.53299 2.10785 4.42302 2.10785 4.28736Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_215_539">
                        <rect
                            width="7.86"
                            height="7.86"
                            fill="white"
                            transform="translate(0.388672 0.357422)"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    };

    const StarIcon = () => {
        return (
            <svg
                className={styles.step_select_button_icon}
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.89509 1.26965C4.1064 0.847619 4.70871 0.847617 4.92003 1.26965L5.62997 2.68749C5.64197 2.71146 5.66496 2.72804 5.6915 2.73187L7.27183 2.95958C7.74429 3.02765 7.93136 3.60965 7.58704 3.94025L6.45004 5.03197C6.43039 5.05083 6.42141 5.07823 6.42608 5.10506L6.69516 6.65166C6.77643 7.11878 6.28767 7.47693 5.86674 7.25871L4.44524 6.52177C4.42161 6.50952 4.3935 6.50952 4.36987 6.52177L2.94838 7.25871C2.52744 7.47693 2.03868 7.11878 2.11995 6.65166L2.38903 5.10506C2.3937 5.07823 2.38472 5.05083 2.36507 5.03197L1.22808 3.94025C0.88376 3.60965 1.07082 3.02765 1.54328 2.95958L3.12361 2.73187C3.15016 2.72804 3.17314 2.71146 3.18515 2.68749L3.89509 1.26965ZM4.48077 1.48959C4.45058 1.4293 4.36454 1.4293 4.33435 1.48959L3.62441 2.90743C3.54037 3.07527 3.37946 3.19132 3.19368 3.21809L1.61334 3.4458C1.54585 3.45553 1.51913 3.53867 1.56832 3.5859L2.70531 4.67762C2.84283 4.80966 2.90569 5.00144 2.87301 5.18926L2.60393 6.73586C2.59232 6.80259 2.66215 6.85376 2.72228 6.82258L4.14378 6.08564C4.30918 5.99989 4.50594 5.99989 4.67134 6.08564L6.09284 6.82258C6.15297 6.85376 6.22279 6.80259 6.21118 6.73586L5.9421 5.18926C5.90943 5.00144 5.97228 4.80966 6.1098 4.67762L7.2468 3.5859C7.29599 3.53867 7.26926 3.45553 7.20177 3.4458L5.62144 3.21809C5.43565 3.19132 5.27475 3.07527 5.19071 2.90743L4.48077 1.48959Z"
                    fill="url(#paint0_linear_215_543)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_215_543"
                        x1="1.05127"
                        y1="0.953125"
                        x2="7.77856"
                        y2="0.968699"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00317B" />
                        <stop offset="1" stopColor="#720066" />
                    </linearGradient>
                </defs>
            </svg>
        );
    };

    const AlarmIcon = () => {
        return (
            <svg
                className={styles.step_select_button_icon}
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
            >
                <g clipPath="url(#clip0_215_547)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.60627 1.63608C3.80913 1.28472 4.31628 1.28472 4.51914 1.63608L7.19464 6.27018C7.3975 6.62155 7.14392 7.06075 6.7382 7.06075H1.38721C0.981486 7.06075 0.727912 6.62155 0.930772 6.27018L3.60627 1.63608ZM4.05424 1.88538C4.05323 1.88594 4.05127 1.88712 4.04872 1.89153L1.37322 6.52563C1.37068 6.53004 1.37064 6.53232 1.37066 6.53348C1.37069 6.53542 1.3713 6.53844 1.37322 6.54178C1.37515 6.54512 1.37747 6.54715 1.37913 6.54815C1.38012 6.54874 1.38211 6.54985 1.38721 6.54985H6.7382C6.7433 6.54985 6.74529 6.54874 6.74629 6.54815C6.74795 6.54715 6.75026 6.54512 6.75219 6.54178C6.75412 6.53844 6.75472 6.53542 6.75475 6.53348C6.75478 6.53232 6.75473 6.53004 6.75219 6.52563L4.07669 1.89153C4.07414 1.88712 4.07219 1.88594 4.07117 1.88538C4.06948 1.88445 4.06656 1.88346 4.06271 1.88346C4.05885 1.88346 4.05593 1.88445 4.05424 1.88538ZM4.06271 3.22351C4.19836 3.22351 4.30833 3.33348 4.30833 3.46914V5.10664C4.30833 5.24229 4.19836 5.35226 4.06271 5.35226C3.92705 5.35226 3.81708 5.24229 3.81708 5.10664V3.46914C3.81708 3.33348 3.92705 3.22351 4.06271 3.22351ZM4.06271 6.19272C4.24358 6.19272 4.39021 6.04609 4.39021 5.86522C4.39021 5.68435 4.24358 5.53772 4.06271 5.53772C3.88183 5.53772 3.73521 5.68435 3.73521 5.86522C3.73521 6.04609 3.88183 6.19272 4.06271 6.19272Z"
                        fill="url(#paint0_linear_215_547)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_215_547"
                        x1="0.859375"
                        y1="1.37256"
                        x2="7.28008"
                        y2="1.38845"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00317B" />
                        <stop offset="1" stopColor="#720066" />
                    </linearGradient>
                    <clipPath id="clip0_215_547">
                        <rect
                            width="7.86"
                            height="7.86"
                            fill="white"
                            transform="translate(0.132812 0.35791)"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    };

    const CountryClass = () => {
        switch (MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID) {
            case 5:
                return {
                    country: !+userState?.gender ? 'united_states' : 'australia',
                };
            case 7:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            case 9:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            case 11:
                return { country: !+userState?.gender ? 'argentina' : 'bahamas' };
            case 12:
                return { country: !+userState?.gender ? 'philippines' : 'china' };
            case 13:
                return { country: !+userState?.gender ? 'brazil' : 'bahamas' };
            case 14:
                return { country: !+userState?.gender ? 'philippines' : 'china' };
            case 16:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            case 18:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            case 20:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            case 22:
                return { country: !+userState?.gender ? 'ukraine' : 'australia' };
            default:
                return { country: !+userState?.gender ? 'ukraine' : 'france' };
        }
    };

    return (
        <>
            <div className={styles.tutorial_step_illustration}>
                <div className={styles.step_select}>
                    <div className={styles.step_select_buttons}>
                        <div className={styles.step_select_button}>
                            <EmailIcon />
                            <p className={styles.step_select_button_text}>Write a letter</p>
                        </div>
                        <div className={styles.step_select_button_accent}>
                            <ChatIcon />
                            <p className={styles.step_select_button_accent_text}>Start chatting</p>
                        </div>
                        <div className={styles.step_select_button}>
                            <StarIcon />
                            <p className={styles.step_select_button_text}>Favorites</p>
                        </div>
                        <div className={styles.step_select_button}>
                            <AlarmIcon />
                            <p className={styles.step_select_button_text}>To complain</p>
                        </div>
                    </div>
                    <div className={styles.step_select_candidate}>
                        {!+userState?.gender ? (
                            <img
                                src={tut_step_4_girl_primary}
                                alt=""
                                width="147"
                                height="207"
                                className={styles.step_select_main_candidate_photo}
                            />
                        ) : (
                            <img
                                src={tut_step_4_man_primary}
                                alt=""
                                width="147"
                                height="207"
                                className={styles.step_select_main_candidate_photo}
                            />
                        )}
                        <div className={styles.step_select_candidate_info}>
                            <div className={styles.step_select_candidate_header}>
                                <div className={styles.step_select_candidate_details}>
                                    <p className={styles.step_select_candidate_name}>
                                        {!+userState?.gender
                                            ? switcherGirlName()?.name
                                            : switcherMenName()?.name}
                                    </p>
                                    <p className={styles.step_select_candidate_birth}>
                                        {!+userState?.gender
                                            ? switcherGirlName()?.birthday
                                            : switcherMenName()?.birthday}
                                    </p>
                                    <div className={styles.step_select_candidate_details_bottom}>
                                        <p className={styles.step_select_candidate_id}>
                                            ID 1945945456
                                        </p>
                                        <p
                                            className={`${styles.step_select_candidate_country} ${
                                                styles[`flag_${CountryClass()?.country}`]
                                            }`}
                                        >
                                            <span>
                                                {!+userState?.gender
                                                    ? switcherGirlName()?.country
                                                    : switcherMenName()?.country}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.step_select_candidate_buttons}>
                                    <div
                                        className={`${styles.step_select_candidate_button} ${styles.step_select_candidate_button_hello}`}
                                    >
                                        <p className={styles.step_select_candidate_button_text}>
                                            Say “Hello”
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.step_select_candidate_button} ${styles.step_select_candidate_button_like}`}
                                    >
                                        <p className={styles.step_select_candidate_button_text}>
                                            Like
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.step_select_gallery}>
                                <div className={styles.step_select_gallery_big}>
                                    {!+userState?.gender ? (
                                        <>
                                            <img
                                                src={tut_step_4_girl_secondary_1}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_secondary_2}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_secondary_3}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={tut_step_4_man_secondary_1}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_man_secondary_2}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_man_secondary_3}
                                                alt=""
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={styles.step_select_gallery_small}>
                                    {!+userState?.gender ? (
                                        <>
                                            <img
                                                src={tut_step_4_girl_small_1}
                                                alt=""
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_small_2}
                                                alt=""
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={tut_step_4_man_small_1}
                                                alt=""
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                            <img
                                                src={tut_step_4_man_small_2}
                                                alt=""
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.step_select_online_status}>
                            <p className={styles.step_select_online_status_text}>I’m online now</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
