import React from 'react';
import { useRef, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import cn from 'classnames';
import classes from './SliderSimilarGigls.module.scss';

interface IProps {
    className?: string;
    handleSimilar: (id: number) => void;
    similarList: any;
    title?: string;
    placeholder?: string;
    initialSlide?: number;
}

const SliderSimilarGigls = ({
    similarList,
    className,
    handleSimilar,
    title,
    placeholder,
    initialSlide,
}: IProps) => {
    function calculateMaxCards() {
        const screenWidth = window.innerWidth;
        const cardWidth = 55;
        const containerPadding = 32;
        const visibleWidth = screenWidth - containerPadding;
        const maxCards = Math.floor(visibleWidth / cardWidth);
        return maxCards;
    }

    const slideRef = useRef<SwiperRef | null>(null);

    useEffect(() => {
        if (slideRef.current) {
            slideRef.current?.swiper?.slideTo(initialSlide ?? 0);
        }
    }, [initialSlide]);

    return (
        <div className={cn(className)}>
            <div className={classes.wrapInfoSlider}>
                <p className={classes.nameSlider}>{title}</p>
                <p className={classes.descriptionSlider}>{placeholder}</p>
            </div>
            <Swiper
                ref={slideRef}
                slidesPerView={calculateMaxCards()}
                spaceBetween={2}
                freeMode={true}
                loop={
                    initialSlide !== undefined
                        ? false
                        : similarList.length < calculateMaxCards()
                          ? false
                          : true
                }
                modules={[FreeMode, Pagination]}
                initialSlide={initialSlide}
                centeredSlides={false}
            >
                {similarList.map((image: any, index: any) => (
                    <SwiperSlide className={classes.slide} key={index}>
                        <p className={classes.online}>Online</p>
                        <button
                            onClick={() => handleSimilar(image.externalID || image.external_id)}
                        >
                            <img alt="" src={image.avatar} className={classes.img} loading="lazy" />
                        </button>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default SliderSimilarGigls;
