import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Button,
    PageWrap,
    Box,
    CardOnline,
    ModalContent,
    ChatFilterPanel,
    TextNameonChat,
    Image,
    Textonchat,
} from 'components/atomsKit/Elements';

import useOnlinePage from './useOnlinePage';

import { images } from 'utils/images';
import { sortCardsToFreeFirst } from 'utils/common';

import classes from './OnlinePage.module.scss';

const OnlinePage = () => {
    const {
        cards,
        handleProfile,
        handleLike,
        handleScroll,
        handleChat,
        handlePlayVideo,
        handlCardPhoto,
        isOpenModalPhoto,
        handleCloseModalPhoto,
        dataForModalPhoto,
        sayHalloList,
        handleWink,
        setIsOpenModalWink,
        isOpenModalWink,
        handleSayHello,
        handleLetters,
        valuesFilter,
        onChangeOption,
        onChangeSearch,
    } = useOnlinePage();

    return (
        <>
            {isOpenModalPhoto && (
                <ModalContent
                    modalIsOpen={isOpenModalPhoto}
                    closeModal={handleCloseModalPhoto}
                    counterPrivatPhoto={
                        dataForModalPhoto?.item.filter(
                            (image) => +image?.free === 0 && !image?.payed,
                        )?.length
                    }
                    items={images(sortCardsToFreeFirst(dataForModalPhoto?.item))}
                    id={dataForModalPhoto?.external_id}
                    chat_uid={dataForModalPhoto?.chat_uid}
                    wink={dataForModalPhoto.wink}
                    handleChat={handleChat}
                    handleSayHello={handleSayHello}
                    handleWink={handleWink}
                    sayHalloList={sayHalloList}
                    isOpenModalWink={isOpenModalWink}
                    setIsOpenModalWink={setIsOpenModalWink}
                    handleProfile={handleProfile}
                />
            )}
            <PageWrap height100 className={classes.wrap}>
                <div className={classes.baners}>
                    <ChatFilterPanel
                        valuesFilter={valuesFilter}
                        onChangeOption={onChangeOption}
                        search={valuesFilter.id}
                        setSearch={onChangeSearch}
                    />
                </div>
                <div className={classes.pageContent}>
                    {cards.length > 0 ? (
                        <>
                            <InfiniteScroll
                                dataLength={cards.length}
                                next={handleScroll}
                                hasMore={true}
                                scrollableTarget="forOnlinePage"
                                scrollThreshold={0.7}
                                loader={<></>}
                            >
                                <></>
                            </InfiniteScroll>
                            <ul className={classes.list} id={'forOnlinePage'}>
                                {cards.map((item, index) => (
                                    <CardOnline
                                        key={index}
                                        info={item}
                                        handlCardPhoto={handlCardPhoto}
                                        cardsList={cards}
                                        handleProfile={handleProfile}
                                        handleLike={handleLike}
                                        handleScroll={handleScroll}
                                        handleChat={handleChat}
                                        handlePlayVideo={handlePlayVideo}
                                        handleLetters={handleLetters}
                                        sayHalloList={sayHalloList}
                                        handleWink={handleWink}
                                        handleSayHello={handleSayHello}
                                        isOpenModalWink={isOpenModalWink}
                                        setIsOpenModalWink={setIsOpenModalWink}
                                    />
                                ))}
                            </ul>
                        </>
                    ) : (
                        <Box
                            flex
                            align="center"
                            justify="center"
                            directionColumn
                            height100
                            padding={'0 16px'}
                        >
                            <Image name="sweetheart_image" className={classes.emptyImg} />
                            <TextNameonChat
                                margin="20px 0 0 0"
                                size={18}
                                text={'No results found'}
                                textAlign={'center'}
                            />
                            <Textonchat
                                textAlign={'center'}
                                margin="20px 0 20px 0"
                                text={
                                    "But there are a lot of other fascinating members. Please, check the data you've entered or choose other parameters"
                                }
                            />
                            <Button
                                fitContent
                                padding="0 16px 0 16px"
                                onClick={() => {}}
                                isWhite
                                text="Clear all filters"
                            />
                        </Box>
                    )}
                </div>
            </PageWrap>
        </>
    );
};

export default OnlinePage;
