import React from 'react';
import { useState } from 'react';
import { Box, Image } from 'components/atomsKit/Elements';

import { ModalChangeMail } from 'components/atomsKit/Elements';

import classes from './BanerHeaderConfirm.module.scss';

interface IProps {
    className?: string;
    handleCloseBaner?: any;
}

const BanerHeaderConfirm = ({ handleCloseBaner }: IProps) => {
    const [isOpenModalChangeMail, setIsOpenModalChangeMail] = useState(false);
    return (
        <>
            {isOpenModalChangeMail && (
                <ModalChangeMail
                    modalIsOpen={isOpenModalChangeMail}
                    closeModal={() => {
                        setIsOpenModalChangeMail(false);
                    }}
                />
            )}
            <Box
                className={classes.wrap}
                onClick={() => {
                    setIsOpenModalChangeMail(true);
                }}
            >
                <Box>
                    <p className={classes.textTop}>Be aware of all events!</p>
                    <p className={classes.textBottom}>Confirm your email </p>
                </Box>
                <Box flex align="center" justify="center">
                    <button
                        className={classes.btnConfirm}
                        onClick={() => {
                            setIsOpenModalChangeMail(true);
                        }}
                    >
                        <span className={classes.textBtn}>Confirm</span>
                    </button>
                    <button onClick={handleCloseBaner}>
                        <Image name="cros" className={classes.btn} />
                    </button>
                </Box>
            </Box>
        </>
    );
};

export default BanerHeaderConfirm;
