import React from 'react';
import { Box, Button, TextNameonChat } from 'components/atomsKit/Elements';
import usePolicyPage from './usePolicyPage';
import classes from './PolicyPage.module.scss';

const PolicyPage = () => {
    const { policyLinkConfig, changePolicy, policyInfo, setPolicyInfo } = usePolicyPage() as any;

    return (
        <Box className={classes.wrap}>
            <Box flex directionColumn align="center" justify="center" padding="24px">
                {!policyInfo &&
                    policyLinkConfig.map((item: any, index: number) => (
                        <button
                            key={index}
                            className={classes.btn}
                            onClick={() => changePolicy(item.seo_url)}
                        >
                            <p className={classes.number}>{index + 1}</p>
                            {item.text}
                        </button>
                    ))}
                {policyInfo && (
                    <>
                        <Box flex position={'relative'} width100 align="center" justify="center">
                            <Button
                                onClick={() => {
                                    setPolicyInfo();
                                }}
                                fitContent
                                text="back"
                                borderOff
                                noBg
                                color="#474F5C"
                                className={classes.btnBack}
                            />
                            <TextNameonChat
                                text={policyInfo.header}
                                margin="0 0 10px 0"
                                size={18}
                            />
                        </Box>
                        <Box className={classes.wrapSubMenu}>
                            <Box className={classes.option}>
                                <div
                                    className={classes.answer}
                                    dangerouslySetInnerHTML={{
                                        __html: policyInfo.content,
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default PolicyPage;
