import React from 'react';
import ReactDOM from 'react-dom';
import { ModalWindowModern, Button, Image } from 'components/atomsKit/Elements';

import classes from './SendWinkModal.module.scss';

interface IProps {
    config?: { message_content: string; id: number; site_id: number }[];
    modalIsOpen?: boolean;
    closeModal: () => void;
    name?: string;
    handleWink: any;
    handleChat: () => void;
    recipient_id?: number;
}

const SendWinkModal = ({
    config,
    handleWink,
    recipient_id,
    name,
    modalIsOpen,
    closeModal,
    handleChat,
}: IProps) => {
    const modalRoot = document.getElementById('modal-root');

    const modalContent = (
        <ModalWindowModern
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            hideCloseButton
            zIndex={9999999}
        >
            <div className={classes.container}>
                <div className={classes.body}>
                    <button className={classes.btnClose} onClick={closeModal}>
                        <Image name="c3lh-item-big-close-blue" />
                    </button>
                    <h2 className={classes.title}>
                        Choose first message {name ? `to ${name}` : ''}
                    </h2>
                    <ul className={classes.wrapList}>
                        {config?.map((item) => (
                            <li key={item.id} className={classes.li}>
                                <button
                                    className={classes.btn}
                                    onClick={() => {
                                        handleWink(item, recipient_id);
                                    }}
                                >
                                    {item.message_content}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <Button
                        text="Start chatting"
                        className={classes.btnStartChating}
                        onClick={handleChat}
                    />
                </div>
            </div>
        </ModalWindowModern>
    );

    return modalIsOpen && modalRoot ? ReactDOM.createPortal(modalContent, modalRoot) : null;
};

export default SendWinkModal;
