// initializes axios by data form config
import axios from 'axios';

import { actionCookies } from 'utils/common';

import { MIRROR } from 'data/mirrorConfig';

let apiInstance: any = null;

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.baseURL = `https://${
    MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].URL
}`;

const apiInit = () => {
    if (apiInstance) {
        return apiInstance;
    }
    apiInstance = axios.create({
        // timeout: 30000,
        headers: {
            Accept: 'application/json',
            // "Access-Control-Allow-Origin": MIRROR[process.env.REACT_APP_MIRROR_NAME].URL,
        },
    });
    return apiInstance as any;
};

const initAuthorized = () => {
    apiInstance = axios.create({
        // timeout: 30000,
        headers: {
            Accept: 'application/json',
            // "Access-Control-Allow-Origin": process.env.URL,
            Authorization: `Bearer ${actionCookies('token') || localStorage.getItem('token')}`,
            fingerprintHash: localStorage.getItem('fingerprinthash'),
        },
    });

    apiInstance.interceptors.response.use((response: any) => response, interceptor);
};

const getApiInstance = () => {
    return apiInstance;
};

const interceptor = (data: any) => {
    if (data && data.response && data.response.status === 401) {
        actionCookies('token', 'del');
        localStorage.removeItem('token');
        localStorage.removeItem('appState');
        actionCookies('fingerprinthash', 'del');
        localStorage.removeItem('fingerprinthash');
        window.location.href = '/login';
    }

    return Promise.reject(new Error(`Request failed: ${JSON.stringify(data)}`));
};

export { apiInit, getApiInstance, initAuthorized };
