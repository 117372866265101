import React from 'react';
import { useState } from 'react';

import { Image, TextTimeonchat } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './InputModern.module.scss';

interface IProps {
    className?: string;
    error?: boolean;
    placeholder?: string;
    value?: string;
    type?: string;
    onChange: (e: any) => void;
    onClick?: () => void;
    isTextArea?: any;
    label?: string;
    autoFocus?: boolean;
    infoText?: any;
}

const InputModern = ({
    error,
    placeholder,
    value = '',
    onChange,
    className,
    type,
    onClick,
    isTextArea,
    label,
    autoFocus,
    infoText,
}: IProps) => {
    const [isHidePass, setIsHidePass] = useState(true);

    return (
        <>
            {label && <h2 className={cn(classes.title)}>{label}</h2>}
            {isTextArea ? (
                <div className={classes.textAreaWrap}>
                    <textarea
                        autoFocus={autoFocus}
                        onClick={onClick}
                        className={cn(
                            classes.input,
                            classes.input_textarea,
                            isTextArea === 'small' && classes.input_textarea_small,
                            error && classes.input_error,
                            className,
                        )}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />
                    {infoText && (
                        <TextTimeonchat
                            text={infoText}
                            className={cn(
                                classes.counter,
                                isTextArea === 'small' && classes.counter_small,
                            )}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.inputWrap}>
                    <input
                        autoFocus={autoFocus}
                        onClick={onClick}
                        type={type !== 'password' ? type : isHidePass ? 'password' : undefined}
                        className={cn(classes.input, error && classes.input_error, className)}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                    />
                    {type === 'password' && (
                        <button
                            className={classes.showPassBtn}
                            type="button"
                            onClick={() => setIsHidePass(!isHidePass)}
                        >
                            <Image name={isHidePass ? 'password-show' : 'password-hide'} />
                        </button>
                    )}
                    {infoText && (
                        <TextTimeonchat
                            text={infoText}
                            className={cn(
                                classes.counter,
                                isTextArea === 'small' && classes.counter_small,
                            )}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default InputModern;
