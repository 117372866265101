import React from 'react';
import cn from 'classnames';

import { Image } from 'components/atomsKit/Elements';

import classes from './ButtonIconNav.module.scss';

interface IProps {
    onClick?: () => void;
    icon: string;
    iconSize?: string;
    text?: string;
    color?: string;
    className?: string;
    disabled?: boolean;
}

const ButtonIconNav = ({ onClick, icon, text, color, disabled, className }: IProps) => {
    return (
        <button onClick={onClick} className={cn(classes.btn, className)} disabled={disabled}>
            <Image name={icon} />
            {text && (
                <p className={classes.text} style={{ color: color }}>
                    {text}
                </p>
            )}
        </button>
    );
};

export default ButtonIconNav;
