import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IProps {
    isAllowed?: boolean;
    redirectPath?: string;
}

const PrivateRoute = ({ isAllowed, redirectPath = '/login' }: IProps) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};

export default PrivateRoute;
