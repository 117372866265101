import React from 'react';
import { Box, TextNameonChat } from 'components/atomsKit/Elements';

import classes from './StickersGroup.module.scss';

interface IProps {
    className?: string;
    stickersConfig: any;
    onChangeStiker: (name: string) => void;
}

const StickersGroup = ({ stickersConfig, onChangeStiker }: IProps) => {
    return (
        <ul className={classes.wrapTypeSticker}>
            {stickersConfig.map((stickerGroup: any, index: number) => (
                <li key={index}>
                    <Box flex align={'center'} justify={'between'}>
                        <TextNameonChat text={stickerGroup.title} />
                    </Box>
                    <ul className={classes.wrapStikersGroup}>
                        {stickerGroup.stickers.map((stick: any, index: number) => (
                            <li key={index}>
                                <button
                                    onClick={() => {
                                        onChangeStiker(stick.name);
                                    }}
                                >
                                    <img
                                        src={stick.img}
                                        alt=""
                                        className={classes.sticker}
                                        loading="lazy"
                                    />
                                </button>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    );
};

export default StickersGroup;
