import { useSelector } from 'react-redux';

import { isLoadingAuth } from 'redux/auth/selectors';
import { isLoadingChat } from 'redux/chat/selectors';
import { isLoadingGirlsList } from 'redux/girlsList/selectors';
import { isLoadingInterface } from 'redux/interface/selectors';
import { isLoadingLetters } from 'redux/letters/selectors';

const useIsLoading = () => {
    const loadingAuth = useSelector(isLoadingAuth);
    const loadingChat = useSelector(isLoadingChat);
    const loadingGirlsList = useSelector(isLoadingGirlsList);
    const loadingInterface = useSelector(isLoadingInterface);
    const loadingLetters = useSelector(isLoadingLetters);

    const isGlobalLoading =
        loadingAuth || loadingChat || loadingGirlsList || loadingInterface || loadingLetters
            ? true
            : false;

    return { isGlobalLoading };
};

export default useIsLoading;
