export const PATHS = {
    LOGIN: { path: '/login' },
    HASH: { path: '/hash/:activation_code', replacePath: '/hash/' },
    SEARCH: { path: '/search' },
    CONFIRM: { path: '/confirm/:activation_code', replacePath: '/confirm/' },
    PROFILE_WOMAN: {
        path: '/profile-woman/:external_id',
        replacePath: '/profile-woman/',
    },
    LETTER: { path: '/letter' },
    CHAT: { path: '/chat' },
    CHAT_ROOM: { path: '/chat/:chat_uid', replacePath: '/chat/' },
    LIKE: { path: '/like' },
    MENU: { path: '/menu' },
    MENU_FAQ: { path: '/menu/faq' },
    MENU_TUTORIAL: { path: '/menu/tutorial' },
    MENU_POLICY: { path: '/menu/policy' },
    GIFTS: { path: '/gifts' },
    GIFTS_CART: { path: '/gifts/cart' },
    MENU_SENTGIFTS: { path: '/menu/sentgifts' },
    MENU_BLOCK: { path: '/menu/block' },
    MENU_CONTACT_REQUEST: { path: '/menu/contact-request' },
    MENU_PURCHASE_HISTORY: { path: '/menu/purchase-history' },
    PROFILE_BLOCK: { path: '/profile/:block', replacePath: '/profile/' },
    PAYMENT: { path: '/payment' },
};
