import React from 'react';
import cn from 'classnames';

import classes from './TextNameonChat.module.scss';

interface IProps {
    className?: string;
    isSpan?: boolean;
    isHeader?: boolean;
    text: string | number;
    size?: number;
    margin?: string;
    textAlign?: any;
}

const TextNameonChat = ({ isSpan, isHeader, text, size, margin, textAlign, className }: IProps) => {
    return (
        <>
            {isSpan ? (
                <span
                    style={{
                        fontSize: `${size}px`,
                        margin: margin,
                        textAlign: textAlign,
                    }}
                    className={cn(classes.text, className && className)}
                >
                    {text}
                </span>
            ) : isHeader ? (
                <h2
                    className={cn(classes.text, className && className)}
                    style={{
                        fontSize: `${size}px`,
                        margin: margin,
                        textAlign: textAlign,
                    }}
                >
                    {text}
                </h2>
            ) : (
                <p
                    className={cn(classes.text, className && className)}
                    style={{
                        fontSize: `${size}px`,
                        margin: margin,
                        textAlign: textAlign,
                    }}
                >
                    {text}
                </p>
            )}
        </>
    );
};

export default TextNameonChat;
