import React from 'react';
import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateRoute from 'components/Shared/PrivateRoute';
import TestPage from 'pages/TestPage/TestPage';
import SearchPage from 'pages/SearchPage/SearchPage';
import UserPage from 'pages/UserPage/UserPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import LettersPage from 'pages/LettersPage/LettersPage';
import ChatPage from 'pages/ChatPage/ChatPage';
import LetterProfilePage from 'pages/LetterProfilePage/LetterProfilePage';
import MessagerPage from 'pages/MessagerPage/MessagerPage';
import LikeHerPage from 'pages/LikeHerPage/LikeHerPage';
import MenuPage from 'pages/MenuPage/MenuPage';
import ProfilePage from 'pages/ProfilePage/ProfilePage';

import OnlinePage from 'pages/OnlinePage/OnlinePage';
// import VideoPage from "pages/VideoPage/VideoPage";
import CresitsPage from 'pages/MenuPage/SubPage/CresitsPage/CresitsPage';
import ContactRequestPage from 'pages/MenuPage/SubPage/ContactRequestPage/ContactRequestPage';
import FaqPage from 'pages/MenuPage/SubPage/FaqPage/FaqPage';
import TutorialPage from 'pages/MenuPage/SubPage/TutorialPage/TutorialPage';
import PolicyPage from 'pages/MenuPage/SubPage/PolicyPage/PolicyPage';
import GiftsPage from 'pages/GiftsPage/GiftsPage';
import CartPage from 'pages/CartPage/CartPage';
import SendGiftsPage from 'pages/SendGiftsPage/SendGiftsPage';
import BlockListPage from 'pages/BlockListPage/BlockListPage';
import PaymentPage from 'pages/PaymentPage/PaymentPage';

import {
    HardResetPage,
    HardResetStorePage,
    HardResetReloginPage,
} from './ResetComponents/Reset.js';

import SocketProvider from 'hooks/useSocket';
import { useAuth, useGtagPageView } from 'hooks';
import { useWebWidget } from 'hooks/useWebWidget';
import { useConfirmMail } from 'hooks/useConfirmMail';

import { postRequest } from 'utils/requests';

import { togleGlobalModal } from 'redux/interface/slice';

const RouterMain = () => {
    useGtagPageView();
    const { isLoggedIn } = useAuth();
    useWebWidget();

    const location = useLocation();
    const searchParams = location.search;

    const { isOpenConfirm } = useConfirmMail();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpenConfirm) {
            const url = window.location.href;

            const parsedUrl = new URL(url);

            const pathParts = parsedUrl.pathname.split('/');

            let key = null;
            const confirmIndex = pathParts.indexOf('confirm');
            if (confirmIndex !== -1 && confirmIndex + 1 < pathParts.length) {
                key = pathParts[confirmIndex + 1];
            }

            postRequest('/api/login/confirmEmail', {
                hash: key,
            })
                .then((res: any) => {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: res.message,
                            btnText: 'Got it',
                        }),
                    );
                    if (res?.fingerprint) {
                        localStorage.setItem('fingerprintHash', res?.fingerprint);
                    }
                    if (res?.token) {
                        localStorage.setItem('token', JSON.stringify(res?.token));
                    }
                })
                .catch(() => {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: 'Something went wrong.',
                            btnText: 'Got it',
                        }),
                    );
                });
        }
    }, [isOpenConfirm]);

    return (
        <>
            <SocketProvider />
            <Routes>
                <Route path="/testOnlyDesign" element={<TestPage />} />
                <Route
                    path="/"
                    element={
                        isLoggedIn ? <Navigate to={`/search${searchParams}`} /> : <LoginPage />
                    }
                />
                <Route
                    path="login"
                    element={
                        isLoggedIn ? <Navigate to={`/search${searchParams}`} /> : <LoginPage />
                    }
                />

                <Route
                    path="hash/:activation_code"
                    element={
                        isLoggedIn ? <Navigate to={`/search${searchParams}`} /> : <LoginPage />
                    }
                />

                <Route
                    path="confirm/:activation_code"
                    element={isLoggedIn ? <SearchPage /> : <LoginPage />}
                />

                <Route path="/hardreset" element={<HardResetPage />} />
                <Route path="/hardreset-store" element={<HardResetStorePage />} />
                <Route path="/hardreset-relogin" element={<HardResetReloginPage />} />

                <Route element={<PrivateRoute isAllowed={isLoggedIn} />}>
                    <Route path="search" element={<SearchPage />} />
                    <Route path="profile-woman/:external_id" element={<UserPage />} />
                    <Route
                        path="letter"
                        element={
                            window.location.search.includes('profileId') ? (
                                <LetterProfilePage />
                            ) : (
                                <LettersPage />
                            )
                        }
                    />
                    <Route path="chat" element={<ChatPage />} />
                    <Route path="chat/:chat_uid" element={<MessagerPage />} />
                    <Route path="like" element={<LikeHerPage />} />

                    <Route path="menu" element={<MenuPage />} />

                    <Route path="menu/faq" element={<FaqPage />} />
                    <Route path="menu/tutorial" element={<TutorialPage />} />
                    <Route path="menu/policy" element={<PolicyPage />} />
                    <Route path="gifts" element={<GiftsPage />} />
                    <Route path="gifts/cart" element={<CartPage />} />
                    <Route path="menu/sentgifts" element={<SendGiftsPage />} />
                    <Route path="menu/block" element={<BlockListPage />} />
                    <Route path="menu/contact-request" element={<ContactRequestPage />} />
                    <Route path="menu/online-now" element={<OnlinePage />} />
                    <Route path="menu/purchase-history" element={<CresitsPage />} />

                    <Route path="profile/:block?" element={<ProfilePage />} />
                    <Route path="payment" element={<PaymentPage />} />
                </Route>
                <Route
                    path="*"
                    element={isLoggedIn ? <Navigate to="/search" /> : <Navigate to="/login" />}
                />
            </Routes>
        </>
    );
};

export default RouterMain;
