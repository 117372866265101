import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {
    LetterList,
    PageWrap,
    ChatFilterPanel,
    SliderSimilarGigls,
    EmptyState,
} from 'components/atomsKit/Elements';
import BanersSubPage from 'pages/BanersSubPage/BanersSubPage';
import useLettersPage from './useLettersPage';
import usePrice from 'hooks/usePrice';
import { configFilterLetters } from 'data/config';
import classes from './LettersPage.module.scss';

const LettersPage = () => {
    const {
        letters,
        handleScroll,
        isOnline,
        search,
        setSearch,
        handleDel,
        handleContent,
        setIsOnline,
        similarList,
        handleSimilar,
        filterChat,
        handleRestore,
    } = useLettersPage();

    const price = usePrice();
    const navigate = useNavigate();

    return (
        <>
            <PageWrap height100>
                <>
                    <div className={classes.baners}>
                        <BanersSubPage />
                        {similarList?.length > 0 && (
                            <SliderSimilarGigls
                                title={'You may like'}
                                placeholder={'Start conversation with them'}
                                similarList={similarList}
                                handleSimilar={handleSimilar}
                                className={classes.similar}
                            />
                        )}
                        <ChatFilterPanel
                            handleIsOnline={() => {
                                setIsOnline(!isOnline);
                            }}
                            isOnline={isOnline}
                            search={search}
                            setSearch={setSearch}
                            configFilterChat={configFilterLetters}
                        />
                    </div>
                    <div className={classes.pageContent}>
                        {letters?.length > 0 ? (
                            <>
                                <InfiniteScroll
                                    dataLength={letters?.length}
                                    next={handleScroll}
                                    hasMore={true}
                                    scrollableTarget="forScroll"
                                    scrollThreshold={0.7}
                                    loader={<></>}
                                >
                                    <></>
                                </InfiniteScroll>

                                <LetterList
                                    price={price}
                                    letterList={letters}
                                    handleDel={handleDel}
                                    handleRestore={handleRestore}
                                    scrollableTarget="forScroll"
                                    handleContent={handleContent}
                                    filterChat={filterChat}
                                />
                            </>
                        ) : (
                            <EmptyState
                                config={{
                                    title: 'No letters found',
                                    description:
                                        'To find someone to chat with, you may use the following options: ',
                                    buttons: [
                                        {
                                            text: 'Like,',
                                            onClick: () => {
                                                navigate('/like');
                                            },
                                        },
                                        {
                                            text: 'Search',
                                            onClick: () => {
                                                navigate('/search');
                                            },
                                        },
                                        // {
                                        //   text: "Video",
                                        //   onClick: () => {
                                        //     navigate("/menu/video");
                                        //   },
                                        // },
                                    ],
                                    onClickClear: () => {
                                        setIsOnline(false);
                                        setSearch('');
                                    },
                                    textClearBtn: 'Clear all filters',
                                }}
                            />
                        )}
                    </div>
                </>
            </PageWrap>
        </>
    );
};

export default LettersPage;
