import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useDetectedDevice, useGoogleDataLayer, useFBConversion } from 'hooks';
import { selectUser, selectTail } from 'redux/auth/selectors';

import { postRequest } from 'utils/requests';

export const useInitPushNotification = () => {
    const userState = useSelector(selectUser);
    const tail = useSelector(selectTail)?.response?.tail;
    const location = useLocation();
    const { fullInfo } = useDetectedDevice();
    const { pushEventConfirmedEmail } = useGoogleDataLayer();
    const { sentFBConfirmEmailConversion } = useFBConversion();

    const checkPush = () => {
        postRequest(`/api/v3/click-history/check-push`, {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const checkAdrol = () => {
        postRequest('/api/v3/click-history/check-adrol', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const checkEmailAdrol = () => {
        postRequest('/api/v3/click-history/check-email-adrol', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const checkSendgrig = () => {
        postRequest('/api/v3/click-history/check-sendgrig', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const checkCenterEmail = () => {
        postRequest('/api/v3/click-history/check-center-email', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        }).then((res) => {
            if (res?.status && res?.confirmedEmail) {
                postRequest('/api/marketing/checkMarketingConversion', {
                    eventName: 'confirmed_email',
                }).then((resp) => {
                    if (resp?.status && resp?.response?.check) {
                        // Push DataLayer for FB
                        pushEventConfirmedEmail();
                        if (tail?.includes('utm_age=45')) {
                            sentFBConfirmEmailConversion();
                        }
                    }
                });
            }
        });
    };

    const postCheckGoogle = () => {
        postRequest('/api/v3/click-history/check-google', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const postCheckTbl = () => {
        postRequest('v3/click-history/check-tbl', {
            external_id: userState?.user_info?.external_id,
            tail: location?.search,
            device: fullInfo(),
        });
    };

    const lastActivity = () => {
        postRequest('/api/v3/click-history/last-activity', {
            external_id: userState?.user_info?.external_id,
            device: fullInfo(),
        });
    };

    return {
        checkPush,
        checkAdrol,
        checkEmailAdrol,
        checkSendgrig,
        lastActivity,
        checkCenterEmail,
        postCheckGoogle,
        postCheckTbl,
    };
};
