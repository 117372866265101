import React from 'react';
import { Box, Button, AccordionMenu, TextNameonChat } from 'components/atomsKit/Elements';

import useTutorialPage from './useTutorialPage';
import classes from './TutorialPage.module.scss';

const TutorialPage = () => {
    const { tutorialList, setChoseTutorial, choseTutorial, idSelectTopic, setIdSelectTopic } =
        useTutorialPage() as any;

    return (
        <Box className={classes.wrap}>
            <Box flex directionColumn align="center" justify="center" padding="24px">
                {!choseTutorial &&
                    tutorialList?.map((item: any, index: number) => (
                        <button
                            key={index}
                            className={classes.btn}
                            onClick={() => setChoseTutorial(item)}
                        >
                            <p className={classes.number}>{index + 1}</p>
                            {item.title}
                        </button>
                    ))}
                {choseTutorial && (
                    <>
                        <Box
                            flex
                            position={'relative'}
                            width100
                            align="center"
                            justify="center"
                            onClick={() => setChoseTutorial()}
                        >
                            <Button
                                fitContent
                                text="back"
                                borderOff
                                noBg
                                color="#474F5C"
                                className={classes.btnBack}
                            />
                            <TextNameonChat
                                text={choseTutorial.title}
                                margin="0 0 10px 0"
                                size={18}
                            />
                        </Box>
                        <Box className={classes.wrapSubMenu}>
                            <div
                                className={classes.answer}
                                dangerouslySetInnerHTML={{
                                    __html: choseTutorial.text,
                                }}
                            />
                            {choseTutorial?.category_list?.map((item: any, index: number) => (
                                <Box className={classes.option} key={index}>
                                    <AccordionMenu
                                        text={item.title}
                                        isOpenOption={idSelectTopic === item.id}
                                        handleOption={() =>
                                            setIdSelectTopic(
                                                idSelectTopic === item.id ? null : item.id,
                                            )
                                        }
                                    >
                                        <div
                                            className={classes.answer}
                                            dangerouslySetInnerHTML={{
                                                __html: item.subcategory_list[0]?.text,
                                            }}
                                        />
                                    </AccordionMenu>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TutorialPage;
