import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { sendErrToSentry } from '../utils/common';

export const useWebWidget = () => {
    const userState = useSelector(selectUser)?.user_info;

    useEffect(() => {
        if (userState?.external_id) {
            try {
                window.zE(() => {
                    window.zE('webWidget', 'identify', {
                        name: `${userState?.name}, ${userState?.age} (${userState?.external_id})`,
                        email: userState?.email,
                    });
                    window.zE('webWidget', 'prefill', {
                        name: { value: userState?.name, readOnly: true },
                        email: { value: userState?.email, readOnly: true },
                    });
                });
            } catch (e) {
                sendErrToSentry(e);
            }
        }
    }, [userState]);
};
