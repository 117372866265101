import { useEffect, useState } from 'react';
import { postRequest } from 'utils/requests';

import { MIRROR } from 'data/mirrorConfig';

const useGetCaptchaConfig = () => {
    const [hasCaptcha, setHasCaptcha] = useState(false);

    useEffect(() => {
        postRequest(`/api/v3/click-history/get-lnd-captcha-setting`, {
            lnd: MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME.toLocaleLowerCase(),
            site_id: Number(MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID),
        })
            .then((res) => {
                if (res?.status) {
                    setHasCaptcha(res?.status);
                }
            })
            .catch(() => {
                return { status: false };
            });
    }, []);

    return { hasCaptcha };
};

export default useGetCaptchaConfig;
