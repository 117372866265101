import React from 'react';
import classes from './ProgressBar.module.scss';
import PropTypes from 'prop-types';

const ProgressBar = ({ val = 0, from = 0 }) => {
    const calcPercent = (curValue, sumValue, round = 0) => {
        if (typeof curValue !== 'number' || typeof sumValue !== 'number') {
            return '';
        }
        const value = +((curValue / sumValue) * 100).toFixed(round);
        return value > 100 ? 100 : value;
    };

    const progressValueStyle = {
        width: from ? `${calcPercent(val, from)}%` : '0%',
    };

    return (
        <div className={classes.wrap}>
            <div className={classes.progress}>
                <div style={progressValueStyle} className={classes.progressBar}></div>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    val: PropTypes.number,
    from: PropTypes.number,
};
export default ProgressBar;
