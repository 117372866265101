import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getChatList } from 'redux/chat/selectors';
import { selectUser } from 'redux/auth/selectors';
import { getReFetchAction } from 'redux/interface/selectors';
import { fetchChatList, delCardChat } from 'redux/chat/operations';

import { getQueryParam, sanitizedSearchItem } from 'utils/common';
import { useDebouncedState } from 'hooks';

const useChatPage = () => {
    const isFirstRender = useRef(true);
    const [isOnline, setIsOnline] = useState(false);
    const [search, debouncedSearch, setSearch] = useDebouncedState('');
    const [pagination, setPagination] = useState(1);

    const chatList = useSelector(getChatList);
    const userInfo = useSelector(selectUser);

    const filterChat = getQueryParam('filter') || 'ALL';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const socketAction = useSelector(getReFetchAction);

    useEffect(() => {
        getFirsPage();
    }, [filterChat, debouncedSearch, isOnline]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (socketAction.action === 'message') getFirsPage();
    }, [socketAction]);

    const getFirsPage = () => {
        setPagination(1);
        dispatch(
            fetchChatList({
                user_id: userInfo?.user_info?.external_id,
                ONLINE_STATUS: isOnline,
                SEARCH: sanitizedSearchItem(debouncedSearch),
                CHAT_TYPE: filterChat === 'ALL' || filterChat === undefined ? '' : filterChat,
                page: 1,
            }),
        );
    };

    const handleIsOnline = () => {
        setIsOnline(!isOnline);
    };

    const handleDelCardChat = (chat_uid) => {
        dispatch(
            delCardChat({
                user_id: userInfo?.user_info?.external_id,
                chat_uid: chat_uid,
            }),
        );
    };

    const handleCard = (chat_uid, id) => {
        navigate(`/chat/${chat_uid}?profile=${id}`);
    };

    const handleScroll = () => {
        setPagination(pagination + 1);
        dispatch(
            fetchChatList({
                user_id: userInfo?.user_info?.external_id,
                ONLINE_STATUS: isOnline,
                SEARCH: sanitizedSearchItem(debouncedSearch),
                CHAT_TYPE: filterChat === 'ALL' ? '' : filterChat,
                page: pagination + 1,
            }),
        );
    };

    return {
        chatList,
        handleIsOnline,
        isOnline,
        setSearch,
        search,
        handleDelCardChat,
        handleCard,
        handleScroll,
    };
};

export default useChatPage;
