import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/high-res.css';

import {
    Box,
    Button,
    ModalWindowModern,
    TextNameonChat,
    Textonchat,
} from 'components/atomsKit/Elements';

import { togleGlobalModal } from 'redux/interface/slice';
import { selectMyProfile } from 'redux/auth/selectors';

import { postRequest } from 'utils/requests';

import classes from './ModalConfirmPhone.module.scss';

interface IProps {
    modalIsOpen: boolean;
    closeModal: any;
}

const ModalConfirmPhone = ({ modalIsOpen, closeModal }: IProps) => {
    const [value, setValue] = useState(useSelector(selectMyProfile)?.user_detail?.phone);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [steep2Confirm, setSteep2Confirm] = useState(false);
    const [code, setCode] = useState({
        '0': undefined,
        '1': undefined,
        '2': undefined,
        '3': undefined,
    });

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const dispatch = useDispatch();

    const onChangeCode = (e: any) => {
        setCode({ ...code, [e.target.id]: e.target.value });
        const element = document.getElementById(String(+e.target.id + 1));
        if (element && e.target.value !== '') {
            element.focus();
        }
    };

    const onChangePhone = (value: any, isValid: boolean) => {
        setIsValidNumber(isValid);
        setValue(value);
    };

    const handleConfirm = (e: any) => {
        e.preventDefault();
        sendSMS();
    };

    const sendSMS = () => {
        postRequest('/api/v3/sms/send', { phone: `+${value}` }).then((res: any) => {
            if (res.status === true) {
                setSteep2Confirm(true);
            }
        });
    };

    const handleCheckNumber = () => {
        postRequest('/api/v3/sms/verify', {
            code: `${code['0']}${code['1']}${code['2']}${code['3']}`,
        }).then((res: any) => {
            if (res.status) {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: res.message,
                        description: `You entered a phone number +${value}, if this is not correct, change the phone number, if the number is correct, receive another SMS and enter 4 digits.`,
                        btnText: 'Got it',
                    }),
                );
            }
            setSteep2Confirm(false);
            setCode({
                '0': undefined,
                '1': undefined,
                '2': undefined,
                '3': undefined,
            });
            setValue('');
            closeModal();
        });
    };

    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
            {steep2Confirm ? (
                <Box>
                    <Box
                        className={classes.modalBody}
                        flex
                        align="center"
                        justify="center"
                        directionColumn
                    >
                        <TextNameonChat
                            className={classes.title}
                            text="Verify your phone"
                            size={18}
                            textAlign={'center'}
                        />
                        <Textonchat
                            textAlign={'center'}
                            text="An SMS with a code has been sent to your phone number. Please enter the 4 digits you received."
                            margin="20px 0 20px 0"
                        />

                        <Textonchat textAlign={'center'} text="SMS CODE" margin="0 0 20px 0" />

                        <Box flex>
                            <input
                                className={classes.codeBox}
                                ref={inputRefs[0]}
                                type="text"
                                id={'0'}
                                maxLength={1}
                                value={code['0']}
                                onChange={onChangeCode}
                            />
                            <input
                                className={classes.codeBox}
                                ref={inputRefs[1]}
                                type="text"
                                id={'1'}
                                maxLength={1}
                                value={code['1']}
                                onChange={onChangeCode}
                            />
                            <input
                                className={classes.codeBox}
                                ref={inputRefs[2]}
                                type="text"
                                id={'2'}
                                maxLength={1}
                                value={code['2']}
                                onChange={onChangeCode}
                            />
                            <input
                                className={classes.codeBox}
                                ref={inputRefs[3]}
                                type="text"
                                id={'3'}
                                maxLength={1}
                                value={code['3']}
                                onChange={onChangeCode}
                            />
                        </Box>

                        <Button
                            onClick={handleCheckNumber}
                            text="Check number"
                            icon="x1"
                            margin="24px 0 0 0"
                            fitContent
                            padding="0 40px 0 40px"
                        />

                        <Textonchat
                            textAlign={'center'}
                            text="Didn't receive SMS?"
                            margin="20px 0 0 0"
                        />

                        <Button text="Resend code" isWhite noBg borderOff onClick={sendSMS} />

                        <Textonchat
                            textAlign={'center'}
                            text="If you face difficulties with the number verification, you can call us at:"
                            margin="20px 0 0 0"
                        />
                        <Textonchat textAlign={'center'} text="+1 888 360 7551 (US)" />

                        <Textonchat
                            margin="20px 0 0 0"
                            textAlign={'center'}
                            text=" Provide us with your account ID and we'll help you to manage this on our side."
                        />
                    </Box>
                </Box>
            ) : (
                <form onSubmit={handleConfirm}>
                    <Box
                        className={classes.modalBody}
                        flex
                        align="center"
                        justify="center"
                        directionColumn
                    >
                        <TextNameonChat
                            className={classes.title}
                            text="Confirm your number"
                            size={18}
                            textAlign={'center'}
                        />
                        <Textonchat
                            textAlign={'center'}
                            text="Please enter your real phone number, which you can verify via SMS right now."
                            margin="20px 0 20px 0"
                        />

                        <PhoneInput
                            inputStyle={{ width: '100%', height: '42px' }}
                            country={'us'}
                            value={value}
                            enableAreaCodes={true}
                            onChange={(value, country: any, e, formattedValue) => {
                                onChangePhone(
                                    value,
                                    country.format.length === formattedValue.length,
                                );
                            }}
                        />

                        <Button
                            disabled={!isValidNumber}
                            text="Receive SMS"
                            icon="x1"
                            margin="24px 0 0 0"
                            fitContent
                            padding="0 40px 0 40px"
                        />

                        <Textonchat
                            textAlign={'center'}
                            text="If you face difficulties with the number verification, you can call us at:"
                            margin="20px 0 0 0"
                        />
                        <Textonchat textAlign={'center'} text="+1 888 360 7551 (US)" />

                        <Textonchat
                            margin="20px 0 0 0"
                            textAlign={'center'}
                            text=" Provide us with your account ID and we'll help you to manage this on our side."
                        />
                    </Box>
                </form>
            )}
        </ModalWindowModern>
    );
};

export default ModalConfirmPhone;
