import React from 'react';
import { Box, ModalWindowModern, Button, CloseButton, Image } from 'components/atomsKit/Elements';

import classes from './ModalPaymentSuccessful.module.scss';

interface IProps {
    modalIsOpen?: boolean;
    credits?: number;
    closeModal: any;
}

const ModalPaymentSuccessful = ({ modalIsOpen = true, credits = 0, closeModal }: IProps) => {
    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={() => {}} hideCloseButton>
            <Box className={classes.modalBody} flex directionColumn align="center" justify="center">
                <CloseButton onClick={closeModal} />
                <Image name="payment_success" size={50} />
                <h2 className={classes.title}>Payment Successful!</h2>
                <p className={classes.text}>
                    Thank you for your purchase of{' '}
                    <span className={classes.credits}>{credits} credits,</span> which give you
                    access to a world of exclusive benefits and features.
                </p>

                <Button text="Done" className={classes.btn} onClick={closeModal} />
            </Box>
        </ModalWindowModern>
    );
};

export default ModalPaymentSuccessful;
