import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import usePageUtils from 'hooks/usePageUtils';
import { useUploadFile, useSendSupportReq } from 'hooks';
import { getMessages, sendMessage } from 'redux/chat/operations';
import { dataModalContent, chatCounter } from 'redux/interface/operations';
import { setFavorite } from 'redux/chat/operations';
import { togleGlobalModal, changeProfileForGift } from 'redux/interface/slice';
import { readStatus, updateStatusLikeInMessenger } from 'redux/chat/slice';
import { getWomanData, getChats, getChatInfo, isLoadingChat } from 'redux/chat/selectors';
import { selectMyProfile } from 'redux/auth/selectors';
import { getSayHalloList } from 'redux/interface/selectors';
import { setWinkTrue } from 'redux/chat/slice';
import { postRequest } from 'utils/requests';
import { getSocket } from 'utils/socket';
import { getQueryParam } from 'utils/common';

const useMessagerPage = () => {
    const [valueMessage, setValueMessage] = useState('');
    const [togleIsOpenStickersBox, setTogleIsOpenStickersBox] = useState(false);

    const [isOpenModalAddBlock, setIsOpenModalAddBlock] = useState(false);

    const { chat_uid } = useParams();

    const female_id = getQueryParam('profile') || 'null';

    const womanData = useSelector(getWomanData);
    const chatInfo = useSelector(getChatInfo);

    const sayHalloList = useSelector(getSayHalloList);

    const isLoading = useSelector(isLoadingChat);
    const chats = useSelector(getChats);
    const user_avatar = useSelector(selectMyProfile)?.user_detail?.photo_link;
    const user_name = useSelector(selectMyProfile)?.user_detail?.name;

    const {
        pagination,
        scrollDown,
        wrapListRef,
        userExternalId,
        onScrollBottom,
        setOnScrollBottom,
        togle,
        setPositionScroll,
    } = usePageUtils({
        startPage: 1,
        trigerScrollDown: [chats],
        rulesSocket: {
            func: (action) => {
                if (
                    action.action === 'message' &&
                    chat_uid === action?.notification_object?.chat_uid
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            rules: ['message'],
        },
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        handleUploadFile,
        uploadFiles,
        handleDelMedia,
        handleItemMedia,
        handleOpenUpploadFile,
        handleUploadNavBtn,
        uploadLoader,
        navConfig,
        isOpenModaAddFile,
        setIsOpenModaAddFile,
        selectedMedia,
    } = useUploadFile({
        userExternalId,
        isMessager: true,
    });

    const {
        sendSupportReq,
        selectFileToComplaint,
        setSelectFileToComplaint,
        describe,
        isOpenModalComplaint,
        setIsOpenModalComplaint,
        mail,
        changeFileToComplaint,
        handleMail,
        onChangeDescribe,
    } = useSendSupportReq();

    useEffect(() => {
        if (chats) {
            setPositionScroll(chats[0]?.id);
        }
        dispatch(
            getMessages({
                chat_uid: chat_uid,
                page: pagination,
                male_id: userExternalId,
                female_id: female_id,
            }),
        );

        if (getSocket()) {
            getSocket().emit('socket', {
                action: 'open_chat',
                chat_uid: chat_uid,
                female_id: female_id,
            });
        }
    }, [pagination, togle]);

    useEffect(() => {
        dispatch(chatCounter(userExternalId));
    }, []);

    const handleValueMessage = (e) => {
        scrollDown();
        if (e.target.value.length <= 300) {
            if (onScrollBottom === false) {
                setOnScrollBottom(true);
            }
            setValueMessage(e.target.value);
        }
    };

    const onChangeSetLike = () => {
        const config = {
            message_type: 'SENT_LIKE',
            message_content: 'TEST liked your profile',
        };
        onChangeSendMessage(config);
        dispatch(updateStatusLikeInMessenger());
    };

    const onChangeSendMessage = (config) => {
        const trimmedText = valueMessage?.trim();
        if (
            trimmedText !== '' ||
            selectedMedia?.length > 0 ||
            config?.message_type === 'SENT_WINK' ||
            config?.message_type === 'SENT_LIKE'
        ) {
            if (onScrollBottom === false) {
                setOnScrollBottom(true);
            }
            const reqBody = {
                recipient_id: womanData?.external_id,
                sender_id: userExternalId,
            };
            dispatch(setWinkTrue());
            dispatch(
                sendMessage(
                    config
                        ? { ...reqBody, ...config }
                        : {
                              ...reqBody,
                              filename: '',
                              message_content: trimmedText,
                              message_type: 'SENT_TEXT',
                          },
                ),
            );
            setValueMessage('');
        }
    };

    const onChangeStiker = (name) => {
        if (onScrollBottom === false) {
            setOnScrollBottom(true);
        }
        dispatch(
            sendMessage({
                filename: '',
                message_content: name,
                message_type: 'SENT_STICKER',
                recipient_id: womanData?.external_id,
                sender_id: userExternalId,
            }),
        );
        setValueMessage('');
        setTogleIsOpenStickersBox(false);
    };

    const handleFavorite = (favorite) => {
        dispatch(
            setFavorite({
                chat_uid: chat_uid,
                favorite: !favorite,
                user_id: womanData?.external_id,
            }),
        );
    };

    const handleDropBtnLeter = () => {
        navigate(`/letter?profileId=${womanData?.public_external_id}`);
    };
    const handleDropBtnBlock = (mode) => {
        if (mode === 'unblock') {
            postRequest('/api/chat/removeBlackList', { chat_uid: chat_uid }).then(() => {
                navigate('/menu/block');
            });
        } else {
            setIsOpenModalAddBlock(true);
        }
    };
    const handleDropBtnComplain = () => {
        setIsOpenModalComplaint(true);
    };

    const handleAddBlock = () => {
        postRequest('/api/chat/addBlackList', {
            user_id: userExternalId,
            chat_uid: chat_uid,
        }).then(() => {
            navigate(`/chat?filter=ALL`);
        });
    };

    const handleProfile = (id) => {
        navigate(`/profile-woman/${id}`);
    };

    const handleContent = (content) => {
        dispatch(
            dataModalContent({
                user_id: userExternalId,
                chat_uid: chat_uid,
                media_id: content.id,
                content: content,
            }),
        );
    };

    const handleSendGift = () => {
        const infoForSend = {
            id: womanData?.external_id,
            public_external_id: womanData?.public_external_id,
            name: womanData?.name,
            age: womanData?.age,
            isOnline: womanData?.online,
            flag: womanData?.country_code,
            avatar: womanData?.avatar,
            city: womanData?.city_name,
        };
        dispatch(changeProfileForGift(infoForSend));
        navigate('/gifts');
    };

    const handleSendMedias = () => {
        if (onScrollBottom === false) {
            setOnScrollBottom(true);
        }
        if (selectedMedia?.length > 0) {
            selectedMedia?.forEach((item) => {
                onChangeSendMessage({
                    filename: item?.filename,
                    message_content: item?.link,
                    message_type: `SENT_${item?.content_type?.toUpperCase()}`,
                });
            });
        }
        setIsOpenModaAddFile(false);
    };

    const handlePlayAudio = async (media_id, payed, playFunc) => {
        const REQ_BODY = {
            chat_uid: chat_uid,
            media_id: media_id,
            message_id: media_id,
            user_id: userExternalId,
        };

        if (payed) {
            await postRequest('/api/chat/viewMedia', REQ_BODY);
        } else {
            const res = await postRequest('/api/chat/readAudio', REQ_BODY);

            if (res?.status) {
                await postRequest('/api/chat/viewMedia', REQ_BODY);
                dispatch(
                    readStatus({
                        chat_uid: REQ_BODY.chat_uid,
                        message_id: REQ_BODY.message_id,
                        payed: true,
                    }),
                );
                playFunc.src = res?.message_content;
                playFunc.play();
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: 'You lack credits',
                        description: `You need to get more credits to be able to open video`,
                        btnText: 'Get credits',
                    }),
                );
            }
        }
    };

    return {
        womanData,
        chatInfo,
        isLoading,
        chats,
        valueMessage,
        handleValueMessage,
        onChangeSendMessage,
        wrapListRef,
        togleIsOpenStickersBox,
        setTogleIsOpenStickersBox,
        onChangeStiker,
        scrollDown,
        handleFavorite,
        isOpenModaAddFile,
        setIsOpenModaAddFile,
        user_avatar,
        user_name,
        navConfig,
        handleUploadNavBtn,
        handleOpenUpploadFile,
        uploadFiles,
        handleItemMedia,
        selectedMedia,
        handleSendMedias,
        handleUploadFile,
        handleDelMedia,
        handleDropBtnLeter,
        handleDropBtnBlock,
        handleDropBtnComplain,
        isOpenModalAddBlock,
        setIsOpenModalAddBlock,
        handleAddBlock,
        isOpenModalComplaint,
        setIsOpenModalComplaint,
        changeFileToComplaint,
        selectFileToComplaint,
        setSelectFileToComplaint,
        handleMail,
        mail,
        onChangeDescribe,
        describe,
        sendSupportReq,
        handleProfile,
        handleContent,
        handleSendGift,
        sayHalloList,
        handlePlayAudio,
        onChangeSetLike,
        uploadLoader,
    };
};

export default useMessagerPage;
