import React from 'react';
import classes from './GirlProfile.module.scss';

interface IProps {
    config: {
        title: string;
        list?: { key: string; name: string; isArr?: boolean }[];
    };
    text?: string;
    data?: any;
}

const InfoList = (props: IProps) => {
    const { config, data, text } = props;
    return (
        <div>
            <h2 className={classes.title}>{config.title}</h2>
            {text ? (
                <div className={classes.ul}>
                    <p className={classes.labelLi}>{text}</p>
                </div>
            ) : (
                <ul className={classes.ul}>
                    {config.list?.map(
                        (item) =>
                            data[item.key] && (
                                <li key={item.name}>
                                    <span className={classes.nameLi}>{item.name}:</span>
                                    {item.isArr ? (
                                        <span className={classes.labelLi}>
                                            {data[item.key].join(', ')}
                                        </span>
                                    ) : (
                                        <span className={classes.labelLi}>{data[item.key]}</span>
                                    )}
                                </li>
                            ),
                    )}
                </ul>
            )}
        </div>
    );
};

export default InfoList;
