import React from 'react';
import { Image } from 'components/atomsKit/Elements';

import classes from './Logo.module.scss';

interface IProps {
    onClick?: () => void;
    logo?: string;
}
const Logo = ({ onClick, logo }: IProps) => {
    return (
        <h1 className={classes.wrap}>
            <button onClick={onClick} className={classes.button}>
                <Image name={logo as string} className={classes.logo} />
            </button>
        </h1>
    );
};

export default Logo;
