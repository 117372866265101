import React from 'react';
import cn from 'classnames';
import { Icon, ModalWindowModern } from 'components/atomsKit/Elements';
import classes from './ModalFilteModern.module.scss';

interface IProps {
    modalIsOpen?: boolean;
    closeModal: () => void;
    children: React.ReactElement;
    position?: 'left' | 'right';
}

const ModalFilteModern = ({ modalIsOpen, closeModal, children, position }: IProps) => {
    return (
        <ModalWindowModern
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            hideCloseButton
            showFooterHeder
        >
            <button
                onClick={closeModal}
                className={cn(
                    classes.btn,
                    position === 'right' ? classes.btn_right : classes.btn_left,
                )}
            >
                <Icon icon={'ok'} className={classes.icon} color={'white'} />
            </button>
            {children}
        </ModalWindowModern>
    );
};

export default ModalFilteModern;
