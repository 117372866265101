export const IMG_BUCKET = 'chats-images.cdndate.net';
export const VIDEO_BUCKET = 'chats-videos';
export const AUDIO_BUCKET = 'chats-audios.cdndate.net';
export const MEN_IMAGES = 'men-images.cdndate.net';
export const AWS_THUMBS_SMALL_SIZE_WIDTH = 250;
export const AWS_THUMBS_SMALL_SIZE_HEIGHT = 250;
export const AWS_THUMBS_BIG_SIZE_WIDTH = 1920;
export const AWS_THUMBS_BIG_SIZE_HEIGHT = 1280;
export const AWS_THUMBS = [
    {
        name: 'small',
        width: AWS_THUMBS_SMALL_SIZE_WIDTH,
        height: AWS_THUMBS_SMALL_SIZE_HEIGHT,
    },
    {
        name: 'big',
        width: AWS_THUMBS_BIG_SIZE_WIDTH,
        height: AWS_THUMBS_BIG_SIZE_HEIGHT,
    },
];

export const AWS_IMAGE_EXCEPTION = [
    'filter_blur-ewjrhewj',
    'filter_blur-jghjhututj',
    'filter_blur-jhsefwdf',
    'filter_blur-jiwejdsfd',
    'filter_blur-jwdhfjondsf',
    'filter_blur-ksdfjkds',
    'filter_blur-sdfjhdskjlfd',
    'filter_blur-skdfhdsfd',
    'filter_blur-werhuefds',
];

export const AWS_CLOUDFRONT_IMAGE = {
    origin: 'chats-images.s3.us-east-2.amazonaws.com',
    cdn: 'd2aeesjlhbqzwc.cloudfront.net',
};
export const AWS_CLOUDFRONT_VIDEO = {
    origin: 'chats-videos.s3.us-east-2.amazonaws.com',
    cdn: 'd30l6m4x9yzl15.cloudfront.net',
};
export const AWS_CLOUDFRONT_AUDIO = {
    origin: 'chats-audios.s3.us-east-2.amazonaws.com',
    cdn: 'd305sqv25mhsh3.cloudfront.net',
};
