import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Button,
    ModalWindowModern,
    InputModern,
    TextNameonChat,
    Textonchat,
} from 'components/atomsKit/Elements';

import { togleGlobalModal } from 'redux/interface/slice';
import { selectMyProfile } from 'redux/auth/selectors';

import { putRequest } from 'utils/requests';

import classes from './ModalConfirmName.module.scss';

interface IProps {
    modalIsOpen: boolean;
    closeModal: any;
}

const ModalConfirmName = ({ modalIsOpen, closeModal }: IProps) => {
    const [value, setValue] = useState('');

    const realName = useSelector(selectMyProfile)?.user_detail?.real_name;

    useEffect(() => {
        setValue(realName);
    }, [realName]);

    const dispatch = useDispatch();

    const onChangeName = (e: any) => {
        setValue(e.target.value);
    };

    const handleConfirm = (e: any) => {
        e.preventDefault();
        putRequest('/api/v3/user-profile/real-name', { realName: value }).then((res: any) => {
            if (res.status === true) {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: 'Your name has been saved',
                        btnText: 'Got it',
                    }),
                );
                closeModal();
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: res.message,
                        btnText: 'Got it',
                    }),
                );
            }
            closeModal();
        });
    };

    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <form onSubmit={handleConfirm}>
                <Box
                    className={classes.modalBody}
                    flex
                    align="center"
                    justify="center"
                    directionColumn
                >
                    <TextNameonChat
                        className={classes.title}
                        text="Confirm your real name"
                        size={18}
                        textAlign={'center'}
                    />
                    <Textonchat
                        text="Please write your real name, the information is completely confidential and will only be used by our support team to contact you."
                        margin="20px 0 4px 0"
                    />

                    <InputModern
                        placeholder="Your real name"
                        value={value}
                        onChange={onChangeName}
                    />

                    <Button
                        text="Confirm name"
                        icon="x1"
                        margin="24px 0 0 0"
                        fitContent
                        padding="0 40px 0 40px"
                    />
                </Box>
            </form>
        </ModalWindowModern>
    );
};

export default ModalConfirmName;
