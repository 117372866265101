import React from 'react';
import { ButtonNavigation } from 'components/atomsKit/Elements';
import classes from './Navigation.module.scss';

interface IProps {
    navConfig: any;
    onSecondAction?: any;
}

const Navigation = ({ navConfig, onSecondAction }: IProps) => {
    return (
        <nav className={classes.wrap}>
            <ul className={classes.menu}>
                {Object.keys(navConfig).map((item) => (
                    <li key={navConfig[item].text} className={classes.menuItem}>
                        <ButtonNavigation
                            url={navConfig[item].url}
                            icon={navConfig[item].icon}
                            text={navConfig[item].text}
                            counter={navConfig[item].counter}
                            onSecondAction={onSecondAction}
                        />
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navigation;
