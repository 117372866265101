import React from 'react';
import cn from 'classnames';
import { Box, Avatar, NameAge, Icon, Image } from 'components/atomsKit/Elements';

import classes from './ChatCard.module.scss';

interface IProps {
    className?: string;
    chat_uid: string;
    external_id: number;
    isFavorite?: boolean;
    isOnline?: boolean;
    name: string;
    age: number;
    message?: string;
    isNew?: boolean;
    time?: any;
    imgLink: string;
    isRead?: boolean;
    handleCard: (chat_uid: string, id: number) => void;
    handleDelCard?: (chat_uid: any) => void;
}

const ChatCard = (props: IProps) => {
    const {
        className,
        chat_uid,
        name,
        age,
        message,
        imgLink,
        isNew,
        time,
        external_id,
        handleCard,
        handleDelCard,
        isFavorite,
        isOnline,
        isRead,
    } = props;
    return (
        <Box
            className={cn(classes.wrap, className && className)}
            flex
            align={'center'}
            position={'relative'}
        >
            {/* заменить на кнопку или линк */}
            <Box onClick={() => handleCard(chat_uid, external_id)} className={classes.cardLink}>
                <Avatar imgLink={imgLink} isOnline={isOnline} isFavorite={isFavorite} />
                <Box marginLeft="10px" flex directionColumn align={'start'}>
                    <NameAge name={name} age={age} />
                    <p className={classes.message}>{message}</p>
                </Box>
                {isNew && <div className={classes.stick}>New</div>}
            </Box>
            {isRead !== undefined && (
                <Image name={`${isRead ? 'readed' : 'send'}`} className={classes.readStatus} />
            )}
            <div className={classes.time}>{time}</div>
            {handleDelCard && (
                <button className={classes.btn} onClick={() => handleDelCard(chat_uid)}>
                    <Icon icon={'ok'} className={classes.icon} />
                </button>
            )}
        </Box>
    );
};

export default ChatCard;
