import React from 'react';
import { Box, Avatar, NameAge, Icon, Image } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './TooltipTransaction.module.scss';
import PropTypes from 'prop-types';

const TooltipTransaction = ({
    id,
    message,
    name,
    age,
    iconType,
    imgLink,
    imgLinkUser = '',
    handleDel,
    handleCard,
    bgColor = '',
}) => {
    return imgLinkUser ? (
        <Box position={'relative'}>
            <Box
                className={cn(
                    classes.wrap,
                    bgColor === 1 && classes.wrap_bg1,
                    bgColor === 2 && classes.wrap_bg2,
                )}
                flex
                align={'center'}
                justify={'center'}
                onClick={() => {
                    handleCard(id);
                }}
            >
                <p
                    className={cn(
                        classes.message,
                        classes.message_double,
                        bgColor === 1 && classes.message_white,
                    )}
                >
                    {message}
                </p>
                <Box flex align="center">
                    <Avatar small imgLink={imgLinkUser} />
                    <Image name={iconType} className={classes.imageTypeDouble} />
                    <Avatar small imgLink={imgLink} />
                </Box>
            </Box>
            <button className={classes.btn} onClick={() => handleDel(id)}>
                <Icon icon={'ok'} className={classes.icon} />
            </button>
        </Box>
    ) : (
        <Box position={'relative'}>
            <Box
                className={cn(
                    classes.wrap,
                    bgColor === 1 && classes.wrap_bg1,
                    bgColor === 2 && classes.wrap_bg2,
                )}
                flex
                align="center"
                onClick={() => {
                    handleCard(id);
                }}
            >
                <Avatar small imgLink={imgLink} />
                <Box marginLeft="10px">
                    {name && <NameAge name={name} age={age} />}
                    <Box flex align={'start'}>
                        {iconType && <Image name={iconType} className={classes.imageType} />}
                        <p className={cn(classes.message, bgColor && classes.white)}>{message}</p>
                    </Box>
                </Box>
            </Box>
            <button className={classes.btn} onClick={() => handleDel(id)}>
                <Icon icon={'ok'} className={cn(classes.icon, bgColor && classes.white)} />
            </button>
        </Box>
    );
};

TooltipTransaction.propTypes = {
    id: PropTypes.number,
    message: PropTypes.string,
    name: PropTypes.string,
    age: PropTypes.number,
    iconType: PropTypes.string,
    imgLink: PropTypes.string,
    imgLinkUser: PropTypes.string,
    handleDel: PropTypes.func,
    handleCard: PropTypes.func,
    bgColor: PropTypes.number,
};

export default TooltipTransaction;
