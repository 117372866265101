import React from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { Icon } from 'components/atomsKit/Elements';
import classes from './ModalWindow.module.scss';

interface IProps {
    modalIsOpen?: boolean;
    closeModal?: () => void;
    children: React.ReactElement;
    className?: object;
}

const ModalWindow = ({ modalIsOpen, closeModal, children, className }: IProps) => {
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={cn(classes.modal, className)}
            overlayClassName="Overlay"
            ariaHideApp={false}
        >
            <button onClick={closeModal} className={classes.btn}>
                <Icon icon={'x'} className={classes.icon} />
            </button>
            <div className={classes.modalBody}>{children}</div>
        </Modal>
    );
};

export default ModalWindow;
