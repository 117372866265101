import { postRequest, getRequest } from 'utils/requests';

export const useTransactionConfig = () => {
    const fetchTransactionConfig = () => {
        getRequest(`/api/v3/ab-test/config/transaction`).then((configRes) => {
            if (configRes) {
                const isRegister = window?.location?.search?.includes('registration=success');

                postRequest(`/api/v3/front-sync/transaction`, {
                    isRegister: isRegister,
                    config: {
                        register: Array.isArray(configRes?.register) ? configRes?.register : [],
                        recycle: Array.isArray(configRes?.recycle) ? configRes?.recycle : [],
                        infinity: Array.isArray(configRes?.infinity) ? configRes?.infinity : [],
                    },
                    registerSettings: configRes?.startTransaction ?? {},
                });
            }
        });
    };
    return {
        fetchTransactionConfig,
    };
};
