import { useState } from 'react';

import {
    BILLING_ACTION_CLOSE_PAYMENT_ROUTING_PAGE,
    BILLING_ACTION_INIT_PAYMENT_ROUTING_ERROR,
    BILLING_ACTION_LAUNCH_PAYMENT_ROUTING_ERROR,
    BILLING_ACTION_OPEN_PAYMENT_ROUTING_PAGE,
} from 'data/billing';
import { RED_USER_LOG_TYPES } from 'data/user';

import { useSelector, useDispatch } from 'react-redux';
import { togleGlobalModal } from 'redux/interface/slice';

import { getUserInfo } from 'redux/auth/operations';

import { useGoogleDataLayer } from 'hooks';

import { getRequest, postRequest } from 'utils/requests';

import { MIRROR } from 'data/mirrorConfig';

import {
    useHandleBilling,
    useTransition,
    useHandleRedUser,
    useTransactionConfig,
    useSuccessPaymentCallback,
} from 'hooks';
import { sendErrToSentry } from '../utils/common';

const openZendesk = () => {
    try {
        window.zE.activate();
    } catch (error) {
        sendErrToSentry(error);
    }
};

export function getLegalEntityInfo() {
    return 'ASTRASOFT PROJECTS LTD, Reg. #ΗΕ 409810, Ifigeneias 14, 3036, Limassol, Cyprus';
}

export function getRoutingRefundPolicy() {
    return `${window?.location?.origin}/policy?policy_page=return-and-refund-policy`;
}

export const useHandleRouting = () => {
    const userState = useSelector((state) => state?.auth?.user?.user_info);
    const abTest = useSelector((state) => state?.auth?.user?.abTest);
    const handleBilling = useHandleBilling();
    const transition = useTransition();
    const handleRedUser = useHandleRedUser();
    const transactionConfig = useTransactionConfig();
    const { pushEventTryPay, pushEventBeginCheckout } = useGoogleDataLayer();
    const successPaymentCallback = useSuccessPaymentCallback();
    const dispatch = useDispatch();

    const [isInitialized, setIsInitialized] = useState(false);

    const paymentLink =
        window?.location?.host?.includes('stage') || window?.location?.host?.includes('localhost')
            ? `https://dev-payment.${MIRROR[
                  process.env.REACT_APP_MIRROR_NAME
              ].NAME.toLocaleLowerCase()}.com`
            : `https://payment.${MIRROR[
                  process.env.REACT_APP_MIRROR_NAME
              ].NAME.toLocaleLowerCase()}.com`;

    const createScript = (loadCallback) => {
        try {
            const block = document.createElement('div');
            block.id = 'sphera-form';
            document.body.appendChild(block);

            const script = document.createElement('script');

            script.id = 'sphera-script';
            script.src = `${paymentLink}/lib.min.js`;

            document.body.appendChild(script);
            script.addEventListener('load', loadCallback);
        } catch (err) {
            sendErrToSentry(err);
        }
    };

    const onPaymentDone = async () => {
        if (+abTest && !+userState?.ghost) transactionConfig.fetchTransactionConfig();

        const lastPurchase = await getRequest('/api/v3/payment/purchase/last').then((res) =>
            res?.status ? res?.data : null,
        );

        if (lastPurchase) {
            successPaymentCallback({
                count: lastPurchase?.packages,
                credits: lastPurchase?.lastPurchase?.amount,
                price: lastPurchase?.lastPurchase?.price,
                isRouting: true,
            });
            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_SUCCESS,
                JSON.stringify({ payment_method: 'sphera', ...lastPurchase }),
            );
        }

        dispatch(getUserInfo(true));
    };

    const onPaymentError = async () => {
        const lastPurchase = await getRequest('/api/v3/payment/purchase/last').then((res) =>
            res?.status ? res?.data : null,
        );

        if (lastPurchase) {
            if (lastPurchase?.packages > 0) transition.addTail('?utm_credits_issue');
            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_DECLINE,
                JSON.stringify({ payment_method: 'ee', ...lastPurchase }),
            );
            if (lastPurchase?.packages === 0) {
                pushEventTryPay();
            }
        }

        dispatch(getUserInfo());
        dispatch(
            togleGlobalModal({
                isOpen: 'openPayFail',
            }),
        );
    };

    const onClose = () => {
        handleBilling.createBillingLog(BILLING_ACTION_CLOSE_PAYMENT_ROUTING_PAGE);
    };

    const init = () => {
        if (isInitialized || window.sphera?.launch) {
            return;
        }

        setIsInitialized(true);

        const initObj = {
            policyLink: getRoutingRefundPolicy(),
            corpDesc: getLegalEntityInfo(),
            merchantDomain: MIRROR[process.env.REACT_APP_MIRROR_NAME].PAY_URL,
            onClose,
            onPaymentDone,
            onPaymentError,
            openZendesk,
        };

        createScript(() => {
            try {
                window.sphera.init(paymentLink, localStorage.getItem('token'), initObj);
            } catch (err) {
                handleBilling.createBillingLog(BILLING_ACTION_INIT_PAYMENT_ROUTING_ERROR);
            }
        });
    };

    const launch = () => {
        try {
            window.sphera?.launch();

            if (!localStorage.getItem('begin_checkout')) {
                localStorage.setItem('begin_checkout', '1');

                postRequest('/api/marketing/checkMarketingConversion', {
                    eventName: 'begin_checkout',
                }).then((res) => {
                    if (res?.status && res?.response?.check) {
                        pushEventBeginCheckout();
                    }
                });
            }

            handleBilling.createBillingLog(BILLING_ACTION_OPEN_PAYMENT_ROUTING_PAGE);
        } catch (err) {
            handleBilling.createBillingLog(BILLING_ACTION_LAUNCH_PAYMENT_ROUTING_ERROR);
        }
    };

    return {
        init,
        launch,
    };
};
