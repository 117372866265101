import React from 'react';
import useIsAllWindow from 'hooks/useIsAllWindow';
import cn from 'classnames';
import classes from './PageWrap.module.scss';

interface IProps {
    className?: string;
    id?: string;
    children: any;
    refWrap?: any;
    mutationHeight?: number;
    startPositionY?: number;
    height100?: boolean;
    padding?: string;
    margin?: string;
    scroll?: boolean;
}

const PageWrap = ({
    className,
    id,
    children,
    refWrap,
    mutationHeight = 0,
    startPositionY = 0,
    height100,
    padding,
    margin,
    scroll,
}: IProps) => {
    const { browsPanelHide } = useIsAllWindow();
    return (
        <div
            className={cn(classes.pageWrap, className, scroll && classes.scroll)}
            ref={refWrap}
            style={{
                [height100 ? 'height' : 'maxHeight']: `${
                    browsPanelHide - 90 - mutationHeight - startPositionY
                }px`,
                padding: padding,
                margin: margin,
                bottom: `${40 + startPositionY}px`,
            }}
            id={id}
        >
            {children}
        </div>
    );
};

export default PageWrap;
