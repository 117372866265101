import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Box,
    Button,
    ModalWindowModern,
    InputModern,
    TextNameonChat,
} from 'components/atomsKit/Elements';

import { togleGlobalModal } from 'redux/interface/slice';

import { postRequest } from 'utils/requests';

import classes from './ModalChangePassword.module.scss';

interface IProps {
    modalIsOpen: boolean;
    closeModal: any;
}

const ModalChangePassword = ({ modalIsOpen, closeModal }: IProps) => {
    const [valuePass, setValuePass] = useState('');
    const [valuePassToo, setValuePassToo] = useState('');

    const dispatch = useDispatch();

    const onChangePass = (e: any) => {
        setValuePass(e.target.value);
    };

    const onChangePassToo = (e: any) => {
        setValuePassToo(e.target.value);
    };

    const handleConfirm = (e: any) => {
        e.preventDefault();
        if (valuePass !== valuePassToo) {
            dispatch(
                togleGlobalModal({
                    isOpen: 'open',
                    text: "The password doesn't match",
                    btnText: 'Got it',
                }),
            );
        } else if (valuePass.length < 8) {
            dispatch(
                togleGlobalModal({
                    isOpen: 'open',
                    text: 'Please enter a new password of 8 or more characters',
                    btnText: 'Got it',
                }),
            );
        } else {
            postRequest('/api/user/changePassword', { password: valuePass }).then((res: any) => {
                if (res.status) {
                    dispatch(
                        togleGlobalModal({
                            isOpen: 'open',
                            text: 'Password was updated',
                            btnText: 'Got it',
                        }),
                    );
                }
                closeModal();
            });
        }
    };

    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <form onSubmit={handleConfirm}>
                <Box
                    className={classes.modalBody}
                    flex
                    align="center"
                    justify="center"
                    directionColumn
                >
                    <TextNameonChat
                        className={classes.title}
                        text="Change your password"
                        size={18}
                        textAlign={'center'}
                        margin="0 0 40px 0"
                    />

                    <Box
                        width100
                        flex
                        align="center"
                        justify="center"
                        directionColumn
                        marginBottom="40px"
                    >
                        <InputModern
                            label="ENTER NEW PASSWORD"
                            type={'password'}
                            placeholder="New password (8+ characters)"
                            value={valuePass}
                            onChange={onChangePass}
                        />
                    </Box>

                    <InputModern
                        label="RE-ENTER NEW PASSWORD"
                        type={'password'}
                        placeholder="New password (8+ characters)"
                        value={valuePassToo}
                        onChange={onChangePassToo}
                    />

                    <Button
                        text="Change password"
                        icon="x1"
                        margin="24px 0 0 0"
                        fitContent
                        padding="0 40px 0 40px"
                    />
                </Box>
            </form>
        </ModalWindowModern>
    );
};

export default ModalChangePassword;
