import React from 'react';
import { useLayoutEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Box, Button, PageWrap, Image, Textonchat } from 'components/atomsKit/Elements';

import useCresitsPage from './useCresitsPage';

import { convertPrice } from 'utils/common';

import classes from './CresitsPage.module.scss';
import styles from './styles.module.scss';

const CresitsPage = () => {
    const { user_detail, purchase_history, handlePayment } = useCresitsPage();

    const { block } = useParams();

    useLayoutEffect(() => {
        const anchor = document.getElementById(block ?? '');
        if (anchor) {
            const scrollFunc = () => {
                anchor.scrollIntoView();
            };
            window.requestAnimationFrame(scrollFunc);
        }
    }, [block]);

    const PURCHASE_HISTORY = () => {
        try {
            if (purchase_history && Object.values(purchase_history)?.length) {
                return Object.values(purchase_history).map((monthItem: any, monthKey) => (
                    <div className={styles.credits_table_list} key={monthKey}>
                        <div className={styles.credits_table_date_row}>
                            {Object.keys(purchase_history)[monthKey]}
                        </div>
                        {Object.values(monthItem).map((elem: any, key2: any) => (
                            <div
                                className={
                                    key2 % 2 ? styles.row_color_white : styles.row_color_blue
                                }
                                key={key2}
                            >
                                {elem?.map((elem2: any, key3: any) => {
                                    const isDate = elem2?.date_added?.includes('T') && key3 === 0;
                                    const date = isDate ? elem2?.date_added?.split('T')[0] : '';
                                    const dateTitle = isDate ? moment(date).format('D.M.Y') : '';

                                    return (
                                        <div className={styles.credits_table_row} key={key3}>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.date}`}
                                            >
                                                {dateTitle}
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.credits}`}
                                            >
                                                {+elem2?.amount || ''} credits
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.card}`}
                                            >
                                                {elem2?.card}
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.price}`}
                                            >
                                                ${convertPrice(elem2?.price) || ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                ));
            }
        } catch (err) {
            return <div />;
        }
    };

    return (
        <PageWrap scroll height100 padding="8px">
            <>
                <Box className={classes.cardWrap}>
                    <Box
                        className={classes.creditWrap}
                        flex
                        align={'center'}
                        justify={'between'}
                        marginBottom="10px"
                    >
                        <Box flex align={'center'}>
                            <Image name="coins" bgImg classNameIcon="btnIco" />
                            <Box marginLeft="10px" align={'center'}>
                                <Textonchat text={'Your balance'} className="btnColor" />
                                <Textonchat
                                    className="btnColor"
                                    size={24}
                                    text={user_detail?.credits + user_detail?.bonus_credits || 0}
                                />
                            </Box>
                        </Box>
                        <Button
                            text={'Get'}
                            iconSvg={'copy_white'}
                            onClick={handlePayment}
                            className={classes.addBtn}
                        />
                    </Box>
                </Box>
                <Textonchat
                    size={15}
                    text={'Purchase history'}
                    isHeader
                    className={classes.cardName}
                    margin="10px 0 0 10px"
                />
                <div className={styles.credits_table_wrap}>
                    <div className={styles.credits_table_title}>Purchase history</div>
                    <div className={styles.credits_table_mob_hor}>
                        <div className={styles.credits_table_head}>
                            <div className={`${styles.credits_table_head_item} ${styles.date}`}>
                                Date
                            </div>
                            <div className={`${styles.credits_table_head_item} ${styles.credits}`}>
                                Credit amount
                            </div>
                            <div className={`${styles.credits_table_head_item} ${styles.card}`}>
                                Card
                            </div>
                            <div className={`${styles.credits_table_head_item} ${styles.price}`}>
                                Price
                            </div>
                        </div>
                        <div className={styles.credits_table_list_wrap}>
                            {purchase_history ? PURCHASE_HISTORY() : ''}
                        </div>
                    </div>
                </div>
            </>
        </PageWrap>
    );
};

export default CresitsPage;
