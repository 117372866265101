import * as AWS from 'data/aws';
import { MIRROR } from 'data/mirrorConfig';

export const getImageLink = (link, size, site_id) => {
    if (!link?.length) return '';

    let exceptionImage = false;

    AWS.AWS_IMAGE_EXCEPTION.forEach((imgLink) => {
        if (link.includes(imgLink)) {
            exceptionImage = true;
        }
    });

    if (exceptionImage) return link;

    const [width, height] = size;
    const sizePrefix = `${+site_id > 1 ? 's' + site_id + '-' : ''}w-${width}-h-${height}-`;
    // Replace add prefix before last slash
    link = link.replace(/(\/)([^/]*)$/, '$1' + sizePrefix + '$2');

    // Replace png to jpg
    link = link.replace(/\.(png|PNG)$/, '.jpg');

    return link;
};

export const images = (photos) =>
    photos?.map((item) => ({
        original: getImageLink(
            item.link,
            [1024, 768],
            MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
        ),
        thumbnail: getImageLink(
            item.link,
            [640, 480],
            MIRROR[process.env.REACT_APP_MIRROR_NAME].SITE_ID,
        ),
        free: !(+item?.free === 0 && !item?.payed),
    }));
