import { useEffect, useState } from 'react';

export const useOpenModalNewPass = () => {
    const [isOpenModalNewPass, setIsOpenModalNewPass] = useState(false);
    useEffect(() => {
        const currentUrl = window.location.href;
        if (currentUrl.includes('/hash/')) {
            setIsOpenModalNewPass(true);
        } else {
            setIsOpenModalNewPass(false);
        }
    }, []);
    return { isOpenModalNewPass, setIsOpenModalNewPass };
};
