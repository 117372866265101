import React from 'react';
import { Box, ModalWindowModern, Button, CloseButton, Image } from 'components/atomsKit/Elements';

import { useHandleRouting } from 'hooks';

import classes from './ModalPaymentFailed.module.scss';

interface IProps {
    modalIsOpen?: boolean;
    closeModal: any;
    credits?: number;
}

const ModalPaymentFailed = ({ modalIsOpen = true, closeModal }: IProps) => {
    const { launch } = useHandleRouting();
    return (
        <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal} hideCloseButton>
            <Box className={classes.modalBody} flex directionColumn align="center" justify="center">
                <CloseButton onClick={closeModal} />
                <Image name="payment_failed" size={50} />
                <h2 className={classes.title}>Payment failed</h2>
                <p className={classes.text}>
                    Check the correctness of the entered data and the status of your bank account.
                </p>
                <div className={classes.supportWrap}>
                    <p className={classes.text}>
                        If the result does not change, our support team is always ready to help you.
                    </p>
                    <Button
                        text="Support"
                        className={classes.btnSupport}
                        iconSvg="payment_support"
                        onClick={() => window.zE.activate()}
                    />
                </div>
                <Button
                    text="Try again"
                    className={classes.btn}
                    iconSvg="reload"
                    onClick={() => {
                        launch();
                        closeModal();
                    }}
                />
            </Box>
        </ModalWindowModern>
    );
};

export default ModalPaymentFailed;
