import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Counter } from 'components/atomsKit/Elements';
import cn from 'classnames';
import classes from './ButtonNavigation.module.scss';

interface IProps {
    url: string;
    icon: string;
    text?: string;
    isHorizontalVersion?: boolean;
    counter?: number;
    onSecondAction?: any;
}

const ButtonNavigation = ({
    url,
    icon,
    text,
    isHorizontalVersion,
    counter,
    onSecondAction,
}: IProps) => {
    const handleNavLinkClick = () => {
        onSecondAction();
    };

    return (
        <NavLink
            onClick={handleNavLinkClick}
            to={url}
            className={({ isActive }) =>
                cn(
                    classes.link,
                    isHorizontalVersion && classes.btn_small,
                    isActive && classes.activeNavLink,
                )
            }
        >
            <div className={classes.iconContainer}>
                <Icon icon={icon} size={'100%'} />
                <Counter
                    counter={counter}
                    classCounter={cn(isHorizontalVersion ? classes.counter_small : classes.counter)}
                />
            </div>
            {text}
        </NavLink>
    );
};

export default ButtonNavigation;
