import React from 'react';
import {
    ChatFilterPanel,
    PageWrap,
    ContactRequestCard,
    EmptyState,
} from 'components/atomsKit/Elements';
import { configFilterSendGifts } from 'data/config';
import useContactRequestPage from './useContactRequestPage';
import { formatDateToMMMMDDYY } from 'utils/common';
import classes from './ContactRequestPage.module.scss';

const ContactRequestPage = () => {
    const { orders } = useContactRequestPage();

    return (
        <>
            <ChatFilterPanel
                handleIsOnline={() => {}}
                isOnline
                configFilterChat={configFilterSendGifts('contact-request')}
            />
            <PageWrap
                mutationHeight={61}
                height100
                scroll
                padding="0 20px"
                className={classes.pageWrap}
            >
                {orders?.length > 0 ? (
                    <ul>
                        {orders?.map((item: any, index: number) => (
                            <li key={index}>
                                <ContactRequestCard
                                    public_external_id={item?.public_external_id}
                                    nameGirl={item?.name}
                                    ageGirl={item?.age}
                                    avatarGirl={item?.photo_link}
                                    date_created={formatDateToMMMMDDYY(item?.created_at)}
                                    status={item?.status}
                                    city={item?.city_name}
                                    country_code={item?.country_code}
                                />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <EmptyState
                        img={'c3_empty_contact'}
                        config={{
                            title: 'No contact requests found',
                            description:
                                'All available information about contact requests you’ve made will be displayed here.',
                            buttons: [],
                        }}
                    />
                )}
            </PageWrap>
        </>
    );
};

export default ContactRequestPage;
