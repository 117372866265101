import React from 'react';
import {
    ButtonGift,
    PageWrap,
    GiftCard,
    TextNameonChat,
    Image,
} from 'components/atomsKit/Elements';

import useGiftsPage from './useGiftsPage';

import classes from './GiftsPage.module.scss';
import { Fragment } from 'react';

const GiftsPage = () => {
    const {
        showAllCategory,
        setShowAllCategory,
        configGifts,
        setSelectedGroup,
        selectedGroup,
        products,
        handleAddCart,
        cart,
        handleCart,
    } = useGiftsPage();

    const parsForSelectGroup = (products, configGifts, selectedGroup) => {
        if (selectedGroup.id) {
            const filteredProducts = products.filter(
                (product) => product?.category_id === selectedGroup?.id,
            );
            return [{ name: selectedGroup?.name, items: filteredProducts }];
        } else {
            const result = configGifts?.map((group) => {
                const filteredProducts = products.filter(
                    (product) => product?.category_id === group?.id,
                );

                return { name: group?.name, items: filteredProducts };
            });

            return result;
        }
    };

    return (
        <PageWrap height100 padding="12px 12px 0 12px">
            <div className={classes.wrap}>
                <ul className={classes.wrapBtn}>
                    <li className={classes.itemBtn}>
                        <ButtonGift
                            text={'All'}
                            onClick={() => setSelectedGroup({ name: 'All' })}
                            isSelected={selectedGroup?.name === 'All'}
                        />
                    </li>
                    {configGifts.slice(0, showAllCategory ? configGifts?.length : 4).map((item) => (
                        <li className={classes.itemBtn} key={item.id}>
                            <ButtonGift
                                text={item.name}
                                onClick={() => setSelectedGroup(item)}
                                isSelected={item?.name === selectedGroup?.name}
                            />
                        </li>
                    ))}
                    <li className={classes.itemBtn}>
                        <button
                            onClick={() => setShowAllCategory(!showAllCategory)}
                            className={classes.showAll}
                        >
                            {showAllCategory ? 'Show less categories' : 'Show all categories '}
                        </button>
                    </li>
                    <li className={classes.cartBtn}>
                        <button onClick={handleCart} className={classes.cart}>
                            <Image name="cart" />
                            {cart.length > 0 && (
                                <p className={classes.counterCart}>{cart?.length}</p>
                            )}
                        </button>
                    </li>
                </ul>
                <div className={classes.list}>
                    {parsForSelectGroup(products, configGifts, selectedGroup).map((group) => (
                        <Fragment key={group?.name}>
                            <TextNameonChat
                                text={group?.name}
                                size={20}
                                margin="14px 0 14px 12px"
                            />
                            <ul className={classes.wrapListProducts}>
                                {group?.items?.map((item) => (
                                    <GiftCard
                                        key={item?.name}
                                        img={item?.image}
                                        name={item?.name}
                                        price={item?.price}
                                        isInCart={cart.find((gift) => gift.id === item.id)}
                                        handleAddCart={() => {
                                            handleAddCart(item);
                                        }}
                                    />
                                ))}
                            </ul>
                        </Fragment>
                    ))}
                </div>
            </div>
        </PageWrap>
    );
};

export default GiftsPage;
